import { resolveColor, resolveFontSize, resolveWithDefault } from "@/styles/helpers";
import styled, { css } from "styled-components";
import { textInputBackgroundColor, textInputColor } from "../input/Input.styled";
import { transparentize } from "polished";
import { SvgWrapperStyled } from "components/svg/Svg.styled";
import Svg from "components/svg/Svg";

type SelectProps = {
  $showError: boolean;
  $hasPrefixIcon: boolean;
  $hasValue: boolean;
};

export const DropdownStyled = styled.select<SelectProps>`
  appearance: none; /* Remove the default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;

  font-family: inherit;
  height: ${({ theme }) => theme.textInput.height};
  font-size: ${({ theme }) => resolveFontSize(theme, theme.textInput.fontSize)};
  border-radius: ${({ theme }) => theme.textInput.borderRadius};
  background-color: ${({ theme }) => textInputBackgroundColor(theme)};
  color: ${({ theme, $hasValue }) => ($hasValue ? textInputColor(theme) : transparentize(0.3, textInputColor(theme)))};
  outline: 0;
  margin: 0;
  border: 0;
  padding: 0 1rem;
  padding-left: 1rem;
  width: 100%;
  box-sizing: border-box;
  inset;
  cursor: pointer;
  box-shadow: ${({ $showError, theme }) =>
    $showError ? `0 0 0 2px ${resolveColor(theme, theme.textInput.error.color)}` : 0}
    inset;
  &:focus {
    box-shadow: ${({ $showError, theme }) =>
      $showError
        ? `0 0 0 2px ${resolveColor(theme, theme.textInput.error.color)}`
        : `0 0 0 2px ${resolveColor(theme, theme.textInput._focus.borderColor)}`}
      inset;
  }
  ${({ $hasPrefixIcon }) => $hasPrefixIcon && `padding-left: 2rem;`}
  ${({ theme }) => theme.textInput.css}
`;

export const DropdownWrapperStyled = styled.div`
  position: relative;

  > ${SvgWrapperStyled}.prefix {
    //flex: 0 0 1rem;
    position: absolute;
    left: 0;
    top: 45%;
    transform: translateY(-50%);
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
`;

export const DropdownErrorWrapper = styled.div`
  padding-top: 3px;
`;

export const DropdownErrorStyled = styled.div`
  font-size: ${({ theme }) => resolveFontSize(theme, "extra_small")};
  color: ${({ theme }) => resolveColor(theme, theme.textInput.error.color)};
  font-weight: bold;
`;

export const IconStyled = styled(Svg)`
  position: absolute;
  right: 1.2rem;
  top: 50%;
  width: 1.5rem;
  height: 1.5rem;
  transform: translateY(-50%);
  pointer-events: none;
  color: ${({ theme }) => transparentize(0.3, textInputColor(theme))};
`;

type LabelProps = {
  $showLabel: boolean;
};

export const DropdownLabelStyled = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) =>
    resolveWithDefault(
      resolveColor(theme, theme.textInput.label.color),
      resolveColor(theme, theme.layout.color),
    )};
`;

export const DropdownSpanStyled = styled.span<LabelProps>`
  font-size: ${({ theme }) => resolveFontSize(theme, theme.textInput.label.fontSize)};
  font-weight: ${({ theme }) => theme.textInput.label.fontWeight};
  padding-bottom: 10px;
  display: block;

  ${({ $showLabel }) =>
    !$showLabel &&
    css`
      border: 0;
      clip: rect(0 0 0 0);
      clippath: inset(50%);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    `}
`;
