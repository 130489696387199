import React, { FC } from "react";
import { FiCopy } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import copy from "copy-to-clipboard";
import parse from "html-react-parser";
import {
  WrapperStyled,
  TitleStyled,
  CopyButtonStyled,
  SuccessCopyNotificationStyled,
  CopySpanStyled,
  SuccessCopySpanStyled,
} from "../ShareArea.styled";

type Props = {
  url: string;
  title: string;
  copySuccessNotification: string;
  showCopySuccessNotification: boolean;
  onCopy?: (copyService: string) => void;
  overrideButtonStyle?: React.ElementType | undefined;
  copyButtonAccessibleLabel?: string;
};

const ShareUrl: FC<Props> = ({
  title,
  url,
  onCopy,
  copySuccessNotification,
  showCopySuccessNotification,
  overrideButtonStyle,
  copyButtonAccessibleLabel,
}) => {
  const copyCodeToClipboard = (): void => {
    copy(url);
    if (onCopy) {
      onCopy("Icon button");
    }
  };

  const ButtonStyleToUse = overrideButtonStyle || CopyButtonStyled;

  return (
    <WrapperStyled>
      <TitleStyled>
        <span>{parse(title)}</span>
      </TitleStyled>
      {showCopySuccessNotification ? (
        <SuccessCopyNotificationStyled>
          <AiOutlineCheckCircle />
          <SuccessCopySpanStyled role="status">{copySuccessNotification}</SuccessCopySpanStyled>
        </SuccessCopyNotificationStyled>
      ) : (
        <ButtonStyleToUse
          {...(copyButtonAccessibleLabel ? { "aria-label": copyButtonAccessibleLabel } : {})}
          onClick={copyCodeToClipboard}
          role="button"
        >
          <CopySpanStyled>{url}</CopySpanStyled>
          <FiCopy aria-hidden={true} />
        </ButtonStyleToUse>
      )}
    </WrapperStyled>
  );
};

export default ShareUrl;
