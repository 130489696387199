import React, { FC } from "react";

import { FormLegal } from "components/legal";

import { Context } from ".";
import { renderString } from "lib/Template";
import MarkdownContent from "components/MarkdownContent";

const formLegal: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.optionalContent) {
    return null;
  }

  return (
    <FormLegal>
      <MarkdownContent>
        {renderString(
          context.step.optionalContent.form.finePrint,
          context.state.sessionContentReplacements,
        )}
      </MarkdownContent>
    </FormLegal>
  );
};

export default formLegal;
