import React, { FC, useContext, useState } from "react";

import useMutation from "hooks/UseMutation";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";

import {
  LookupUserStep,
  LookupUserActiveStep,
  LookupUserViaCodePayload,
  LookupUserViaCodeInput,
} from "types/graphql";

import { lookupUserJourneyQueries } from "queries";

import DefaultLayout from "./layouts";
import { Lego } from "lib/Lego";
import { BrickTypes, Context, FormData } from "lib/Lego/UserLookup/LookupUser";
import { useDevice } from "hooks/Device";
import { FormProvider, useForm } from "react-hook-form";

export type LookupUserProps = {
  stepData: LookupUserStep;
};

const LookupUser: FC<LookupUserProps> = ({ stepData }) => {
  const { appConfig, goToStep, sessionConfig, sessionContentReplacements, localisation } =
    useContext<DefaultContext>(StepContext);

  const flowId = appConfig.flow.id;
  const id = appConfig.id;
  const locale = appConfig.locale;
  const showInvalidCodeMessage = sessionConfig.config?.showInvalidCodeMessage;

  const [submissionError, setSubmissionError] = useState<boolean>(false);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const [submitLookupUserViaCode, { loading }] = useMutation<
    { lookupUserViaCode: LookupUserViaCodePayload },
    LookupUserViaCodeInput
  >(lookupUserJourneyQueries.mutations.lookupUserViaCode, {
    onCompleted: (data: { lookupUserViaCode: LookupUserViaCodePayload }): void => {
      if (data.lookupUserViaCode.ok) {
        const activeStep = data.lookupUserViaCode.session.activeStep as LookupUserActiveStep;
        const { redirectToUrl, showInvalidCodeMessage } = activeStep.config;

        if (showInvalidCodeMessage) {
          return goToStep({
            type: activeStep.type,
            config: activeStep.config,
            contentReplacements: data.lookupUserViaCode.session.contentReplacements,
          });
        }

        if (redirectToUrl) {
          (window.top || window.parent).postMessage(
            `buyapowa:sso_redirect:${encodeURIComponent(redirectToUrl)}`,
            "*",
          );
        } else {
          setSubmissionError(true);
        }
      } else {
        setSubmissionError(true);
      }
    },
    onError: () => setSubmissionError(true),
  });

  const handleLookupUser = (formData: FormData): void => {
    const { code } = formData;
    const variables = {
      flowId,
      id,
      locale,
      code,
    } as LookupUserViaCodeInput;

    submitLookupUserViaCode({
      variables,
    });
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleLookupUser,
    },
    localisation: localisation,
    state: {
      device: useDevice(),
      sessionContentReplacements,
      loading,
      submissionError,
      showInvalidCodeMessage: !!showInvalidCodeMessage,
    },
  };

  return (
    <FormProvider {...methods}>
      <Lego<Context>
        brickTypes={BrickTypes}
        layout={DefaultLayout}
        context={context}
        isPreview={appConfig.isPreview}
      />
    </FormProvider>
  );
};

export default LookupUser;
