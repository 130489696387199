#import "../../../fragments/flows/friend-journey/session.graphql"

query friendJourneyGetSession($flow: String!, $flowId: ID!, $id: ID!, $requestedLocale: String) {
  session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
    activeStep {
      ...FriendJourneySessionFragment
    }
    contentReplacements
    locale
  }
}
