#import "../../../fragments/flows/click-to-claim-referrer/config.graphql"

query clickToClaimReferrerGetConfig(
  $flow: String!
  $campaignSlug: String
  $locale: String
  $flowId: ID
) {
  config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
    ...ClickToClaimReferrerConfigFragment
  }
}
