import { EmbedParameters } from "types/embed-parameters.types";
import { ApolloClient } from "@apollo/client";
import { sessionForFlow } from "queries";
import { SessionInterface } from "types/graphql";

export default (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
  sessionId: string,
): Promise<SessionInterface> => {
  return new Promise<SessionInterface>((resolve, reject) => {
    const {
      flow: { name: flowName },
    } = params;

    apolloClient
      .query({
        query: sessionForFlow(flowName),
        variables: {
          flow: flowName,
          flowId: params.flow.id,
          id: sessionId,
          requestedLocale: params.locale,
        },
      })
      .then((result) => {
        resolve(result.data.session as SessionInterface);
      })
      .catch(reject);
  });
};
