import { EmbedParameters } from "types/embed-parameters.types";
import { ApolloClient } from "@apollo/client";
import { Config, SessionInterface } from "types/graphql";

import loadConfigPreview from "./LoadConfigPreview";
import loadSessionPreview from "./LoadSessionPreview";

export default async (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
): Promise<[Config, SessionInterface]> => {
  const session = await loadSessionPreview(apolloClient, params);
  const config = await loadConfigPreview(apolloClient, params);

  return [config as Config, session as SessionInterface];
};
