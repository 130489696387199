import React, { FC, useState } from "react";
import { TileButtonContent } from "types/graphql";

import { ImageTilePagerContainerStyled, ImageTilesContainerStyled } from "./ImageTiles.styled";
import Carousel from "components/carousel";
import Pager from "components/pager";
import { ImageTile } from "./image-tile/ImageTile";

type ImageTilesProps = {
  smallContainer: boolean;
  buttons: Array<TileButtonContent>;
  appearance: "primary" | "secondary";
  handleCallToActionClick: (url: string, actionId?: string) => void;
  carouselStyle?: Record<string, any>;
};

export const ImageTiles: FC<ImageTilesProps> = ({
  buttons,
  appearance,
  handleCallToActionClick,
  smallContainer,
  carouselStyle,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);

  const pageCount = buttons.length;
  const shouldUseCarousel = smallContainer && pageCount > 1;

  const imageTiles = buttons.map((button: TileButtonContent, index: number) => (
    <ImageTile
      key={`imagetile_${index}`}
      button={button}
      index={index}
      appearance={appearance}
      smallContainer={smallContainer}
      handleCallToActionClick={() => handleCallToActionClick(button.url, button.actionId)}
    />
  ));

  return (
    <>
      {shouldUseCarousel ? (
        <Carousel
          currentPage={selectedPage}
          itemWidthPercentage={85}
          onScroll={setCurrentPage}
          style={carouselStyle}
        >
          {imageTiles}
        </Carousel>
      ) : (
        <ImageTilesContainerStyled>{imageTiles}</ImageTilesContainerStyled>
      )}
      {shouldUseCarousel ? (
        <ImageTilePagerContainerStyled>
          <Pager onPageClick={setSelectedPage} pageCount={pageCount} selectedPage={currentPage} />
        </ImageTilePagerContainerStyled>
      ) : (
        <br />
      )}
    </>
  );
};
