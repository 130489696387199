import React, { FC } from "react";
import { FaFacebookMessenger } from "react-icons/fa";

import usePopupWindow from "hooks/PopupWindow";
import useRecordEvent from "hooks/RecordEvent";
import {
  ShareButtonLabelStyled,
  ShareButtonOuterStyled,
  ShareButtonStyled,
} from "../ShareButton.styled";

import { ContainerSize, useDevice } from "hooks/Device";
import { EventEnum } from "types/graphql";

export type Props = {
  shareUrl: string;
  buttonText?: string;
};

const Facebook: FC<Props> = ({ shareUrl, buttonText = "Messenger" }) => {
  const popupWindow = usePopupWindow();
  const recordEvent = useRecordEvent();
  const device = useDevice();

  const handleFacebookMessengerShareClick = (): void => {
    let url;

    if (device.containerSize === ContainerSize.LargeContainer) {
      url = `https://www.facebook.com/dialog/send?app_id=${
        import.meta.env.VITE_FACEBOOK_APP_ID
      }&link=${encodeURIComponent(shareUrl)}&redirect_uri=https%3A%2F%2Fwww.facebook.com%2F`;
    } else {
      url = `fb-messenger://share?link=${encodeURIComponent(shareUrl)}`;
    }

    popupWindow(url);

    recordEvent(EventEnum.Share, { share_network: "FB Messenger" });
  };

  return (
    <ShareButtonOuterStyled>
      <ShareButtonStyled onClick={handleFacebookMessengerShareClick}>
        <FaFacebookMessenger />
      </ShareButtonStyled>
      <ShareButtonLabelStyled>{buttonText}</ShareButtonLabelStyled>
    </ShareButtonOuterStyled>
  );
};

export default Facebook;
