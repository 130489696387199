fragment RedirectFriendToClientJourneySessionFragment on ActiveStepInterface {
  type

  ... on ClickToRedirectFriendActiveStep {
    config {
      destinationUrl
      requiresFriendRegistration
    }
  }

  ... on AutomaticallyRedirectFriendActiveStep {
    config {
      destinationUrl
      requiresFriendRegistration
    }
  }
}
