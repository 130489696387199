mutation trackEvent(
  $id: ID!
  $campaignSlug: String!
  $event: EventEnum!
  $params: JSON!
  $flowId: ID
  $utmParams: UtmParamsInput
) {
  trackEvent(
    input: {
      id: $id
      campaignSlug: $campaignSlug
      event: $event
      params: $params
      flowId: $flowId
      utmParams: $utmParams
    }
  ) {
    ok
    errors
  }
}
