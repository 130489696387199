#import "../../../../fragments/flows/referrer-journey/session.graphql"

mutation registerReferrer(
  $flowId: String!
  $id: ID!
  $locale: String
  $fullName: String
  $firstName: String
  $lastName: String
  $email: String!
  $marketingOptIn: Boolean
  $termsOptIn: Boolean
  $customFields: [CustomFieldInput!]
  $utmParams: UtmParamsInput
) {
  registerReferrer(
    input: {
      flowId: $flowId
      id: $id
      locale: $locale
      fullName: $fullName
      firstName: $firstName
      lastName: $lastName
      email: $email
      termsOptIn: $termsOptIn
      marketingOptIn: $marketingOptIn
      customFields: $customFields
      utmParams: $utmParams
    }
  ) {
    ok
    errors
    session {
      locale
      contentReplacements
      activeStep {
        ...ReferrerJourneySessionFragment
      }
    }
  }
}
