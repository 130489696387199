#import "../../../../fragments/flows/friend-journey/session.graphql"

mutation addFriendReferral($flowId: String!, $id: ID!) {
  addFriendReferral(input: { flowId: $flowId, id: $id }) {
    ok
    session {
      locale
      contentReplacements
      activeStep {
        ...FriendJourneySessionFragment
      }
    }
    errors
  }
}
