#import "../../../fragments/flows/click-to-claim-friend/config.graphql"

query clickToClaimFriendGetConfig(
  $flow: String!
  $campaignSlug: String
  $locale: String
  $flowId: ID
) {
  config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
    ...ClickToClaimFriendConfigFragment
  }
}
