import { EmbedParameters } from "types/embed-parameters.types";
import { configForFlow } from "queries";
import { ApolloClient } from "@apollo/client";
import { Config } from "types/graphql";

export default (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
  sessionLocale: string,
): Promise<Config> => {
  return new Promise<Config>((resolve, reject) => {
    apolloClient
      .query({
        query: configForFlow(params.flow.name),
        variables: {
          flow: params.flow.name,
          locale: sessionLocale,
          campaignSlug: params.campaign,
          flowId: params.flow.id,
        },
      })
      .then((result) => {
        resolve(result.data.config as Config);
      })
      .catch(reject);
  });
};
