#import "../../shared-style.graphql"
#import "../../custom-fonts.graphql"
#import "../../urls.graphql"

fragment FriendJourneyConfigFragment on Config {
  customFonts {
    ...CustomFontsFragment
  }
  styles {
    ...SharedStyleFragment
  }
  globalStyles
  steps {
    type

    ... on CaptureFriendDetailsStep {
      content {
        title
        body
        image {
          url
          altText
          mobile {
            url
            altText
          }
        }
        form {
          caption
          button {
            text
          }
          inputs {
            fullName {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            firstName {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            lastName {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            email {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            marketingOptIn {
              label
              validationErrors {
                key
                value
              }
            }
            termsOptIn {
              label
              validationErrors {
                key
                value
              }
            }
            customFields {
              key
              content {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                items
              }
            }
          }
          submitError
          finePrint
        }
        additionalFinePrint
        partnerDetails {
          avatarUrl
          bannerUrl
          businessName
          displayName
          offerHeader
          quote
        }
      }
      config {
        partialRegistration
        inputs {
          fullName {
            show
            pattern
          }
          firstName {
            show
            pattern
          }
          lastName {
            show
            pattern
          }
          email {
            show
            pattern
          }
          marketingOptIn {
            show
            required
          }
          termsOptIn {
            show
          }
          customFields {
            key
            position
            inputFieldType
            ... on CustomFieldInputConfig {
              pattern
            }
            ... on CustomFieldListInputConfig {
              items
            }
          }
        }
        button {
          show
        }
        displayPartnerRewards
      }
    }
    ... on CaptureAdditionalFriendDetailsStep {
      optionalContent {
        nextVisit {
          card {
            title
            body
            finePrint
          }
          instructions
          showButtons
        }
        firstVisit {
          card {
            title
            body
            finePrint
          }
          instructions
        }
        form {
          inputs {
            customFields {
              key
              content {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                items
                tooltip
              }
            }
          }
          button {
            text
          }
          submitError
        }
        buttons {
          buttonClass
          text
          url

          ... on TileButtonContent {
            image {
              altText
              url
            }
            heading
            description
            actionId
            buttonClass
          }
        }
        finePrint
      }
      optionalConfig {
        inputs {
          customFields {
            key
            position
            inputFieldType
            ... on CustomFieldInputConfig {
              pattern
            }
            ... on CustomFieldListInputConfig {
              items
            }
          }
        }
      }
    }
    ... on SuccessfulFriendRegistrationStep {
      optionalContent {
        title
        body
        secondaryButton {
          text
        }
      }
      optionalConfig {
        button {
          show
        }
      }
    }
    ... on IneligibleForVoucherStep {
      content {
        title
        body
        button {
          text
        }
      }
      config {
        button {
          show
        }
      }
    }
    ... on ReferralLimitReachedStep {
      optionalContent {
        title
        body
        button {
          text
        }
      }
      optionalConfig {
        button {
          show
        }
      }
    }
  }
  urls {
    ...UrlsFragment
  }
  programType
}
