#import "./splash-page-active-step.graphql"
#import "./capture-referrer-details-active-step.graphql"
#import "./referrer-dashboard-active-step.graphql"

fragment ReferrerJourneySessionFragment on ActiveStepInterface {
  type

  ... on SplashPageActiveStep {
    ...SplashPageActiveStepFragment
  }

  ... on CaptureReferrerDetailsActiveStep {
    ...CaptureReferrerDetailsActiveStepFragment
  }

  ... on ReferrerDashboardActiveStep {
    ...ReferrerDashboardActiveStepFragment
  }
}
