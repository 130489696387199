import React, { FC } from "react";

import { Context } from ".";
import StandardSharingTools from "components/standard-sharing-tools";
import { ReferrerDashboardActiveStepConfigShareNetworkShortUrls } from "types/graphql";
import { renderString } from "lib/Template";

const SharingTools: FC<{ context: Context }> = ({ context }) => {
  const device = context.state.device;

  if (
    device.nativeSharingSupported ||
    (!context.step.config.showDirectLink && !context.step.config.showShareCode)
  ) {
    return null;
  }

  const { sessionContentReplacements } = context.state;
  const { shareCode } = context.step.content;

  const shareMessage = context.step.config.showDirectLink
    ? context.step.content.shareMessage
    : shareCode.shareMessage;
  const emailSharingSubject = context.step.config.showDirectLink
    ? context.step.content.nativeSharing.title
    : shareCode.label;
  const emailSharingShortUrl = context.step.config.showDirectLink
    ? context.session?.config?.emailSharingShortUrl
    : "";

  return (
    <StandardSharingTools
      shareNetworks={context.step.config.shareNetworks}
      shareNetworkShortUrls={
        context.session?.config?.shareNetworkShortUrls ||
        ({} as ReferrerDashboardActiveStepConfigShareNetworkShortUrls)
      }
      shareMessage={renderString(shareMessage, sessionContentReplacements)}
      shareImage={context.step.content.image?.url}
      emailSharingSubject={emailSharingSubject || ""}
      emailSharingShortUrl={emailSharingShortUrl || ""}
    />
  );
};

export default SharingTools;
