#import "../../shared-style.graphql"
#import "../../custom-fonts.graphql"
#import "../../urls.graphql"

fragment LookupUserJourneyConfigFragment on Config {
  customFonts {
    ...CustomFontsFragment
  }
  styles {
    ...SharedStyleFragment
  }
  globalStyles
  localisation {
    date {
      dayNames
      abbreviatedDayNames
      monthNames
      abbreviatedMonthNames
      shortFormat
      longFormat
      monthOnlyFormat
    }
  }
  steps {
    type

    ... on LookupUserStep {
      content {
        body
        form {
          button {
            text
          }
          inputs {
            accessCode {
              label
              placeholder
              tooltip
              validationErrors {
                key
                value
              }
            }
          }
          submitError
        }
        image {
          altText
          url
        }
        title
      }
    }
  }
  urls {
    ...UrlsFragment
  }
}
