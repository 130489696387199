fragment SharedStyleFragment on Styles {
  font {
    family
    weight
    sizes {
      extraSmall
      small
      medium
      large
      extraLarge
    }
  }
  colors {
    highlight
    error
  }
  layout {
    backgroundColor
    color
  }
  button {
    css
    fontFamily
    fontSize
    fontWeight
    borderRadius
    height
    primary {
      css
      color
      backgroundColor
      _disabled {
        color
        backgroundColor
      }
    }
    secondary {
      css
      color
      backgroundColor
      _disabled {
        color
        backgroundColor
      }
    }
  }
  textInput {
    css
    height
    fontSize
    borderRadius
    color
    backgroundColor
    label {
      fontSize
      fontWeight
      color
    }
    _focus {
      css
      borderColor
    }
    error {
      css
      color
    }
  }
  form {
    css
    backgroundColor
    captionColor
    borderColor
    error {
      css
      color
    }
  }
  switch {
    css
    backgroundColor
    label {
      css
      fontSize
      fontWeight
      color
    }
    _active {
      css
      backgroundColor
    }
    error {
      css
      color
    }
  }
  link {
    css
    color
    fontFamily
    fontWeight
    fontSize
    textDecoration
    _hover {
      css
      color
    }
    _active {
      css
      color
    }
    _visited {
      css
      color
    }
  }
  notification {
    css
    backgroundColor
    color
    fontSize
    successful {
      iconColor
    }
  }
  card {
    css
    backgroundColor
    title {
      css
      fontSize
      fontWeight
      color
    }
    description {
      css
      fontSize
      fontWeight
      color
    }
  }
  title {
    css
    fontFamily
    fontSize
    fontWeight
    color
  }
  avatar {
    css
    backgroundColor
    color
    borderRadius
  }
  pill {
    css
    backgroundColor
    color
    borderRadius
    fontSize
    fontWeight
    fontFamily
  }
  tabSwitcher {
    css
    backgroundColor
    color
    borderRadius
    fontSize
    fontWeight
    tab {
      css
      _active {
        css
        backgroundColor
        color
        borderRadius
      }
    }
  }
  strong {
    css
    color
    fontFamily
    fontWeight
  }
  emphasis {
    css
    color
    fontFamily
    fontWeight
    fontStyle
  }
  shareArea {
    css
    backgroundColor
    color
    fontFamily
    fontSize
    fontWeight
    borderRadius
    height
    _hover {
      css
      backgroundColor
      color
    }
    manualSharingTitle {
      fontFamily
      fontSize
      fontWeight
    }
  }
  errorBanner {
    backgroundColor
    color
  }
  warningBanner {
    backgroundColor
    color
  }
  legal {
    color
    fontFamily
    fontSize
    lineHeight
    opacity
    css
  }
}
