#import "../../../../fragments/flows/redirect-friend-to-client-journey/session.graphql"

mutation registerFriendForRedirection($flowId: String!, $id: ID!) {
  registerFriendForRedirection(input: { flowId: $flowId, id: $id }) {
    ok
    session {
      locale
      contentReplacements
      activeStep {
        ...RedirectFriendToClientJourneySessionFragment
      }
    }
    errors
  }
}
