#import "../../shared-style.graphql"
#import "../../custom-fonts.graphql"
#import "../../urls.graphql"

fragment RewardRedemptionJourneyConfigFragment on Config {
  customFonts {
    ...CustomFontsFragment
  }
  styles {
    ...SharedStyleFragment
  }
  globalStyles
  urls {
    ...UrlsFragment
  }
  programType
  steps {
    type
  }
}
