import React, { FC } from "react";
import { BiShoppingBag } from "react-icons/bi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { RiShareBoxLine } from "react-icons/ri";
import parse from "html-react-parser";

import { ListStyled, ListItemStyled, ListIconStyled, ListItemTextStyled } from "./StepsList.styled";

type StepsListProps = {
  steps: Array<string>;
  showIcons: boolean;
};

const StepsList: FC<StepsListProps> = ({ steps, showIcons }) => {
  const offerStepImages = [
    <RiShareBoxLine key="RiShareBoxLine" />,
    <BiShoppingBag key="BiShoppingBag" />,
    <FaRegMoneyBillAlt key="FaRegMoneyBillAlt" />,
  ];

  return (
    <ListStyled>
      {steps.map(
        (step, index) =>
          !!step && (
            <ListItemStyled key={step} className={index === 0 ? "first" : ""}>
              {showIcons && <ListIconStyled>{offerStepImages[index]}</ListIconStyled>}
              <ListItemTextStyled>{parse(step)}</ListItemTextStyled>
            </ListItemStyled>
          ),
      )}
    </ListStyled>
  );
};

export default StepsList;
