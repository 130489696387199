import store from "store2";
import { v4 as uuidv4 } from "uuid";

const oldKeyNameForFlow = (flow: string, market: string): string => `bp-uid-${flow}-${market}`;
const keyNameForFlow = (flow: string, market: string, campaign: string): string =>
  `bp-uid-${flow}-${market}-${campaign}`;

const migrateOldId = (flow: string, market: string, campaign: string): void => {
  const oldKey = oldKeyNameForFlow(flow, market);
  const oldId = store.get(oldKey);
  const newKey = keyNameForFlow(flow, market, campaign);

  if (oldId && !store.get(newKey)) {
    store.set(newKey, oldId);
  }
};

export const createSessionId = (flow: string, market: string, campaign: string): string => {
  const newId = uuidv4();

  setSessionId(flow, market, campaign, newId);

  return newId;
};

export const getSessionId = (flow: string, market: string, campaign: string): string => {
  migrateOldId(flow, market, campaign);

  const sessionId = store.get(keyNameForFlow(flow, market, campaign));

  return sessionId || createSessionId(flow, market, campaign);
};

export const setSessionId = (
  flow: string,
  market: string,
  campaign: string,
  value: string,
): void => {
  store.set(keyNameForFlow(flow, market, campaign), value);
};
