#import "./capture-friend-details-active-step.graphql"
#import "./capture-additional-friend-details-active-step.graphql"
#import "./successful-friend-registration-active-step.graphql"
#import "./ineligible-for-voucher-active-step.graphql"
#import "./referral-limit-reached-active-step.graphql"
#import "./continue-to-client-site-active-step.graphql"

fragment FriendJourneySessionFragment on ActiveStepInterface {
  type

  ... on CaptureFriendDetailsActiveStep {
    ...CaptureFriendDetailsActiveStepFragment
    config {
      rewards {
        title
        body
      }
    }
  }
  ... on CaptureAdditionalFriendDetailsActiveStep {
    ...CaptureAdditionalFriendDetailsActiveStepFragment
  }
  ... on SuccessfulFriendRegistrationActiveStep {
    ...SuccessfulFriendRegistrationActiveStepFragment
  }
  ... on IneligibleForVoucherActiveStep {
    ...IneligibleForVoucherActiveStepFragment
  }
  ... on ReferralLimitReachedActiveStep {
    ...ReferralLimitReachedActiveStepFragment
  }
  ... on ContinueToClientSiteActiveStep {
    ...ContinueToClientSiteActiveStepFragment
  }
}
