#import "../../../../fragments/flows/friend-journey/session.graphql"

mutation addFriendDetails($flowId: String!, $id: ID!, $customFields: [CustomFieldInput!]!) {
  addFriendDetails(input: { flowId: $flowId, id: $id, customFields: $customFields }) {
    ok
    session {
      locale
      contentReplacements
      activeStep {
        ...FriendJourneySessionFragment
      }
    }
    errors
  }
}
