import React, { FC, ReactNode } from "react";

import styled from "styled-components";
import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";
import { Modifier } from "./Modifiers";

type Props = {
  children: ReactNode[];
  modifiers: Modifier[];
  isPreview: boolean;
};

const RowStyled = styled.div<{ $modifiers: Modifier[] }>`
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: min-content 1fr;

  ${({ $modifiers }): string => {
    let columnLayout = "grid-template-columns: repeat(auto-fit, minmax(200px,1fr));";

    if ($modifiers && $modifiers.find((f) => f === Modifier.SixtyFourtySplit))
      columnLayout = "grid-template-columns: 1.66fr 1fr";

    if ($modifiers && $modifiers.find((f) => f === Modifier.FourtySixtySplit))
      columnLayout = "grid-template-columns: 1fr 1.66fr";

    return useForLargeContainer(columnLayout);
  }}
`;

const V1RowStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`;

export const Row: FC<Props> = ({ children, modifiers }) => (
  <RowStyled $modifiers={modifiers}>{children}</RowStyled>
);

const ColumnStyled = styled.div<{ $isPreview: boolean }>`
  ${({ $isPreview }): string => {
    const width = $isPreview ? "375px" : "100vw";
    return useForSmallContainer(`padding: 0 1rem; width: ${width};`);
  }}

  > *:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const Column: FC<Props> = ({ children, isPreview }) => {
  return <ColumnStyled $isPreview={isPreview}>{children}</ColumnStyled>;
};

export const V1Row: FC<Props> = ({ children }) => <V1RowStyled>{children}</V1RowStyled>;
