import styled from "styled-components";
import { resolveColor } from "styles/helpers";

import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";

import { transparentize } from "polished";

type PerformanceTableStyledProps = {
  $overlay?: boolean;
  $columnCount: number;
  $firstColumnWidthPercent: number;
  $otherColumnWidthPercent: number;
};

export const PerformanceTableContainerStyled = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  ${({ theme }): string =>
    useForSmallContainer(`
    border-radius: 0.8rem;
    border: solid 1px ${transparentize(0.9, theme.layout.color)};
  `)}
`;

export const ResponsiveTableHeadStyled = styled.table<PerformanceTableStyledProps>`
  thead {
    tr th {
      padding: 1.2rem 0.6rem;
      text-align: left;

      border-bottom: solid 1px
        ${({ theme }): string => transparentize(0.95, theme.colors.highlight)};
      color: ${({ theme }): string => theme.colors.highlight};

      ${({ theme }): string => useForSmallContainer(`border-color: ${theme.colors.highlight}`)};

      font-weight: 700;

      span {
        display: inline-flex;
        gap: 0.6rem;
      }

      ${({ $columnCount, $firstColumnWidthPercent, $otherColumnWidthPercent }): string => {
        const totalSize = $otherColumnWidthPercent * ($columnCount - 1) + $firstColumnWidthPercent;
        const firstColumnPc = totalSize / $firstColumnWidthPercent;
        const otherColumnPc = (100 - firstColumnPc) / $columnCount;

        return useForSmallContainer(`
            width: ${otherColumnPc}%;
            text-align: right;

            &:first-of-type {
                width: ${firstColumnPc}%;
                text-align: left;
            }
    `);
      }}
    }
  }
`;

export const PerformanceTableStyled = styled(ResponsiveTableHeadStyled)`
  position: ${({ $overlay }): string => ($overlay ? "absolute" : "relative")};
  left: 0;
  top: 0;
  border-spacing: 0;
  white-space: nowrap;

  background-color: ${({ theme }): string => resolveColor(theme, theme.layout.backgroundColor)};

  ${(): string => useForLargeContainer("table-layout: fixed;")}
  ${({ $overlay, theme }): string =>
    useForLargeContainer(
      `
      border-radius: 0.8rem;
      border: solid 1px ${
        $overlay
          ? resolveColor(theme, theme.layout.backgroundColor)
          : transparentize(0.9, theme.layout.color)
      };
  `,
    )}
  ${({ $overlay, $columnCount, $firstColumnWidthPercent, $otherColumnWidthPercent }): string =>
    useForSmallContainer(`
      width: ${
        $overlay
          ? `${$firstColumnWidthPercent}%`
          : `${$otherColumnWidthPercent * ($columnCount - 1) + $firstColumnWidthPercent}%`
      };
  `)}
  ${(): string => useForLargeContainer("width: 100%;")}
  ${({ $overlay }): any =>
    $overlay &&
    `
      box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.1);
  `}

  tbody tr,
  tfoot tr {
    ${({ theme }): string =>
      useForLargeContainer(`
      &:nth-of-type(2n) {
        background-color: ${transparentize(0.96, theme.colors.highlight)};
      }
    `)}
    &:last-of-type {
      td {
        border-top: solid 2px ${({ theme }): string => transparentize(0.95, theme.colors.highlight)};
        ${({ theme }): string => useForSmallContainer(`border-color: ${theme.colors.highlight}`)}
      }

      background-color: transparent;
      color: ${({ theme }): string => theme.colors.highlight};

      font-weight: 700;
    }

    td {
      padding: 1rem 0.6rem;

      ${(): string =>
        useForLargeContainer(`
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;

            &:first-of-type {
                border-left: 2px solid transparent;
            }

            &:last-of-type {
                border-right: 2px solid transparent;
            }
      `)}

      ${(): string =>
        useForSmallContainer(`
          text-align: right;

          &:first-of-type {
            text-align: left;
          }
        `)}
    }

    ${({ theme }): string =>
      useForLargeContainer(`
        &:hover {
            background-color: ${transparentize(0.92, theme.colors.highlight)};

            td {
                border-top: 2px solid ${theme.colors.highlight};
                border-bottom: 2px solid ${theme.colors.highlight};

                &:first-of-type {
                    border-left: 2px solid ${theme.colors.highlight};
                    border-radius: 0.9rem 0 0 0.9rem;
                }

                &:last-of-type {
                    border-right: 2px solid ${theme.colors.highlight};
                    border-radius: 0 0.9rem 0.9rem 0;
                }
            }
        }
    `)}
  }
`;
