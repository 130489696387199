fragment LookupUserActiveStepFragment on LookupUserActiveStep {
  config {
    redirectToUrl
    showInvalidCodeMessage
  }
  validationErrors {
    key
    field
  }
}
