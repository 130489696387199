#import "../../../../fragments/flows/referrer-journey/session.graphql"

mutation logoutReferrer($flowId: String!, $id: ID!) {
  logoutReferrer(input: { flowId: $flowId, id: $id }) {
    ok
    session {
      locale
      contentReplacements
      activeStep {
        ...ReferrerJourneySessionFragment
      }
    }
    errors
  }
}
