#import "../../../fragments/flows/lookup-user-journey/lookup-user-active-step.graphql"

query lookupUserJourneyGetSession(
  $flow: String!
  $campaignSlug: String!
  $step: String!
  $configurationId: ID!
  $state: PreviewStateInput
  $locale: String!
) {
  sessionPreview(
    flow: $flow
    campaignSlug: $campaignSlug
    step: $step
    state: $state
    configurationId: $configurationId
    locale: $locale
  ) {
    activeStep {
      type

      ... on LookupUserActiveStep {
        ...LookupUserActiveStepFragment
      }
    }
    contentReplacements
  }
}
