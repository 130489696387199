#import "../../../fragments/flows/partner-journey/config.graphql"

query partnerJourneyGetConfigPreview(
  $flow: String!
  $campaignSlug: String!
  $locale: String!
  $configurationId: ID!
  $flowId: ID
) {
  configPreview(
    flow: $flow
    campaignSlug: $campaignSlug
    locale: $locale
    configurationId: $configurationId
    flowId: $flowId
  ) {
    ...PartnerJourneyConfigFragment
  }
}
