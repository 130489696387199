fragment PartnerDashboardActiveStepFragment on PartnerDashboardActiveStep {
  config {
    referralStats {
      friendVisitCount
      completeReferralCount
      friendRegistrationCount
      cancelledReferralCount
      pendingReferralCount
    }
    sharingUrls {
      directLink
      shareCode
    }
    performance {
      total {
        visits
        created
        confirmed
        cancelled
        pending
        rewardsConfirmed {
          displayString
        }
      }
      details {
        date
        visits
        created
        confirmed
        cancelled
        rewardsConfirmed {
          displayString
        }
      }
    }
    rewards {
      partnerRewards {
        title
        body
      }
      audienceRewards {
        title
        body
      }
    }
  }
}
