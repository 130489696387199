import { EmbedParameters } from "types/embed-parameters.types";
import { ApolloClient } from "@apollo/client";
import { Config, SessionInterface } from "types/graphql";
import loginViaIdentifier from "./LoginViaIdentifier";
import loginViaAuthToken from "./LoginViaAuthToken";
import loginViaSeamless from "./LoginViaSeamless";
import partnerLoginViaAuthToken from "./PartnerLoginViaAuthToken";
import setupSession from "./SetupSession";
import loadConfig from "./LoadConfig";
import loadSession from "./LoadSession";

type SessionLoader = (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
  sessionId: string,
) => Promise<Record<string, any>>;

const flowSessionLoaders: { [key: string]: SessionLoader } = {
  click_to_claim_referrer: loadSession,
  click_to_claim_friend: loadSession,
  friend_journey: (
    apolloClient: ApolloClient<object>,
    params: EmbedParameters,
    sessionId: string,
  ): Promise<Record<string, any>> =>
    loginViaIdentifier(apolloClient, params, sessionId) ||
    loadSession(apolloClient, params, sessionId),
  referrer_journey: (
    apolloClient: ApolloClient<object>,
    params: EmbedParameters,
    sessionId: string,
  ): Promise<Record<string, any>> =>
    loginViaAuthToken(apolloClient, params, sessionId) ||
    loginViaSeamless(apolloClient, params) ||
    loadSession(apolloClient, params, sessionId),
  partner_journey: (
    apolloClient: ApolloClient<object>,
    params: EmbedParameters,
    sessionId: string,
  ): Promise<Record<string, any>> =>
    partnerLoginViaAuthToken(apolloClient, params, sessionId) ||
    loadSession(apolloClient, params, sessionId),
  user_lookup_journey: loadSession,
  redirect_friend_to_client_journey: loadSession,
  reward_redemption_journey: loadSession,
};

export default async (
  apolloClient: ApolloClient<object>,
  params: EmbedParameters,
  sessionId: string,
): Promise<[Config, SessionInterface]> => {
  setupSession(params);

  const sessionLoader = flowSessionLoaders[params.flow.name];
  if (!sessionLoader) {
    throw new Error(`flow not found: ${params.flow.name}`);
  }

  const session = await sessionLoader(apolloClient, params, sessionId);
  const config = await loadConfig(apolloClient, params, session.locale);

  return [config as Config, session as SessionInterface];
};
