#import "../../../fragments/flows/friend-journey/capture-additional-friend-details-active-step.graphql"
#import "../../../fragments/flows/friend-journey/successful-friend-registration-active-step.graphql"
#import "../../../fragments/flows/friend-journey/ineligible-for-voucher-active-step.graphql"
#import "../../../fragments/flows/friend-journey/referral-limit-reached-active-step.graphql"

query friendJourneyGetSessionPreview(
  $flow: String!
  $campaignSlug: String!
  $step: String!
  $configurationId: ID!
  $state: PreviewStateInput
  $locale: String!
) {
  sessionPreview(
    flow: $flow
    campaignSlug: $campaignSlug
    step: $step
    state: $state
    configurationId: $configurationId
    locale: $locale
  ) {
    activeStep {
      type

      ... on CaptureAdditionalFriendDetailsActiveStep {
        ...CaptureAdditionalFriendDetailsActiveStepFragment
      }
      ... on SuccessfulFriendRegistrationActiveStep {
        ...SuccessfulFriendRegistrationActiveStepFragment
      }
      ... on IneligibleForVoucherActiveStep {
        ...IneligibleForVoucherActiveStepFragment
      }
      ... on ReferralLimitReachedActiveStep {
        ...ReferralLimitReachedActiveStepFragment
      }
    }
    contentReplacements
  }
}
