#import "../../../fragments/flows/redirect-friend-to-client-journey/session.graphql"

query redirectFriendToClientJourneyGetSession(
  $flow: String!
  $flowId: ID!
  $id: ID!
  $requestedLocale: String
) {
  session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
    activeStep {
      ...RedirectFriendToClientJourneySessionFragment
    }
    contentReplacements
    locale
  }
}
