import React, { FC, useCallback, useEffect, useState } from "react";
import {
  InputStyled,
  DateContainerStyled,
  InputErrorWrapper,
  InputErrorStyled,
  InputLabelStyled,
  InputSpanStyled,
  InputLabelContainerStyled,
} from "./DateEditor.styled";
import { IconType } from "react-icons/lib";
import { DateTime } from "luxon";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Svg from "../svg/Svg";
import TargetTooltip from "../TargetTooltip";

type Props = {
  label: string;
  handleOnChange?: (value: string) => void;
  value?: string;
  defaultValue?: string;
  errorMessage?: string;
  showError?: boolean;
  required?: boolean;
  register?: any;
  name: string;
  error?: boolean;
  suffixIcon?: IconType;
  tooltip?: string;
};

const DateEditor: FC<Props> = ({
  label,
  handleOnChange,
  value,
  defaultValue,
  errorMessage,
  showError = false,
  required,
  register,
  name,
  tooltip,
}) => {
  const [ref, setRef] = useState<HTMLLabelElement | null>(null);

  const dateToParse = value || defaultValue || "";
  const parsed = dateToParse ? DateTime.fromISO(dateToParse) : null;

  const [day, setDay] = useState<string>(parsed?.day?.toString() || "");
  const [month, setMonth] = useState<string>(parsed?.month?.toString() || "");
  const [year, setYear] = useState<string>(parsed?.year?.toString() || "");

  const browserLocale = window.navigator.language === "en" ? "en-GB" : window.navigator.language;

  // Small hack to check if the locale formats month first
  const monthFirst = DateTime.local(2000, 12, 21)
    .setLocale(browserLocale)
    .toLocaleString(DateTime.DATE_SHORT)
    .startsWith("12");

  const registerResult =
    register &&
    register(name, {
      required,
      pattern: /^(19[0-9]{2}|20[0-9]{2})-([0-1]?[0-9])-([0-3]?[0-9])$/,
    });

  const updateValue = useCallback(
    (fieldValue: any) => {
      registerResult?.onChange({
        target: {
          name,
          value: fieldValue,
        },
      });
    },
    [registerResult, name],
  );

  useEffect(() => {
    if (day || month || year) {
      const parsedDate = DateTime.local(parseInt(year), parseInt(month), parseInt(day));

      const fieldValue =
        year.length === 4 && parsedDate.isValid
          ? parsedDate.toISODate()
          : `${year}-${month}-${day}`;

      updateValue(fieldValue);

      handleOnChange && handleOnChange(fieldValue || "");
    }
  }, [day, month, year]);

  const inputError = showError && (
    <InputErrorWrapper>
      <InputErrorStyled>{errorMessage || "Required"}</InputErrorStyled>
    </InputErrorWrapper>
  );

  const monthInput = (
    <InputStyled
      $showError={showError}
      onChange={(e) => setMonth(e.target.value)}
      value={month}
      name={`${name}_month`}
      placeholder="MM"
      maxLength={2}
      inputMode="numeric"
    />
  );

  const dayInput = (
    <InputStyled
      $showError={showError}
      onChange={(e) => setDay(e.target.value)}
      value={day}
      name={`${name}_day`}
      placeholder="DD"
      maxLength={2}
      inputMode="numeric"
    />
  );

  return (
    <InputLabelStyled
      ref={(e) => {
        setRef(e);
      }}
    >
      <InputLabelContainerStyled>
        <InputSpanStyled>{label}</InputSpanStyled>
        {tooltip ? (
          <TargetTooltip content={tooltip} targetRef={ref as HTMLElement} isInline={false}>
            <Svg svgImage={AiOutlineInfoCircle} />
          </TargetTooltip>
        ) : null}
      </InputLabelContainerStyled>

      <DateContainerStyled>
        {monthFirst ? monthInput : dayInput}
        {monthFirst ? dayInput : monthInput}

        <InputStyled
          $showError={showError}
          onChange={(e) => setYear(e.target.value)}
          value={year}
          name={`${name}_year`}
          placeholder="YYYY"
          maxLength={4}
          inputMode="numeric"
        />
      </DateContainerStyled>
      {inputError}
    </InputLabelStyled>
  );
};

export default DateEditor;
