import React, { FC } from "react";
import { Context } from ".";
import renderTemplate from "lib/Template";
import { ContentStyled, HeadingStyled, WrapperStyled } from "./SuccessfulFriendRegistration.styled";

const Heading: FC<{ context: Context }> = ({ context }) => {
  if (!context.step.optionalContent) {
    return null;
  }

  return (
    <WrapperStyled>
      <ContentStyled>
        <HeadingStyled>
          {renderTemplate(
            context.step.optionalContent.title,
            context.state.sessionContentReplacements,
          )}
        </HeadingStyled>
      </ContentStyled>
    </WrapperStyled>
  );
};

export default Heading;
