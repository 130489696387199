query clickToClaimReferrerGetSessionPreview(
  $flow: String!
  $campaignSlug: String!
  $step: String!
  $configurationId: ID!
  $state: PreviewStateInput
  $locale: String!
) {
  sessionPreview(
    flow: $flow
    campaignSlug: $campaignSlug
    step: $step
    state: $state
    configurationId: $configurationId
    locale: $locale
  ) {
    activeStep {
      type

      ... on ClickToClaimOptionsSelectorActiveStep {
        config {
          rewardTypeId
          allowedRewardOptions
        }
      }
    }
    contentReplacements
  }
}
