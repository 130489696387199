import styled from "styled-components";
import { transparentize } from "polished";
import { resolveColor, resolveFontSize } from "styles/helpers";
import { css } from "styled-components";

type StyledHeaderWrapperProps = {
  $anyReferrals: boolean;
};

type Props = {
  isOpen: boolean;
};

export const MobileWrapper = styled.div`
  padding-bottom: 2rem;
`;

export const HeaderWrapper = styled.div<StyledHeaderWrapperProps>`
  border: 1px solid ${({ theme }): string => transparentize(0.8, theme.layout.color)};
  border-radius: 8px;
  overflow: ${({ $anyReferrals }) => ($anyReferrals ? "visible" : "hidden")};
`;

export const PillBackGroundStyled = styled.div<Props>`
  outline: none;
  display: flex;
  justify-content: center;
  align-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${({ theme, isOpen }) =>
    isOpen ? "transparent" : transparentize(0.9, theme.layout.color)};
`;

export const PillWrapperStyled = styled.div`
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
  outline: none;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Chip = styled.div`
  background-color: #717171;
  color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};
  cursor: pointer;
  padding: 0.6rem 0.8rem;
  border-radius: 13px;
  font-size: ${({ theme }) => resolveFontSize(theme, "small")};
  margin-left: auto;
  margin-right: auto;
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
`;

export const ChildrenBody = styled.div<Props>`
  max-height: 0;
  transition: max-height 0.05s ease-out;
  overflow: hidden;
  ${({ isOpen }) =>
    isOpen &&
    css`
      max-height: 500px;
      transition: max-height 0.25s ease-in;
    `}
`;
