import React, { FC } from "react";
import { StyledCSVLink } from "./DownloadToCsv.styled";
import { MdOutlineFileDownload } from "react-icons/md";
import { ButtonStyled } from "components/button/Button.styled";

type CsvDataHeader = {
  label: string;
  key: string;
};

type DownloadToCsvProps = {
  text: string;
  headers: CsvDataHeader[];
  data: any[];
  disabled?: boolean;
};

const DownloadToCsv: FC<DownloadToCsvProps> = ({ text, headers, data, disabled = false }) => {
  if (disabled) {
    return (
      <ButtonStyled $appearance="primary" disabled>
        <>
          {<MdOutlineFileDownload />}
          {text}
        </>
      </ButtonStyled>
    );
  }

  return (
    <StyledCSVLink
      data={data}
      headers={headers}
      filename={"monthly-performance.csv"}
      target="_blank"
      role="button"
    >
      <>
        {<MdOutlineFileDownload />}
        {text}
      </>
    </StyledCSVLink>
  );
};

export default DownloadToCsv;
