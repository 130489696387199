import Svg from "components/svg/Svg";
import TargetTooltip from "components/TargetTooltip";
import React, { FC, useState } from "react";
import { CgInfo } from "react-icons/cg";
import { IconType } from "react-icons/lib";
import {
  InputStyled,
  InputLabelStyled,
  InputSpanStyled,
  InputErrorWrapper,
  InputErrorStyled,
  InputWrapperStyled,
} from "./Input.styled";

type Props = {
  inputType?: "text" | "email" | "tel";
  placeholder?: string;
  label: string;
  handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string | number;
  defaultValue?: string | number;
  errorMessage?: string;
  showError?: boolean;
  required?: boolean;
  register?: any;
  name: string;
  showLabel?: boolean;
  regex?: string | null;
  error?: boolean;
  prefixIcon?: IconType;
  suffixIcon?: IconType;
  tooltip?: string;
  numericKeypad?: boolean;
};

const Input: FC<Props> = ({
  inputType = "text",
  placeholder,
  label,
  handleOnChange,
  defaultValue,
  errorMessage,
  showError = false,
  required,
  register,
  name,
  showLabel = false,
  regex,
  prefixIcon,
  suffixIcon,
  tooltip,
  value,
  numericKeypad = false,
}) => {
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);

  const inputError = showError && (
    <InputErrorWrapper>
      <InputErrorStyled>{errorMessage || "Required"}</InputErrorStyled>
    </InputErrorWrapper>
  );

  const validator = regex ? new RegExp(regex) : null;

  if (tooltip && !suffixIcon) suffixIcon = CgInfo;

  const prefixIconPrepared = prefixIcon && <Svg svgImage={prefixIcon} className="prefix" />;
  const suffixIconPrepared = suffixIcon && <Svg svgImage={suffixIcon} className="suffix" />;
  const suffixContent =
    suffixIcon && tooltip ? (
      <TargetTooltip content={tooltip} targetRef={inputRef as HTMLElement}>
        {suffixIconPrepared}
      </TargetTooltip>
    ) : (
      suffixIconPrepared
    );

  const registerResult = register
    ? register(name, { required, pattern: { value: validator } })
    : null;

  return (
    <>
      <InputLabelStyled>
        <InputSpanStyled $showLabel={showLabel}>{label}</InputSpanStyled>
        <InputWrapperStyled>
          {prefixIconPrepared}
          <InputStyled
            autoComplete="off"
            value={value}
            defaultValue={defaultValue}
            $hasSuffixIcon={!!suffixIcon}
            $hasPrefixIcon={!!prefixIcon}
            name={name}
            $showError={showError}
            type={inputType}
            inputMode={numericKeypad ? "numeric" : undefined}
            placeholder={placeholder}
            {...registerResult}
            onChange={async (event) => {
              handleOnChange && handleOnChange(event);
              await registerResult?.onChange(event);
            }}
            ref={(e) => {
              registerResult?.ref(e);
              setInputRef(e);
            }}
          />
          {suffixContent}
        </InputWrapperStyled>
      </InputLabelStyled>
      {inputError}
    </>
  );
};

export default Input;
