#import "../../../fragments/flows/partner-journey/partner-dashboard-active-step.graphql"

query partnerJourneyGetSessionPreview(
  $flow: String!
  $campaignSlug: String!
  $step: String!
  $configurationId: ID!
  $state: PreviewStateInput
  $locale: String!
) {
  sessionPreview(
    flow: $flow
    campaignSlug: $campaignSlug
    step: $step
    state: $state
    configurationId: $configurationId
    locale: $locale
  ) {
    activeStep {
      type

      ... on PartnerDashboardActiveStep {
        ...PartnerDashboardActiveStepFragment
      }
    }
    contentReplacements
  }
}
