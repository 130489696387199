fragment ReferrerDashboardActiveStepFragment on ReferrerDashboardActiveStep {
  config {
    canLogout
    manualSharingShortUrl
    nativeSharingShortUrl
    emailSharingShortUrl
    shareNetworkShortUrls {
      facebook
      twitter
      pinterest
      reddit
      whatsapp
      facebookMessenger
      line
      vkontakte
      zalo
      viber
      telegram
    }
    rewardCappingType
    showRewardLimitWarningMessage
    referralStatus {
      friendVisitCount
      completeReferralCount
      friendRegistrationCount
      cancelledReferralCount
      pendingReferralCount
      completeReferrals {
        name
        date
        status
      }
      pendingReferrals {
        name
        date
        status
      }
    }
  }
}
