#import "../../shared-style.graphql"
#import "../../custom-fonts.graphql"
#import "../../urls.graphql"

fragment PartnerJourneyConfigFragment on Config {
  customFonts {
    ...CustomFontsFragment
  }
  styles {
    ...SharedStyleFragment
  }
  globalStyles
  localisation {
    date {
      dayNames
      abbreviatedDayNames
      monthNames
      abbreviatedMonthNames
      shortFormat
      longFormat
      monthOnlyFormat
    }
  }
  steps {
    type

    ... on PartnerPageDisabledStep {
      content {
        title
        body
      }
    }

    ... on PartnerDashboardStep {
      content {
        title
        referralStats {
          title
          friendVisitCount
          completeReferralCount
          friendRegistrationCount
          cancelledReferralCount
          pendingReferralCount
        }
        partnerOffer {
          title
          heading
          body
          image {
            url
            altText
            mobile {
              url
              altText
            }
          }
        }
        audienceOffer {
          title
          heading
          body
          image {
            url
            altText
            mobile {
              url
              altText
            }
          }
        }
        sharingTools {
          title
          directLink {
            label
            copyButtonText
            copySuccessNotification
            nativeSharing {
              buttonText
              title
            }
            shareMessage
          }
          shareCode {
            label
            copyButtonText
            copySuccessNotification
            nativeSharing {
              buttonText
              title
            }
            shareMessage
          }
        }
        usefulLinks {
          title
          buttons {
            text
            url
          }
        }
        performance {
          title
          table {
            headers {
              month
              visits
              created
              confirmed
              cancelled
              rewardsConfirmed
            }
            tooltips {
              visits
              created
              confirmed
              cancelled
            }
            total
            downloadButtonText
          }
        }
        rules {
          title
          body
        }
      }
      config {
        partnerOffer {
          displayRewards
        }
        audienceOffer {
          displayRewards
        }
        sharingTools {
          displayDirectLink
          displayShareCode
        }
        showReferralStats
        referralStats
      }
    }
  }
  urls {
    ...UrlsFragment
  }
}
