import React, { FC } from "react";
import { TableCell } from "./Item.styled";
import Tooltip from "components/Tooltip/Tooltip";
import { ReferrerDashboardActiveStepConfigReferralStatusReferral } from "types/graphql";
import { Name, DateStyled } from "../../../Shared";
import FormattedDate, { formatDate, Format } from "components/FormattedDate";

type Props = {
  item: ReferrerDashboardActiveStepConfigReferralStatusReferral;
  type: string;
  statusText: string;
};

const Row: FC<Props> = ({ item, type, statusText }) => {
  return (
    <>
      <TableCell data-tooltip-id="referrer-status-name" data-tooltip-content={item.name}>
        <Tooltip message={item.name} type={"friend-status-name"}>
          <Name>{item.name}</Name>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip message={formatDate(item.date, Format.Long)} type={"friend-status-date"}>
          <DateStyled>
            <FormattedDate timestamp={item.date} />
          </DateStyled>
        </Tooltip>
      </TableCell>
    </>
  );
};

export default Row;
