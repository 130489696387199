#import "../../shared-style.graphql"
#import "../../custom-fonts.graphql"
#import "../../urls.graphql"

fragment RedirectFriendToClientJourneyConfigFragment on Config {
  customFonts {
    ...CustomFontsFragment
  }
  styles {
    ...SharedStyleFragment
  }
  globalStyles
  steps {
    type

    ... on ClickToRedirectFriendStep {
      optionalContent {
        title
        body
        image {
          url
          altText
          mobile {
            url
            altText
          }
        }
        form {
          caption
          button {
            text
          }
          finePrint
        }
        additionalFinePrint
      }
    }
  }
  urls {
    ...UrlsFragment
  }
  programType
}
