import styled from "styled-components";
import {
  cardBackgroundColor,
  cardDescriptionColor,
  cardTitleColor,
  resolveColor,
  resolveFontSize,
  resolveWithDefault,
} from "styles/helpers";
import { useForLargeContainer, useForSmallContainer } from "hooks/ResponsiveStyle";

import { ParagraphStyled, TitleStyled as DefaultTitleStyled } from "styles/global.styled";
import { transparentize } from "polished";
import { SvgWrapperStyled } from "components/svg/Svg.styled";

type ContainerStyledProps = {
  $optionsCount: number;
};

export const WrapperStyled = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  margin: 0 15px;

  img {
    width: 100%;
  }
`;

export const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 24px;
`;

export const TitleStyled = styled(DefaultTitleStyled)`
  margin: 24px 0 10px 0;
`;

export const ContainerStyled = styled.div<ContainerStyledProps>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 1020px;
  margin: 0 auto;

  ${(props) =>
    props.$optionsCount < 3 &&
    useForLargeContainer(`
      justify-content: center;
      > div {
        margin-right: 20px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    `)}

  ${(props) =>
    props.$optionsCount < 3 &&
    useForSmallContainer(`
      > div {
        margin-right: 0;
      }
    `)}
`;

export const CardStyled = styled.div`
  background-color: ${({ theme }) => cardBackgroundColor(theme)};
  border-radius: 12px;
  box-shadow: 0 0 12px 0 rgba(21, 40, 50, 0.2);
  margin-bottom: 20px;
  text-align: center;
  display: inline-flex;
  flex-direction: column;
  width: 326px;
`;

export const CardTitleStyled = styled.h4`
  margin: 10px 0;
  font-size: ${({ theme }) => resolveFontSize(theme, theme.card.title.fontSize)};
  font-weight: ${({ theme }) => theme.card.title.fontWeight};
  color: ${({ theme }) => cardTitleColor(theme)};
`;

export const CardDescriptionStyled = styled(ParagraphStyled)`
  font-size: ${({ theme }) => resolveFontSize(theme, theme.card.description.fontSize)};
  font-weight: ${({ theme }) => theme.card.description.fontWeight};
  color: ${({ theme }) => cardDescriptionColor(theme)};
  margin: 10px 0 30px 0;
`;

export const ImageContainerStyled = styled.div`
  background-color: #e6e8e8;
  padding: 20px 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageStyled = styled.div`
  width: 185px;
  height: 116px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
`;

export const CopyStyled = styled.div`
  padding: 24px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;

  > p {
    flex: 1;
  }
`;

export const SubmissionErrorStyled = styled(ParagraphStyled)`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
`;

export const ExpiryInfoStyled = styled.div`
  display: flex;
  align-items: center;

  max-width: 1020px;
  ${() => useForSmallContainer(`width: 326px;`)}

  margin: 0 auto;

  border-radius: 0.8rem;
  padding: 1.5rem;
  font-size: 0.8rem;
  font-weight: bold;

  > ${SvgWrapperStyled} {
    flex: 0 0 1.2rem;

    width: 1.2rem;
    height: 1.2rem;

    margin-right: 1rem;
  }

  background-color: ${({ theme }) =>
    resolveColor(
      theme,
      resolveWithDefault(
        theme.warningBanner?.backgroundColor,
        transparentize(0.92, theme.colors.highlight),
      ),
    )};
  color: ${({ theme }) =>
    resolveColor(theme, resolveWithDefault(theme.warningBanner?.color, theme.colors.highlight))};
`;
