query clickToClaimReferrerGetSession(
  $flow: String!
  $flowId: ID!
  $id: ID!
  $requestedLocale: String
) {
  session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
    activeStep {
      type

      ... on ClickToClaimOptionsSelectorActiveStep {
        config {
          rewardTypeId
          allowedRewardOptions
        }
      }
    }
    contentReplacements
    locale
  }
}
