import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  ISO8601Date: { input: any; output: any };
  ISO8601DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
};

/** All active steps have a type */
export type ActiveStepInterface = {
  type: Scalars["String"]["output"];
};

/** Autogenerated input type of AddFriendActionId */
export type AddFriendActionIdInput = {
  actionId: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of AddFriendActionId. */
export type AddFriendActionIdPayload = {
  __typename?: "AddFriendActionIdPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

/** Autogenerated input type of AddFriendDetails */
export type AddFriendDetailsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  customFields: Array<CustomFieldInput>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of AddFriendDetails. */
export type AddFriendDetailsPayload = {
  __typename?: "AddFriendDetailsPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

/** Autogenerated input type of AddFriendReferral */
export type AddFriendReferralInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of AddFriendReferral. */
export type AddFriendReferralPayload = {
  __typename?: "AddFriendReferralPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type AutomaticallyRedirectFriendActiveStep = ActiveStepInterface & {
  __typename?: "AutomaticallyRedirectFriendActiveStep";
  config: AutomaticallyRedirectFriendActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type AutomaticallyRedirectFriendActiveStepConfig = {
  __typename?: "AutomaticallyRedirectFriendActiveStepConfig";
  destinationUrl?: Maybe<Scalars["String"]["output"]>;
  requiresFriendRegistration: Scalars["Boolean"]["output"];
};

export type AutomaticallyRedirectFriendStep = StepInterface & {
  __typename?: "AutomaticallyRedirectFriendStep";
  type: Scalars["String"]["output"];
};

export type AvatarStyle = {
  __typename?: "AvatarStyle";
  backgroundColor: Scalars["String"]["output"];
  borderRadius: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type BannerStyle = {
  __typename?: "BannerStyle";
  backgroundColor: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
};

export type BasicButtonContent = ButtonContentInterface & {
  __typename?: "BasicButtonContent";
  buttonClass?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type Button = {
  __typename?: "Button";
  borderRadius: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
  height: Scalars["String"]["output"];
  primary: ButtonState;
  secondary: ButtonState;
};

export type ButtonContent = {
  __typename?: "ButtonContent";
  text: Scalars["String"]["output"];
};

export type ButtonContentInterface = {
  buttonClass?: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type ButtonOnlyFormContent = {
  __typename?: "ButtonOnlyFormContent";
  button: ButtonContent;
  caption: Scalars["String"]["output"];
  finePrint: Scalars["String"]["output"];
};

export type ButtonState = {
  __typename?: "ButtonState";
  _disabled?: Maybe<DisabledStyle>;
  backgroundColor: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type CaptureAdditionalFriendDetailsActiveStep = ActiveStepInterface & {
  __typename?: "CaptureAdditionalFriendDetailsActiveStep";
  config: CaptureAdditionalFriendDetailsActiveStepConfig;
  type: Scalars["String"]["output"];
  validationErrors: Array<ValidationError>;
};

export type CaptureAdditionalFriendDetailsActiveStepConfig = {
  __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig";
  firstVisit: Scalars["Boolean"]["output"];
};

export type CaptureAdditionalFriendDetailsCardContent = {
  __typename?: "CaptureAdditionalFriendDetailsCardContent";
  body?: Maybe<Scalars["String"]["output"]>;
  finePrint?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export type CaptureAdditionalFriendDetailsFirstVisitContent = {
  __typename?: "CaptureAdditionalFriendDetailsFirstVisitContent";
  card: CaptureAdditionalFriendDetailsCardContent;
  instructions: Scalars["String"]["output"];
};

export type CaptureAdditionalFriendDetailsFormContent = {
  __typename?: "CaptureAdditionalFriendDetailsFormContent";
  button: ButtonContent;
  inputs: CaptureAdditionalFriendDetailsInputsContent;
  submitError: Scalars["String"]["output"];
};

export type CaptureAdditionalFriendDetailsInputsContent = {
  __typename?: "CaptureAdditionalFriendDetailsInputsContent";
  customFields: Array<CustomFieldInputContent>;
};

export type CaptureAdditionalFriendDetailsNextVisitContent = {
  __typename?: "CaptureAdditionalFriendDetailsNextVisitContent";
  card: CaptureAdditionalFriendDetailsCardContent;
  instructions?: Maybe<Scalars["String"]["output"]>;
  showButtons: Scalars["Boolean"]["output"];
};

export type CaptureAdditionalFriendDetailsStep = StepInterface & {
  __typename?: "CaptureAdditionalFriendDetailsStep";
  optionalConfig?: Maybe<CaptureAdditionalFriendDetailsStepConfig>;
  optionalContent?: Maybe<CaptureAdditionalFriendDetailsStepContent>;
  type: Scalars["String"]["output"];
};

export type CaptureAdditionalFriendDetailsStepConfig = StepConfigInterface & {
  __typename?: "CaptureAdditionalFriendDetailsStepConfig";
  inputs: CaptureAdditionalFriendDetailsStepConfigInputs;
  layout: Scalars["String"]["output"];
};

export type CaptureAdditionalFriendDetailsStepConfigInputs = {
  __typename?: "CaptureAdditionalFriendDetailsStepConfigInputs";
  customFields: Array<CustomFieldInputConfigInterface>;
};

export type CaptureAdditionalFriendDetailsStepContent = {
  __typename?: "CaptureAdditionalFriendDetailsStepContent";
  buttons: Array<ButtonContentInterface>;
  finePrint?: Maybe<Scalars["String"]["output"]>;
  firstVisit: CaptureAdditionalFriendDetailsFirstVisitContent;
  form: CaptureAdditionalFriendDetailsFormContent;
  nextVisit: CaptureAdditionalFriendDetailsNextVisitContent;
};

export type CaptureFriendDetailsActiveStep = ActiveStepInterface & {
  __typename?: "CaptureFriendDetailsActiveStep";
  config: CaptureFriendDetailsActiveStepConfig;
  type: Scalars["String"]["output"];
  validationErrors: Array<ValidationError>;
};

export type CaptureFriendDetailsActiveStepConfig = {
  __typename?: "CaptureFriendDetailsActiveStepConfig";
  rewards?: Maybe<Array<CaptureFriendDetailsActiveStepConfigRewardDetail>>;
};

export type CaptureFriendDetailsActiveStepConfigRewardDetail = {
  __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
  body: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type CaptureFriendDetailsStep = StepInterface & {
  __typename?: "CaptureFriendDetailsStep";
  config: CaptureFriendDetailsStepConfig;
  content: CaptureFriendDetailsStepContent;
  type: Scalars["String"]["output"];
};

export type CaptureFriendDetailsStepConfig = StepConfigInterface & {
  __typename?: "CaptureFriendDetailsStepConfig";
  button: CaptureFriendDetailsStepConfigButton;
  displayPartnerRewards: Scalars["Boolean"]["output"];
  inputs: CaptureFriendDetailsStepConfigInputs;
  layout: Scalars["String"]["output"];
  partialRegistration: Scalars["Boolean"]["output"];
};

export type CaptureFriendDetailsStepConfigButton = InputConfigInterface & {
  __typename?: "CaptureFriendDetailsStepConfigButton";
  show: Scalars["Boolean"]["output"];
};

export type CaptureFriendDetailsStepConfigInputs = {
  __typename?: "CaptureFriendDetailsStepConfigInputs";
  customFields: Array<CustomFieldInputConfigInterface>;
  email: TextInputConfig;
  firstName: TextInputConfig;
  fullName: TextInputConfig;
  lastName: TextInputConfig;
  marketingOptIn: MarketingOptInInputConfig;
  termsOptIn: TermsOptInInputConfig;
};

export type CaptureFriendDetailsStepContent = {
  __typename?: "CaptureFriendDetailsStepContent";
  additionalFinePrint?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  form: FormContent;
  image: ResponsiveImage;
  partnerDetails?: Maybe<PartnerDetails>;
  title: Scalars["String"]["output"];
};

export type CaptureReferrerDetailsActiveStep = ActiveStepInterface & {
  __typename?: "CaptureReferrerDetailsActiveStep";
  config: CaptureReferrerDetailsActiveStepConfig;
  type: Scalars["String"]["output"];
  validationErrors: Array<ValidationError>;
};

export type CaptureReferrerDetailsActiveStepConfig = {
  __typename?: "CaptureReferrerDetailsActiveStepConfig";
  showEligibilityCheckRejectionMessage: Scalars["Boolean"]["output"];
  showSignInLinkEmailedMessage: Scalars["Boolean"]["output"];
};

export type CaptureReferrerDetailsStep = StepInterface & {
  __typename?: "CaptureReferrerDetailsStep";
  optionalConfig?: Maybe<CaptureReferrerDetailsStepConfig>;
  optionalContent?: Maybe<CaptureReferrerDetailsStepContent>;
  type: Scalars["String"]["output"];
};

export type CaptureReferrerDetailsStepConfig = StepConfigInterface & {
  __typename?: "CaptureReferrerDetailsStepConfig";
  button: CaptureReferrerDetailsStepConfigButton;
  inputs: CaptureReferrerDetailsStepConfigInputs;
  layout: Scalars["String"]["output"];
  offerDetails: OfferDetailsConfig;
};

export type CaptureReferrerDetailsStepConfigButton = InputConfigInterface & {
  __typename?: "CaptureReferrerDetailsStepConfigButton";
  show: Scalars["Boolean"]["output"];
};

export type CaptureReferrerDetailsStepConfigInputs = {
  __typename?: "CaptureReferrerDetailsStepConfigInputs";
  customFields: Array<CustomFieldInputConfigInterface>;
  email: TextInputConfig;
  firstName: TextInputConfig;
  fullName: TextInputConfig;
  lastName: TextInputConfig;
  marketingOptIn: MarketingOptInInputConfig;
  termsOptIn: TermsOptInInputConfig;
};

export type CaptureReferrerDetailsStepContent = {
  __typename?: "CaptureReferrerDetailsStepContent";
  additionalFinePrint?: Maybe<Scalars["String"]["output"]>;
  eligibilityCheckRejectionMessage: Scalars["String"]["output"];
  form: FormContent;
  image?: Maybe<ResponsiveImage>;
  offerDetails: OfferDetailsContent;
  signInLinkEmailedMessage: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type Card = {
  __typename?: "Card";
  backgroundColor: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  description: CardTextElement;
  title: CardTextElement;
};

export type CardTextElement = {
  __typename?: "CardTextElement";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

export type CheckboxInputContent = InputContentInterface & {
  __typename?: "CheckboxInputContent";
  label: Scalars["String"]["output"];
  validationErrors: Array<ValidationErrorContent>;
};

/** Autogenerated input type of ClaimReward */
export type ClaimRewardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  optionKey: Scalars["ID"]["input"];
  userType: Scalars["String"]["input"];
};

/** Autogenerated return type of ClaimReward. */
export type ClaimRewardPayload = {
  __typename?: "ClaimRewardPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type ClickToClaimErrorActiveStep = ActiveStepInterface & {
  __typename?: "ClickToClaimErrorActiveStep";
  type: Scalars["String"]["output"];
};

export type ClickToClaimFriendRewardType = ClickToClaimRewardTypeInterface & {
  __typename?: "ClickToClaimFriendRewardType";
  id: Scalars["ID"]["output"];
  rewardOptions: Array<RewardOption>;
};

export type ClickToClaimLink = InputConfigInterface & {
  __typename?: "ClickToClaimLink";
  show: Scalars["Boolean"]["output"];
};

export type ClickToClaimOptionConfirmationActiveStep = ActiveStepInterface & {
  __typename?: "ClickToClaimOptionConfirmationActiveStep";
  type: Scalars["String"]["output"];
};

export type ClickToClaimOptionConfirmationStep = StepInterface & {
  __typename?: "ClickToClaimOptionConfirmationStep";
  config: ClickToClaimOptionConfirmationStepConfig;
  content: ClickToClaimOptionConfirmationStepContent;
  type: Scalars["String"]["output"];
};

export type ClickToClaimOptionConfirmationStepConfig = {
  __typename?: "ClickToClaimOptionConfirmationStepConfig";
  footerLink: ClickToClaimLink;
};

export type ClickToClaimOptionConfirmationStepContent = {
  __typename?: "ClickToClaimOptionConfirmationStepContent";
  cancelButton: Scalars["String"]["output"];
  claimRewardError: Scalars["String"]["output"];
  confirmButton: Scalars["String"]["output"];
  footerLink: LinkContent;
  title: Scalars["String"]["output"];
};

export type ClickToClaimOptionsSelectorActiveStep = ActiveStepInterface & {
  __typename?: "ClickToClaimOptionsSelectorActiveStep";
  config: ClickToClaimOptionsSelectorActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type ClickToClaimOptionsSelectorActiveStepConfig = {
  __typename?: "ClickToClaimOptionsSelectorActiveStepConfig";
  allowedRewardOptions: Array<Scalars["String"]["output"]>;
  rewardTypeId: Scalars["ID"]["output"];
};

export type ClickToClaimOptionsSelectorStep = StepInterface & {
  __typename?: "ClickToClaimOptionsSelectorStep";
  config: ClickToClaimOptionsSelectorStepConfig;
  content: ClickToClaimOptionsSelectorStepContent;
  type: Scalars["String"]["output"];
};

export type ClickToClaimOptionsSelectorStepConfig = {
  __typename?: "ClickToClaimOptionsSelectorStepConfig";
  introLink: ClickToClaimLink;
  rewardTypes: Array<ClickToClaimRewardType>;
};

export type ClickToClaimOptionsSelectorStepContent = {
  __typename?: "ClickToClaimOptionsSelectorStepContent";
  body: Scalars["String"]["output"];
  claimRewardError: Scalars["String"]["output"];
  expiryInfo: Scalars["String"]["output"];
  introLink: LinkContent;
  title: Scalars["String"]["output"];
};

export type ClickToClaimRewardType = ClickToClaimRewardTypeInterface & {
  __typename?: "ClickToClaimRewardType";
  id: Scalars["ID"]["output"];
  rewardOptions: Array<RewardOption>;
};

export type ClickToClaimRewardTypeInterface = {
  id: Scalars["ID"]["output"];
  rewardOptions: Array<RewardOption>;
};

export type ClickToRedirectFriendActiveStep = ActiveStepInterface & {
  __typename?: "ClickToRedirectFriendActiveStep";
  config: ClickToRedirectFriendActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type ClickToRedirectFriendActiveStepConfig = {
  __typename?: "ClickToRedirectFriendActiveStepConfig";
  destinationUrl?: Maybe<Scalars["String"]["output"]>;
  requiresFriendRegistration: Scalars["Boolean"]["output"];
};

export type ClickToRedirectFriendStep = StepInterface & {
  __typename?: "ClickToRedirectFriendStep";
  optionalContent?: Maybe<ClickToRedirectFriendStepContent>;
  type: Scalars["String"]["output"];
};

export type ClickToRedirectFriendStepContent = {
  __typename?: "ClickToRedirectFriendStepContent";
  additionalFinePrint?: Maybe<Scalars["String"]["output"]>;
  body: Scalars["String"]["output"];
  form: ButtonOnlyFormContent;
  image: ImageContent;
  title: Scalars["String"]["output"];
};

export type Colors = {
  __typename?: "Colors";
  error: Scalars["String"]["output"];
  highlight: Scalars["String"]["output"];
};

export type Config = {
  __typename?: "Config";
  customFonts: Array<CustomFont>;
  globalStyles: Scalars["JSON"]["output"];
  localisation: Localisation;
  programType: Scalars["String"]["output"];
  steps: Array<StepInterface>;
  styles: Styles;
  urls: Urls;
};

export type ConfirmFriendRewardOptionActiveStep = ActiveStepInterface & {
  __typename?: "ConfirmFriendRewardOptionActiveStep";
  type: Scalars["String"]["output"];
};

export type ConfirmFriendRewardOptionStep = StepInterface & {
  __typename?: "ConfirmFriendRewardOptionStep";
  config: ConfirmFriendRewardOptionStepConfig;
  content: ConfirmFriendRewardOptionStepContent;
  type: Scalars["String"]["output"];
};

export type ConfirmFriendRewardOptionStepConfig = {
  __typename?: "ConfirmFriendRewardOptionStepConfig";
  footerLink: ClickToClaimLink;
};

export type ConfirmFriendRewardOptionStepContent = {
  __typename?: "ConfirmFriendRewardOptionStepContent";
  cancelButton: Scalars["String"]["output"];
  claimRewardError: Scalars["String"]["output"];
  confirmButton: Scalars["String"]["output"];
  footerLink: LinkContent;
  title: Scalars["String"]["output"];
};

export type ContinueToClientSiteActiveStep = ActiveStepInterface & {
  __typename?: "ContinueToClientSiteActiveStep";
  config: ContinueToClientSiteActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type ContinueToClientSiteActiveStepConfig = {
  __typename?: "ContinueToClientSiteActiveStepConfig";
  destinationUrl: Scalars["String"]["output"];
};

export type ContinueToClientSiteStep = StepInterface & {
  __typename?: "ContinueToClientSiteStep";
  type: Scalars["String"]["output"];
};

export type CustomFieldInput = {
  key: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type CustomFieldInputConfig = CustomFieldInputConfigInterface & {
  __typename?: "CustomFieldInputConfig";
  inputFieldType: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  pattern: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
};

export type CustomFieldInputConfigInterface = {
  inputFieldType: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
};

export type CustomFieldInputContent = {
  __typename?: "CustomFieldInputContent";
  content: TextInputContent;
  key: Scalars["String"]["output"];
};

export type CustomFieldListInputConfig = CustomFieldInputConfigInterface & {
  __typename?: "CustomFieldListInputConfig";
  inputFieldType: Scalars["String"]["output"];
  items: Array<Scalars["String"]["output"]>;
  key: Scalars["String"]["output"];
  position: Scalars["Int"]["output"];
};

export type CustomFont = {
  __typename?: "CustomFont";
  family: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type Date = {
  __typename?: "Date";
  abbreviatedDayNames: Array<Scalars["String"]["output"]>;
  abbreviatedMonthNames: Array<Scalars["String"]["output"]>;
  dayNames: Array<Scalars["String"]["output"]>;
  longFormat: Scalars["String"]["output"];
  monthNames: Array<Scalars["String"]["output"]>;
  monthOnlyFormat: Scalars["String"]["output"];
  shortFormat: Scalars["String"]["output"];
};

export type DisabledStyle = {
  __typename?: "DisabledStyle";
  backgroundColor: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type EmphasisStyle = {
  __typename?: "EmphasisStyle";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontStyle: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

export type ErrorStyle = {
  __typename?: "ErrorStyle";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

/** All available events */
export enum EventEnum {
  ClickToClaimFriendLinkClicks = "click_to_claim_friend_link_clicks",
  ClickToClaimReferrerLinkClicks = "click_to_claim_referrer_link_clicks",
  ContinueToClientSite = "continue_to_client_site",
  Share = "share",
  VisitCampaign = "visit_campaign",
  VisitCaptureReferrerDetailsPage = "visit_capture_referrer_details_page",
  VisitClickToClaimFriendPage = "visit_click_to_claim_friend_page",
  VisitClickToClaimReferrerPage = "visit_click_to_claim_referrer_page",
  VisitReferrerDashboardPage = "visit_referrer_dashboard_page",
  VisitRewardRedemptionPage = "visit_reward_redemption_page",
  VisitSplashPage = "visit_splash_page",
  VisitVoucherPage = "visit_voucher_page",
}

export type Font = {
  __typename?: "Font";
  family: Scalars["String"]["output"];
  lineHeight: Scalars["String"]["output"];
  sizes: FontSizes;
  weight: Scalars["String"]["output"];
};

export type FontSizes = {
  __typename?: "FontSizes";
  extraLarge: Scalars["String"]["output"];
  extraSmall: Scalars["String"]["output"];
  large: Scalars["String"]["output"];
  medium: Scalars["String"]["output"];
  small: Scalars["String"]["output"];
};

export type Form = {
  __typename?: "Form";
  backgroundColor: Scalars["String"]["output"];
  borderColor: Scalars["String"]["output"];
  captionColor: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  error: ErrorStyle;
};

export type FormContent = {
  __typename?: "FormContent";
  button: ButtonContent;
  caption: Scalars["String"]["output"];
  finePrint: Scalars["String"]["output"];
  inputs: InputsContent;
  submitError: Scalars["String"]["output"];
};

export type FriendRewardAlreadyClaimedActiveStep = ActiveStepInterface & {
  __typename?: "FriendRewardAlreadyClaimedActiveStep";
  type: Scalars["String"]["output"];
};

export type FriendRewardAlreadyClaimedStep = StepInterface & {
  __typename?: "FriendRewardAlreadyClaimedStep";
  content: FriendRewardAlreadyClaimedStepContent;
  type: Scalars["String"]["output"];
};

export type FriendRewardAlreadyClaimedStepContent = {
  __typename?: "FriendRewardAlreadyClaimedStepContent";
  body: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type FriendRewardClaimedActiveStep = ActiveStepInterface & {
  __typename?: "FriendRewardClaimedActiveStep";
  type: Scalars["String"]["output"];
};

export type FriendRewardClaimedStep = StepInterface & {
  __typename?: "FriendRewardClaimedStep";
  config: FriendRewardClaimedStepConfig;
  content: FriendRewardClaimedStepContent;
  type: Scalars["String"]["output"];
};

export type FriendRewardClaimedStepConfig = {
  __typename?: "FriendRewardClaimedStepConfig";
  footerLink: ClickToClaimLink;
};

export type FriendRewardClaimedStepContent = {
  __typename?: "FriendRewardClaimedStepContent";
  body: Scalars["String"]["output"];
  footerLink: LinkContent;
  title: Scalars["String"]["output"];
};

export type FulfilmentExpiredActiveStep = ActiveStepInterface & {
  __typename?: "FulfilmentExpiredActiveStep";
  type: Scalars["String"]["output"];
};

export type FulfilmentExpiredStep = StepInterface & {
  __typename?: "FulfilmentExpiredStep";
  config: FulfilmentExpiredStepConfig;
  content: FulfilmentExpiredStepContent;
  type: Scalars["String"]["output"];
};

export type FulfilmentExpiredStepConfig = {
  __typename?: "FulfilmentExpiredStepConfig";
  footerLink: ClickToClaimLink;
};

export type FulfilmentExpiredStepContent = {
  __typename?: "FulfilmentExpiredStepContent";
  body: Scalars["String"]["output"];
  footerLink: LinkContent;
  title: Scalars["String"]["output"];
};

export type GenericErrorActiveStep = ActiveStepInterface & {
  __typename?: "GenericErrorActiveStep";
  type: Scalars["String"]["output"];
};

export type HoverStyle = {
  __typename?: "HoverStyle";
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type ImageContent = {
  __typename?: "ImageContent";
  altText: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type IneligibleForVoucherActiveStep = ActiveStepInterface & {
  __typename?: "IneligibleForVoucherActiveStep";
  config: IneligibleForVoucherActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type IneligibleForVoucherActiveStepConfig = {
  __typename?: "IneligibleForVoucherActiveStepConfig";
  destinationUrl: Scalars["String"]["output"];
};

export type IneligibleForVoucherStep = StepInterface & {
  __typename?: "IneligibleForVoucherStep";
  config: IneligibleForVoucherStepConfig;
  content: IneligibleForVoucherStepContent;
  type: Scalars["String"]["output"];
};

export type IneligibleForVoucherStepConfig = StepConfigInterface & {
  __typename?: "IneligibleForVoucherStepConfig";
  button: IneligibleForVoucherStepConfigButton;
  layout: Scalars["String"]["output"];
};

export type IneligibleForVoucherStepConfigButton = InputConfigInterface & {
  __typename?: "IneligibleForVoucherStepConfigButton";
  show: Scalars["Boolean"]["output"];
};

export type IneligibleForVoucherStepContent = {
  __typename?: "IneligibleForVoucherStepContent";
  body: Scalars["String"]["output"];
  button: ButtonContent;
  title: Scalars["String"]["output"];
};

export type InputConfigInterface = {
  show: Scalars["Boolean"]["output"];
};

export type InputContentInterface = {
  label: Scalars["String"]["output"];
};

export type InputsContent = {
  __typename?: "InputsContent";
  customFields: Array<CustomFieldInputContent>;
  email: TextInputContent;
  firstName: TextInputContent;
  fullName: TextInputContent;
  lastName: TextInputContent;
  marketingOptIn: CheckboxInputContent;
  termsOptIn: CheckboxInputContent;
};

export type Layout = {
  __typename?: "Layout";
  backgroundColor: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
};

export type LegalStyle = {
  __typename?: "LegalStyle";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontSize: Scalars["String"]["output"];
  lineHeight: Scalars["String"]["output"];
  opacity: Scalars["Float"]["output"];
};

export type Link = {
  __typename?: "Link";
  _active: LinkState;
  _hover: LinkState;
  _visited: LinkState;
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
  textDecoration: Scalars["String"]["output"];
};

export type LinkContent = {
  __typename?: "LinkContent";
  text: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type LinkState = {
  __typename?: "LinkState";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type Localisation = {
  __typename?: "Localisation";
  date: Date;
  sharing: SharingLocalisation;
};

/** Autogenerated input type of LoginViaAuthToken */
export type LoginViaAuthTokenInput = {
  authToken: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of LoginViaAuthToken. */
export type LoginViaAuthTokenPayload = {
  __typename?: "LoginViaAuthTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

/** Autogenerated input type of LoginViaIdentifier */
export type LoginViaIdentifierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  identifier: Scalars["String"]["input"];
};

/** Autogenerated return type of LoginViaIdentifier. */
export type LoginViaIdentifierPayload = {
  __typename?: "LoginViaIdentifierPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

/** Autogenerated input type of LoginViaSeamless */
export type LoginViaSeamlessInput = {
  clientData?: InputMaybe<Scalars["JSON"]["input"]>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  customFieldValue?: InputMaybe<Scalars["String"]["input"]>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  email: Scalars["String"]["input"];
  flowId: Scalars["String"]["input"];
  fullName: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  signature: Scalars["String"]["input"];
  signedKeys: Array<Scalars["String"]["input"]>;
  splashPageSubmit?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of LoginViaSeamless. */
export type LoginViaSeamlessPayload = {
  __typename?: "LoginViaSeamlessPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type LogoutContent = {
  __typename?: "LogoutContent";
  linkText: Scalars["String"]["output"];
  message: Scalars["String"]["output"];
};

/** Autogenerated input type of LogoutReferrer */
export type LogoutReferrerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of LogoutReferrer. */
export type LogoutReferrerPayload = {
  __typename?: "LogoutReferrerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type LookupUserActiveStep = ActiveStepInterface & {
  __typename?: "LookupUserActiveStep";
  config: LookupUserActiveStepConfig;
  type: Scalars["String"]["output"];
  validationErrors: Array<ValidationError>;
};

export type LookupUserActiveStepConfig = {
  __typename?: "LookupUserActiveStepConfig";
  redirectToUrl?: Maybe<Scalars["String"]["output"]>;
  showInvalidCodeMessage: Scalars["Boolean"]["output"];
};

export type LookupUserFormContent = {
  __typename?: "LookupUserFormContent";
  button: ButtonContent;
  inputs: LookupUserInputsContent;
  submitError: Scalars["String"]["output"];
};

export type LookupUserInputsContent = {
  __typename?: "LookupUserInputsContent";
  accessCode: TextInputContent;
};

export type LookupUserStep = StepInterface & {
  __typename?: "LookupUserStep";
  content: LookupUserStepContent;
  type: Scalars["String"]["output"];
};

export type LookupUserStepContent = {
  __typename?: "LookupUserStepContent";
  body: Scalars["String"]["output"];
  form: LookupUserFormContent;
  image: ImageContent;
  title: Scalars["String"]["output"];
};

/** Autogenerated input type of LookupUserViaCode */
export type LookupUserViaCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  code: Scalars["String"]["input"];
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of LookupUserViaCode. */
export type LookupUserViaCodePayload = {
  __typename?: "LookupUserViaCodePayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type ManualSharingContent = {
  __typename?: "ManualSharingContent";
  copyButtonText: Scalars["String"]["output"];
  copySuccessNotification: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ManualSharingTitleStyle = {
  __typename?: "ManualSharingTitleStyle";
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

export type MarketingOptInInputConfig = InputConfigInterface & {
  __typename?: "MarketingOptInInputConfig";
  required: Scalars["Boolean"]["output"];
  show: Scalars["Boolean"]["output"];
};

export type Money = {
  __typename?: "Money";
  displayString: Scalars["String"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  addFriendActionId?: Maybe<AddFriendActionIdPayload>;
  addFriendDetails?: Maybe<AddFriendDetailsPayload>;
  addFriendReferral?: Maybe<AddFriendReferralPayload>;
  claimReward?: Maybe<ClaimRewardPayload>;
  loginViaAuthToken?: Maybe<LoginViaAuthTokenPayload>;
  loginViaIdentifier?: Maybe<LoginViaIdentifierPayload>;
  loginViaSeamless?: Maybe<LoginViaSeamlessPayload>;
  logoutReferrer?: Maybe<LogoutReferrerPayload>;
  lookupUserViaCode?: Maybe<LookupUserViaCodePayload>;
  partiallyRegisterFriend?: Maybe<PartiallyRegisterFriendPayload>;
  partnerLoginViaAuthToken?: Maybe<PartnerLoginViaAuthTokenPayload>;
  registerFriend?: Maybe<RegisterFriendPayload>;
  registerFriendForRedirection?: Maybe<RegisterFriendForRedirectionPayload>;
  registerReferrer?: Maybe<RegisterReferrerPayload>;
  trackEvent?: Maybe<TrackEventPayload>;
};

export type MutationAddFriendActionIdArgs = {
  input: AddFriendActionIdInput;
};

export type MutationAddFriendDetailsArgs = {
  input: AddFriendDetailsInput;
};

export type MutationAddFriendReferralArgs = {
  input: AddFriendReferralInput;
};

export type MutationClaimRewardArgs = {
  input: ClaimRewardInput;
};

export type MutationLoginViaAuthTokenArgs = {
  input: LoginViaAuthTokenInput;
};

export type MutationLoginViaIdentifierArgs = {
  input: LoginViaIdentifierInput;
};

export type MutationLoginViaSeamlessArgs = {
  input: LoginViaSeamlessInput;
};

export type MutationLogoutReferrerArgs = {
  input: LogoutReferrerInput;
};

export type MutationLookupUserViaCodeArgs = {
  input: LookupUserViaCodeInput;
};

export type MutationPartiallyRegisterFriendArgs = {
  input: PartiallyRegisterFriendInput;
};

export type MutationPartnerLoginViaAuthTokenArgs = {
  input: PartnerLoginViaAuthTokenInput;
};

export type MutationRegisterFriendArgs = {
  input: RegisterFriendInput;
};

export type MutationRegisterFriendForRedirectionArgs = {
  input: RegisterFriendForRedirectionInput;
};

export type MutationRegisterReferrerArgs = {
  input: RegisterReferrerInput;
};

export type MutationTrackEventArgs = {
  input: TrackEventInput;
};

export type NativeSharingContent = {
  __typename?: "NativeSharingContent";
  buttonText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type Notification = {
  __typename?: "Notification";
  backgroundColor: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontSize: Scalars["String"]["output"];
  successful: NotificationStyle;
};

export type NotificationStyle = {
  __typename?: "NotificationStyle";
  iconColor: Scalars["String"]["output"];
};

export type OfferDetailsConfig = {
  __typename?: "OfferDetailsConfig";
  revealOnLoad: Scalars["Boolean"]["output"];
  showStepIcons: Scalars["Boolean"]["output"];
};

export type OfferDetailsContent = {
  __typename?: "OfferDetailsContent";
  collapsedTitle: Scalars["String"]["output"];
  expandedTitle: Scalars["String"]["output"];
  footer: Scalars["String"]["output"];
  steps: Array<Scalars["String"]["output"]>;
};

export type PageDisabledActiveStep = ActiveStepInterface & {
  __typename?: "PageDisabledActiveStep";
  type: Scalars["String"]["output"];
};

export type PageDisabledStep = StepInterface & {
  __typename?: "PageDisabledStep";
  content: PageDisabledStepContent;
  type: Scalars["String"]["output"];
};

export type PageDisabledStepContent = {
  __typename?: "PageDisabledStepContent";
  body: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

/** Autogenerated input type of PartiallyRegisterFriend */
export type PartiallyRegisterFriendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of PartiallyRegisterFriend. */
export type PartiallyRegisterFriendPayload = {
  __typename?: "PartiallyRegisterFriendPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type PartnerDashboardActiveStep = ActiveStepInterface & {
  __typename?: "PartnerDashboardActiveStep";
  config: PartnerDashboardActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type PartnerDashboardActiveStepConfig = {
  __typename?: "PartnerDashboardActiveStepConfig";
  performance: PartnerDashboardActiveStepConfigPerformance;
  referralStats: PartnerDashboardActiveStepConfigReferralStats;
  rewards: PartnerDashboardActiveStepConfigRewards;
  sharingUrls: PartnerDashboardActiveStepConfigSharingUrls;
};

export type PartnerDashboardActiveStepConfigPerformance = {
  __typename?: "PartnerDashboardActiveStepConfigPerformance";
  details: Array<PartnerDashboardActiveStepConfigPerformanceDetail>;
  total: PartnerDashboardActiveStepConfigPerformanceTotal;
};

export type PartnerDashboardActiveStepConfigPerformanceDetail = {
  __typename?: "PartnerDashboardActiveStepConfigPerformanceDetail";
  cancelled: Scalars["Int"]["output"];
  confirmed: Scalars["Int"]["output"];
  created: Scalars["Int"]["output"];
  date: Scalars["ISO8601Date"]["output"];
  rewardsConfirmed: Money;
  visits: Scalars["Int"]["output"];
};

export type PartnerDashboardActiveStepConfigPerformanceTotal = {
  __typename?: "PartnerDashboardActiveStepConfigPerformanceTotal";
  cancelled: Scalars["Int"]["output"];
  confirmed: Scalars["Int"]["output"];
  created: Scalars["Int"]["output"];
  pending: Scalars["Int"]["output"];
  rewardsConfirmed: Money;
  visits: Scalars["Int"]["output"];
};

export type PartnerDashboardActiveStepConfigReferralStats = {
  __typename?: "PartnerDashboardActiveStepConfigReferralStats";
  cancelledReferralCount: Scalars["Int"]["output"];
  completeReferralCount: Scalars["Int"]["output"];
  friendRegistrationCount: Scalars["Int"]["output"];
  friendVisitCount: Scalars["Int"]["output"];
  pendingReferralCount: Scalars["Int"]["output"];
};

export type PartnerDashboardActiveStepConfigRewardDetail = {
  __typename?: "PartnerDashboardActiveStepConfigRewardDetail";
  body: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PartnerDashboardActiveStepConfigRewards = {
  __typename?: "PartnerDashboardActiveStepConfigRewards";
  audienceRewards: Array<PartnerDashboardActiveStepConfigRewardDetail>;
  partnerRewards: Array<PartnerDashboardActiveStepConfigRewardDetail>;
};

export type PartnerDashboardActiveStepConfigSharingUrls = {
  __typename?: "PartnerDashboardActiveStepConfigSharingUrls";
  directLink: Scalars["String"]["output"];
  shareCode: Scalars["String"]["output"];
};

export type PartnerDashboardOfferContent = {
  __typename?: "PartnerDashboardOfferContent";
  body: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  image?: Maybe<ImageContent>;
  title: Scalars["String"]["output"];
};

export type PartnerDashboardPerformanceContent = {
  __typename?: "PartnerDashboardPerformanceContent";
  table: PartnerDashboardPerformanceTableContent;
  title: Scalars["String"]["output"];
};

export type PartnerDashboardPerformanceTableContent = {
  __typename?: "PartnerDashboardPerformanceTableContent";
  downloadButtonText: Scalars["String"]["output"];
  headers: PartnerDashboardPerformanceTableHeadersContent;
  tooltips: PartnerDashboardPerformanceTableTooltipsContent;
  total: Scalars["String"]["output"];
};

export type PartnerDashboardPerformanceTableHeadersContent = {
  __typename?: "PartnerDashboardPerformanceTableHeadersContent";
  cancelled: Scalars["String"]["output"];
  confirmed: Scalars["String"]["output"];
  created: Scalars["String"]["output"];
  month: Scalars["String"]["output"];
  rewardsConfirmed: Scalars["String"]["output"];
  visits: Scalars["String"]["output"];
};

export type PartnerDashboardPerformanceTableTooltipsContent = {
  __typename?: "PartnerDashboardPerformanceTableTooltipsContent";
  cancelled: Scalars["String"]["output"];
  confirmed: Scalars["String"]["output"];
  created: Scalars["String"]["output"];
  visits: Scalars["String"]["output"];
};

export type PartnerDashboardReferralStatsContent = {
  __typename?: "PartnerDashboardReferralStatsContent";
  cancelledReferralCount: Scalars["String"]["output"];
  completeReferralCount: Scalars["String"]["output"];
  friendRegistrationCount: Scalars["String"]["output"];
  friendVisitCount: Scalars["String"]["output"];
  pendingReferralCount: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PartnerDashboardRulesContent = {
  __typename?: "PartnerDashboardRulesContent";
  body: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PartnerDashboardSharingToolsContent = {
  __typename?: "PartnerDashboardSharingToolsContent";
  directLink: PartnerDashboardSharingToolsFormContent;
  shareCode: PartnerDashboardSharingToolsFormContent;
  title: Scalars["String"]["output"];
};

export type PartnerDashboardSharingToolsFormContent = {
  __typename?: "PartnerDashboardSharingToolsFormContent";
  copyButtonText: Scalars["String"]["output"];
  copySuccessNotification: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  nativeSharing: PartnerDashboardSharingToolsNativeSharingContent;
  shareMessage: Scalars["String"]["output"];
};

export type PartnerDashboardSharingToolsNativeSharingContent = {
  __typename?: "PartnerDashboardSharingToolsNativeSharingContent";
  buttonText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type PartnerDashboardStep = StepInterface & {
  __typename?: "PartnerDashboardStep";
  config: PartnerDashboardStepConfig;
  content: PartnerDashboardStepContent;
  type: Scalars["String"]["output"];
};

export type PartnerDashboardStepConfig = StepConfigInterface & {
  __typename?: "PartnerDashboardStepConfig";
  audienceOffer: PartnerDashboardStepConfigOffer;
  layout: Scalars["String"]["output"];
  partnerOffer: PartnerDashboardStepConfigOffer;
  referralStats: Array<Scalars["String"]["output"]>;
  sharingTools: PartnerDashboardStepConfigSharingTools;
  showReferralStats: Scalars["Boolean"]["output"];
};

export type PartnerDashboardStepConfigOffer = {
  __typename?: "PartnerDashboardStepConfigOffer";
  displayRewards: Scalars["Boolean"]["output"];
};

export type PartnerDashboardStepConfigSharingTools = {
  __typename?: "PartnerDashboardStepConfigSharingTools";
  displayDirectLink: Scalars["Boolean"]["output"];
  displayShareCode: Scalars["Boolean"]["output"];
};

export type PartnerDashboardStepContent = {
  __typename?: "PartnerDashboardStepContent";
  audienceOffer: PartnerDashboardOfferContent;
  partnerOffer: PartnerDashboardOfferContent;
  performance: PartnerDashboardPerformanceContent;
  referralStats: PartnerDashboardReferralStatsContent;
  rules: PartnerDashboardRulesContent;
  sharingTools: PartnerDashboardSharingToolsContent;
  title: Scalars["String"]["output"];
  usefulLinks: PartnerDashboardUsefulLinksContent;
};

export type PartnerDashboardUsefulLinksContent = {
  __typename?: "PartnerDashboardUsefulLinksContent";
  buttons: Array<LinkContent>;
  title: Scalars["String"]["output"];
};

export type PartnerDetails = {
  __typename?: "PartnerDetails";
  avatarUrl: Scalars["String"]["output"];
  bannerUrl: Scalars["String"]["output"];
  businessName: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  offerHeader: Scalars["String"]["output"];
  quote: Scalars["String"]["output"];
};

/** Autogenerated input type of PartnerLoginViaAuthToken */
export type PartnerLoginViaAuthTokenInput = {
  authToken: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of PartnerLoginViaAuthToken. */
export type PartnerLoginViaAuthTokenPayload = {
  __typename?: "PartnerLoginViaAuthTokenPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type PartnerPageDisabledActiveStep = ActiveStepInterface & {
  __typename?: "PartnerPageDisabledActiveStep";
  type: Scalars["String"]["output"];
};

export type PartnerPageDisabledStep = StepInterface & {
  __typename?: "PartnerPageDisabledStep";
  content: PageDisabledStepContent;
  type: Scalars["String"]["output"];
};

export type PillStyle = {
  __typename?: "PillStyle";
  backgroundColor: Scalars["String"]["output"];
  borderRadius: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  /** Get the published campaign configuration */
  config: Config;
  /** Get session data */
  session: Session;
};

export type QueryConfigArgs = {
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  flow: Scalars["String"]["input"];
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuerySessionArgs = {
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
};

export type RedeemRewardActiveStep = ActiveStepInterface & {
  __typename?: "RedeemRewardActiveStep";
  config: RedeemRewardActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type RedeemRewardActiveStepConfig = {
  __typename?: "RedeemRewardActiveStepConfig";
  destinationUrl: Scalars["String"]["output"];
};

export type RedeemRewardStep = StepInterface & {
  __typename?: "RedeemRewardStep";
  type: Scalars["String"]["output"];
};

export type ReferralLimitReachedActiveStep = ActiveStepInterface & {
  __typename?: "ReferralLimitReachedActiveStep";
  config: ReferralLimitReachedActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type ReferralLimitReachedActiveStepConfig = {
  __typename?: "ReferralLimitReachedActiveStepConfig";
  destinationUrl: Scalars["String"]["output"];
};

export type ReferralLimitReachedStep = StepInterface & {
  __typename?: "ReferralLimitReachedStep";
  optionalConfig?: Maybe<ReferralLimitReachedStepConfig>;
  optionalContent?: Maybe<ReferralLimitReachedStepContent>;
  type: Scalars["String"]["output"];
};

export type ReferralLimitReachedStepConfig = StepConfigInterface & {
  __typename?: "ReferralLimitReachedStepConfig";
  button: ReferralLimitReachedStepConfigButton;
  layout: Scalars["String"]["output"];
};

export type ReferralLimitReachedStepConfigButton = InputConfigInterface & {
  __typename?: "ReferralLimitReachedStepConfigButton";
  show: Scalars["Boolean"]["output"];
};

export type ReferralLimitReachedStepContent = {
  __typename?: "ReferralLimitReachedStepContent";
  body: Scalars["String"]["output"];
  button: ButtonContent;
  title: Scalars["String"]["output"];
};

export type ReferralStatusContent = {
  __typename?: "ReferralStatusContent";
  pill: ReferralStatusPillContent;
  stats: ReferralStatusStatsContent;
  table: ReferralStatusTableContent;
};

export type ReferralStatusPillContent = {
  __typename?: "ReferralStatusPillContent";
  collapsed: Scalars["String"]["output"];
  expanded: Scalars["String"]["output"];
};

export type ReferralStatusStatsContent = {
  __typename?: "ReferralStatusStatsContent";
  cancelledReferralCount: Scalars["String"]["output"];
  completeReferralCount: Scalars["String"]["output"];
  friendRegistrationCount: Scalars["String"]["output"];
  friendVisitCount: Scalars["String"]["output"];
  pendingReferralCount: Scalars["String"]["output"];
};

export type ReferralStatusTableContent = {
  __typename?: "ReferralStatusTableContent";
  complete: ReferralStatusTableSectionContent;
  headers: ReferralStatusTableHeadersContent;
  pending: ReferralStatusTableSectionContent;
  statuses: ReferralStatusTableStatusesContent;
};

export type ReferralStatusTableHeadersContent = {
  __typename?: "ReferralStatusTableHeadersContent";
  date: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export type ReferralStatusTableSectionContent = {
  __typename?: "ReferralStatusTableSectionContent";
  title: Scalars["String"]["output"];
  tooltip: Scalars["String"]["output"];
};

export type ReferralStatusTableStatusesContent = {
  __typename?: "ReferralStatusTableStatusesContent";
  complete: Scalars["String"]["output"];
  pending: Scalars["String"]["output"];
};

export type ReferrerDashboardActiveStep = ActiveStepInterface & {
  __typename?: "ReferrerDashboardActiveStep";
  config: ReferrerDashboardActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type ReferrerDashboardActiveStepConfig = {
  __typename?: "ReferrerDashboardActiveStepConfig";
  canLogout: Scalars["Boolean"]["output"];
  emailSharingShortUrl: Scalars["String"]["output"];
  manualSharingShortUrl: Scalars["String"]["output"];
  nativeSharingShortUrl: Scalars["String"]["output"];
  referralStatus: ReferrerDashboardActiveStepConfigReferralStatus;
  rewardCappingType: Scalars["String"]["output"];
  shareNetworkShortUrls: ReferrerDashboardActiveStepConfigShareNetworkShortUrls;
  showRewardLimitWarningMessage: Scalars["Boolean"]["output"];
};

export type ReferrerDashboardActiveStepConfigReferralStatus = {
  __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
  cancelledReferralCount: Scalars["Int"]["output"];
  completeReferralCount: Scalars["Int"]["output"];
  completeReferrals: Array<ReferrerDashboardActiveStepConfigReferralStatusReferral>;
  friendRegistrationCount: Scalars["Int"]["output"];
  friendVisitCount: Scalars["Int"]["output"];
  pendingReferralCount: Scalars["Int"]["output"];
  pendingReferrals: Array<ReferrerDashboardActiveStepConfigReferralStatusReferral>;
};

export type ReferrerDashboardActiveStepConfigReferralStatusReferral = {
  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
  date: Scalars["ISO8601DateTime"]["output"];
  name: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

export type ReferrerDashboardActiveStepConfigShareNetworkShortUrls = {
  __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
  facebook: Scalars["String"]["output"];
  facebookMessenger: Scalars["String"]["output"];
  line: Scalars["String"]["output"];
  pinterest: Scalars["String"]["output"];
  reddit: Scalars["String"]["output"];
  telegram: Scalars["String"]["output"];
  twitter: Scalars["String"]["output"];
  viber: Scalars["String"]["output"];
  vkontakte: Scalars["String"]["output"];
  whatsapp: Scalars["String"]["output"];
  zalo: Scalars["String"]["output"];
};

export type ReferrerDashboardShareCodeContent = {
  __typename?: "ReferrerDashboardShareCodeContent";
  copyButtonText: Scalars["String"]["output"];
  copySuccessNotification: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  nativeSharing: NativeSharingContent;
  shareMessage: Scalars["String"]["output"];
};

export type ReferrerDashboardStep = StepInterface & {
  __typename?: "ReferrerDashboardStep";
  config: ReferrerDashboardStepConfig;
  content: ReferrerDashboardStepContent;
  style?: Maybe<ReferrerDashboardStepStyle>;
  type: Scalars["String"]["output"];
};

export type ReferrerDashboardStepConfig = StepConfigInterface & {
  __typename?: "ReferrerDashboardStepConfig";
  layout: Scalars["String"]["output"];
  offerDetails: OfferDetailsConfig;
  referralStats: Array<Scalars["String"]["output"]>;
  shareNetworks: Array<Scalars["String"]["output"]>;
  showDirectLink: Scalars["Boolean"]["output"];
  showPendingReferrals: Scalars["Boolean"]["output"];
  showReferralStats: Scalars["Boolean"]["output"];
  showReferralStatuses: Scalars["Boolean"]["output"];
  showShareCode: Scalars["Boolean"]["output"];
};

export type ReferrerDashboardStepContent = {
  __typename?: "ReferrerDashboardStepContent";
  finePrint: Scalars["String"]["output"];
  image?: Maybe<ResponsiveImage>;
  logout: LogoutContent;
  manualSharing: ManualSharingContent;
  nativeSharing: NativeSharingContent;
  offerDetails: OfferDetailsContent;
  referralStatus?: Maybe<ReferralStatusContent>;
  rewardCapping: RewardCappingContent;
  shareCode: ReferrerDashboardShareCodeContent;
  shareMessage: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type ReferrerDashboardStepStyle = {
  __typename?: "ReferrerDashboardStepStyle";
  shareButtons?: Maybe<ShareButtonsStyle>;
};

/** Autogenerated input type of RegisterFriendForRedirection */
export type RegisterFriendForRedirectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of RegisterFriendForRedirection. */
export type RegisterFriendForRedirectionPayload = {
  __typename?: "RegisterFriendForRedirectionPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

/** Autogenerated input type of RegisterFriend */
export type RegisterFriendInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  email: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Autogenerated return type of RegisterFriend. */
export type RegisterFriendPayload = {
  __typename?: "RegisterFriendPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

/** Autogenerated input type of RegisterReferrer */
export type RegisterReferrerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  customFields?: InputMaybe<Array<CustomFieldInput>>;
  email: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  flowId: Scalars["String"]["input"];
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  utmParams?: InputMaybe<UtmParamsInput>;
};

/** Autogenerated return type of RegisterReferrer. */
export type RegisterReferrerPayload = {
  __typename?: "RegisterReferrerPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
  session: Session;
};

export type ResponsiveImage = {
  __typename?: "ResponsiveImage";
  altText: Scalars["String"]["output"];
  mobile?: Maybe<ImageContent>;
  url: Scalars["String"]["output"];
};

export type RewardCappingContent = {
  __typename?: "RewardCappingContent";
  monetaryWarningMessage: Scalars["String"]["output"];
  volumeWarningMessage: Scalars["String"]["output"];
  warningTitle: Scalars["String"]["output"];
};

export type RewardEmailedActiveStep = ActiveStepInterface & {
  __typename?: "RewardEmailedActiveStep";
  type: Scalars["String"]["output"];
};

export type RewardEmailedReturningActiveStep = ActiveStepInterface & {
  __typename?: "RewardEmailedReturningActiveStep";
  type: Scalars["String"]["output"];
};

export type RewardEmailedReturningStep = StepInterface & {
  __typename?: "RewardEmailedReturningStep";
  content: RewardEmailedReturningStepContent;
  type: Scalars["String"]["output"];
};

export type RewardEmailedReturningStepContent = {
  __typename?: "RewardEmailedReturningStepContent";
  body: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type RewardEmailedStep = StepInterface & {
  __typename?: "RewardEmailedStep";
  config: RewardEmailedStepConfig;
  content: RewardEmailedStepContent;
  type: Scalars["String"]["output"];
};

export type RewardEmailedStepConfig = {
  __typename?: "RewardEmailedStepConfig";
  footerLink: ClickToClaimLink;
};

export type RewardEmailedStepContent = {
  __typename?: "RewardEmailedStepContent";
  body: Scalars["String"]["output"];
  footerLink: LinkContent;
  title: Scalars["String"]["output"];
};

export type RewardOption = {
  __typename?: "RewardOption";
  body: Scalars["String"]["output"];
  containerBackgroundColor: Scalars["String"]["output"];
  image?: Maybe<ImageContent>;
  key: Scalars["ID"]["output"];
  position: Scalars["Int"]["output"];
  selectButtonText: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SeamlessLoginFailureActiveStep = ActiveStepInterface & {
  __typename?: "SeamlessLoginFailureActiveStep";
  type: Scalars["String"]["output"];
};

export type SeamlessLoginFailureStep = StepInterface & {
  __typename?: "SeamlessLoginFailureStep";
  content: SeamlessLoginFailureStepContent;
  type: Scalars["String"]["output"];
};

export type SeamlessLoginFailureStepContent = {
  __typename?: "SeamlessLoginFailureStepContent";
  body: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
};

export type SelectFriendRewardOptionActiveStep = ActiveStepInterface & {
  __typename?: "SelectFriendRewardOptionActiveStep";
  config: SelectFriendRewardOptionActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type SelectFriendRewardOptionActiveStepConfig = {
  __typename?: "SelectFriendRewardOptionActiveStepConfig";
  allowedRewardOptions: Array<Scalars["String"]["output"]>;
  rewardTypeId: Scalars["ID"]["output"];
};

export type SelectFriendRewardOptionStep = StepInterface & {
  __typename?: "SelectFriendRewardOptionStep";
  config: SelectFriendRewardOptionStepConfig;
  content: SelectFriendRewardOptionStepContent;
  type: Scalars["String"]["output"];
};

export type SelectFriendRewardOptionStepConfig = {
  __typename?: "SelectFriendRewardOptionStepConfig";
  introLink: ClickToClaimLink;
  rewardTypes: Array<ClickToClaimFriendRewardType>;
};

export type SelectFriendRewardOptionStepContent = {
  __typename?: "SelectFriendRewardOptionStepContent";
  body: Scalars["String"]["output"];
  claimRewardError: Scalars["String"]["output"];
  expiryInfo: Scalars["String"]["output"];
  introLink: LinkContent;
  title: Scalars["String"]["output"];
};

export type Session = SessionInterface & {
  __typename?: "Session";
  activeStep: ActiveStepInterface;
  contentReplacements: Scalars["JSON"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
};

export type SessionInterface = {
  activeStep: ActiveStepInterface;
  contentReplacements: Scalars["JSON"]["output"];
};

export type ShareAreaHoverStyle = {
  __typename?: "ShareAreaHoverStyle";
  backgroundColor: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type ShareAreaStyle = {
  __typename?: "ShareAreaStyle";
  _hover: ShareAreaHoverStyle;
  backgroundColor: Scalars["String"]["output"];
  borderRadius: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
  height: Scalars["String"]["output"];
  manualSharingTitle: ManualSharingTitleStyle;
};

export type ShareButtonsIconStyle = {
  __typename?: "ShareButtonsIconStyle";
  _hover?: Maybe<HoverStyle>;
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type ShareButtonsStyle = {
  __typename?: "ShareButtonsStyle";
  _hover?: Maybe<HoverStyle>;
  css?: Maybe<Scalars["JSON"]["output"]>;
  icon?: Maybe<ShareButtonsIconStyle>;
};

export type SharingEmailLocalisation = {
  __typename?: "SharingEmailLocalisation";
  emailClientLabelText: Scalars["String"]["output"];
  messageLabelText: Scalars["String"]["output"];
};

export type SharingLocalisation = {
  __typename?: "SharingLocalisation";
  email: SharingEmailLocalisation;
  tabs: SharingTabsLocalisation;
};

export type SharingTabsLocalisation = {
  __typename?: "SharingTabsLocalisation";
  emailButtonText: Scalars["String"]["output"];
  socialButtonText: Scalars["String"]["output"];
};

export type SplashPageActiveStep = ActiveStepInterface & {
  __typename?: "SplashPageActiveStep";
  config: SplashPageActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type SplashPageActiveStepConfig = {
  __typename?: "SplashPageActiveStepConfig";
  showEligibilityCheckRejectionMessage: Scalars["Boolean"]["output"];
};

export type SplashPageStep = StepInterface & {
  __typename?: "SplashPageStep";
  optionalConfig?: Maybe<SplashPageStepConfig>;
  optionalContent?: Maybe<SplashPageStepContent>;
  type: Scalars["String"]["output"];
};

export type SplashPageStepConfig = StepConfigInterface & {
  __typename?: "SplashPageStepConfig";
  inputs: SplashPageStepConfigInputs;
  layout: Scalars["String"]["output"];
  offerDetails: OfferDetailsConfig;
};

export type SplashPageStepConfigInputs = {
  __typename?: "SplashPageStepConfigInputs";
  marketingOptIn: MarketingOptInInputConfig;
  termsOptIn: TermsOptInInputConfig;
};

export type SplashPageStepContent = {
  __typename?: "SplashPageStepContent";
  additionalFinePrint?: Maybe<Scalars["String"]["output"]>;
  eligibilityCheckRejectionMessage: Scalars["String"]["output"];
  form: FormContent;
  image?: Maybe<ResponsiveImage>;
  offerDetails: OfferDetailsContent;
  title: Scalars["String"]["output"];
};

export type StepConfigInterface = {
  layout: Scalars["String"]["output"];
};

/** All steps have a type */
export type StepInterface = {
  type: Scalars["String"]["output"];
};

export type StrongStyle = {
  __typename?: "StrongStyle";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

export type Styles = {
  __typename?: "Styles";
  avatar: AvatarStyle;
  button: Button;
  card: Card;
  colors: Colors;
  emphasis: EmphasisStyle;
  errorBanner: BannerStyle;
  font: Font;
  form: Form;
  layout: Layout;
  legal: LegalStyle;
  link: Link;
  notification: Notification;
  pill: PillStyle;
  shareArea: ShareAreaStyle;
  strong: StrongStyle;
  switch: Switch;
  tabSwitcher: TabSwitcherStyle;
  textInput: TextInput;
  title: Title;
  warningBanner: BannerStyle;
};

export type SuccessfulFriendRegistrationActiveStep = ActiveStepInterface & {
  __typename?: "SuccessfulFriendRegistrationActiveStep";
  config: SuccessfulFriendRegistrationActiveStepConfig;
  type: Scalars["String"]["output"];
};

export type SuccessfulFriendRegistrationActiveStepConfig = {
  __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
  buttons: Array<ButtonContentInterface>;
  showSecondaryButton: Scalars["Boolean"]["output"];
};

export type SuccessfulFriendRegistrationStep = StepInterface & {
  __typename?: "SuccessfulFriendRegistrationStep";
  optionalConfig?: Maybe<SuccessfulFriendRegistrationStepConfig>;
  optionalContent?: Maybe<SuccessfulFriendRegistrationStepContent>;
  type: Scalars["String"]["output"];
};

export type SuccessfulFriendRegistrationStepConfig = StepConfigInterface & {
  __typename?: "SuccessfulFriendRegistrationStepConfig";
  button: SuccessfulFriendRegistrationStepConfigButton;
  layout: Scalars["String"]["output"];
};

export type SuccessfulFriendRegistrationStepConfigButton = InputConfigInterface & {
  __typename?: "SuccessfulFriendRegistrationStepConfigButton";
  show: Scalars["Boolean"]["output"];
};

export type SuccessfulFriendRegistrationStepContent = {
  __typename?: "SuccessfulFriendRegistrationStepContent";
  body: Scalars["String"]["output"];
  secondaryButton: ButtonContent;
  title: Scalars["String"]["output"];
};

export type Switch = {
  __typename?: "Switch";
  _active: SwitchState;
  backgroundColor: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  error: ErrorStyle;
  label: SwitchLabel;
};

export type SwitchLabel = {
  __typename?: "SwitchLabel";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

export type SwitchState = {
  __typename?: "SwitchState";
  backgroundColor: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type TabStyle = {
  __typename?: "TabStyle";
  _active: TabSwitcherActiveStyle;
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type TabSwitcherActiveStyle = {
  __typename?: "TabSwitcherActiveStyle";
  backgroundColor: Scalars["String"]["output"];
  borderRadius: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type TabSwitcherStyle = {
  __typename?: "TabSwitcherStyle";
  backgroundColor: Scalars["String"]["output"];
  borderRadius: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
  tab: TabStyle;
};

export type TermsOptInInputConfig = InputConfigInterface & {
  __typename?: "TermsOptInInputConfig";
  show: Scalars["Boolean"]["output"];
};

export type TextInput = {
  __typename?: "TextInput";
  _focus: TextInputState;
  backgroundColor: Scalars["String"]["output"];
  borderRadius: Scalars["String"]["output"];
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  error: ErrorStyle;
  fontSize: Scalars["String"]["output"];
  height: Scalars["String"]["output"];
  label: TextInputLabel;
};

export type TextInputConfig = InputConfigInterface & {
  __typename?: "TextInputConfig";
  pattern: Scalars["String"]["output"];
  show: Scalars["Boolean"]["output"];
};

export type TextInputContent = InputContentInterface & {
  __typename?: "TextInputContent";
  items?: Maybe<Array<Scalars["String"]["output"]>>;
  label: Scalars["String"]["output"];
  placeholder: Scalars["String"]["output"];
  tooltip?: Maybe<Scalars["String"]["output"]>;
  validationErrors: Array<ValidationErrorContent>;
};

export type TextInputLabel = {
  __typename?: "TextInputLabel";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

export type TextInputState = {
  __typename?: "TextInputState";
  borderColor: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
};

export type TileButtonContent = ButtonContentInterface & {
  __typename?: "TileButtonContent";
  actionId: Scalars["String"]["output"];
  buttonClass?: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  heading: Scalars["String"]["output"];
  image: ImageContent;
  text: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type Title = {
  __typename?: "Title";
  color: Scalars["String"]["output"];
  css?: Maybe<Scalars["JSON"]["output"]>;
  fontFamily: Scalars["String"]["output"];
  fontSize: Scalars["String"]["output"];
  fontWeight: Scalars["String"]["output"];
};

/** Autogenerated input type of TrackEvent */
export type TrackEventInput = {
  campaignSlug: Scalars["String"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  event: EventEnum;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
  id: Scalars["ID"]["input"];
  params: Scalars["JSON"]["input"];
  utmParams?: InputMaybe<UtmParamsInput>;
};

/** Autogenerated return type of TrackEvent. */
export type TrackEventPayload = {
  __typename?: "TrackEventPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  errors: Array<Scalars["String"]["output"]>;
  ok: Scalars["Boolean"]["output"];
};

export type Urls = {
  __typename?: "Urls";
  faqsUrl: Scalars["String"]["output"];
  privacyPolicyUrl: Scalars["String"]["output"];
  termsAndConditionsUrl: Scalars["String"]["output"];
  userLookupUrl: Scalars["String"]["output"];
};

export type UtmParamsInput = {
  utmCampaign?: InputMaybe<Scalars["String"]["input"]>;
  utmContent?: InputMaybe<Scalars["String"]["input"]>;
  utmMedium?: InputMaybe<Scalars["String"]["input"]>;
  utmSource?: InputMaybe<Scalars["String"]["input"]>;
  utmTerm?: InputMaybe<Scalars["String"]["input"]>;
};

export type ValidationError = {
  __typename?: "ValidationError";
  field: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
};

export type ValidationErrorContent = {
  __typename?: "ValidationErrorContent";
  key: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type CustomFontsFragmentFragment = {
  __typename?: "CustomFont";
  family: string;
  url: string;
};

export type ClickToClaimFriendConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  localisation: {
    __typename?: "Localisation";
    date: {
      __typename?: "Date";
      dayNames: Array<string>;
      abbreviatedDayNames: Array<string>;
      monthNames: Array<string>;
      abbreviatedMonthNames: Array<string>;
      shortFormat: string;
      longFormat: string;
      monthOnlyFormat: string;
    };
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
    | { __typename?: "CaptureFriendDetailsStep"; type: string }
    | { __typename?: "CaptureReferrerDetailsStep"; type: string }
    | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
    | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
    | { __typename?: "ClickToRedirectFriendStep"; type: string }
    | {
        __typename?: "ConfirmFriendRewardOptionStep";
        type: string;
        config: {
          __typename?: "ConfirmFriendRewardOptionStepConfig";
          footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
        };
        content: {
          __typename?: "ConfirmFriendRewardOptionStepContent";
          title: string;
          confirmButton: string;
          cancelButton: string;
          claimRewardError: string;
          footerLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | {
        __typename?: "FriendRewardAlreadyClaimedStep";
        type: string;
        content: {
          __typename?: "FriendRewardAlreadyClaimedStepContent";
          title: string;
          body: string;
        };
      }
    | {
        __typename?: "FriendRewardClaimedStep";
        type: string;
        config: {
          __typename?: "FriendRewardClaimedStepConfig";
          footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
        };
        content: {
          __typename?: "FriendRewardClaimedStepContent";
          title: string;
          body: string;
          footerLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | {
        __typename?: "FulfilmentExpiredStep";
        type: string;
        config: {
          __typename?: "FulfilmentExpiredStepConfig";
          footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
        };
        content: {
          __typename?: "FulfilmentExpiredStepContent";
          title: string;
          body: string;
          footerLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | { __typename?: "IneligibleForVoucherStep"; type: string }
    | { __typename?: "LookupUserStep"; type: string }
    | { __typename?: "PageDisabledStep"; type: string }
    | { __typename?: "PartnerDashboardStep"; type: string }
    | { __typename?: "PartnerPageDisabledStep"; type: string }
    | { __typename?: "RedeemRewardStep"; type: string }
    | { __typename?: "ReferralLimitReachedStep"; type: string }
    | { __typename?: "ReferrerDashboardStep"; type: string }
    | { __typename?: "RewardEmailedReturningStep"; type: string }
    | { __typename?: "RewardEmailedStep"; type: string }
    | { __typename?: "SeamlessLoginFailureStep"; type: string }
    | {
        __typename?: "SelectFriendRewardOptionStep";
        type: string;
        config: {
          __typename?: "SelectFriendRewardOptionStepConfig";
          introLink: { __typename?: "ClickToClaimLink"; show: boolean };
          rewardTypes: Array<{
            __typename?: "ClickToClaimFriendRewardType";
            id: string;
            rewardOptions: Array<{
              __typename?: "RewardOption";
              key: string;
              position: number;
              title: string;
              body: string;
              containerBackgroundColor: string;
              selectButtonText: string;
              image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
            }>;
          }>;
        };
        content: {
          __typename?: "SelectFriendRewardOptionStepContent";
          title: string;
          body: string;
          claimRewardError: string;
          expiryInfo: string;
          introLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | { __typename?: "SplashPageStep"; type: string }
    | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
  >;
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
};

export type ClickToClaimReferrerConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  localisation: {
    __typename?: "Localisation";
    date: {
      __typename?: "Date";
      dayNames: Array<string>;
      abbreviatedDayNames: Array<string>;
      monthNames: Array<string>;
      abbreviatedMonthNames: Array<string>;
      shortFormat: string;
      longFormat: string;
      monthOnlyFormat: string;
    };
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
    | { __typename?: "CaptureFriendDetailsStep"; type: string }
    | { __typename?: "CaptureReferrerDetailsStep"; type: string }
    | {
        __typename?: "ClickToClaimOptionConfirmationStep";
        type: string;
        config: {
          __typename?: "ClickToClaimOptionConfirmationStepConfig";
          footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
        };
        content: {
          __typename?: "ClickToClaimOptionConfirmationStepContent";
          title: string;
          confirmButton: string;
          cancelButton: string;
          claimRewardError: string;
          footerLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | {
        __typename?: "ClickToClaimOptionsSelectorStep";
        type: string;
        config: {
          __typename?: "ClickToClaimOptionsSelectorStepConfig";
          introLink: { __typename?: "ClickToClaimLink"; show: boolean };
          rewardTypes: Array<{
            __typename?: "ClickToClaimRewardType";
            id: string;
            rewardOptions: Array<{
              __typename?: "RewardOption";
              key: string;
              position: number;
              title: string;
              body: string;
              containerBackgroundColor: string;
              selectButtonText: string;
              image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
            }>;
          }>;
        };
        content: {
          __typename?: "ClickToClaimOptionsSelectorStepContent";
          title: string;
          body: string;
          claimRewardError: string;
          expiryInfo: string;
          introLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | { __typename?: "ClickToRedirectFriendStep"; type: string }
    | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
    | { __typename?: "FriendRewardClaimedStep"; type: string }
    | {
        __typename?: "FulfilmentExpiredStep";
        type: string;
        config: {
          __typename?: "FulfilmentExpiredStepConfig";
          footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
        };
        content: {
          __typename?: "FulfilmentExpiredStepContent";
          title: string;
          body: string;
          footerLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | { __typename?: "IneligibleForVoucherStep"; type: string }
    | { __typename?: "LookupUserStep"; type: string }
    | { __typename?: "PageDisabledStep"; type: string }
    | { __typename?: "PartnerDashboardStep"; type: string }
    | { __typename?: "PartnerPageDisabledStep"; type: string }
    | { __typename?: "RedeemRewardStep"; type: string }
    | { __typename?: "ReferralLimitReachedStep"; type: string }
    | { __typename?: "ReferrerDashboardStep"; type: string }
    | {
        __typename?: "RewardEmailedReturningStep";
        type: string;
        content: { __typename?: "RewardEmailedReturningStepContent"; title: string; body: string };
      }
    | {
        __typename?: "RewardEmailedStep";
        type: string;
        config: {
          __typename?: "RewardEmailedStepConfig";
          footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
        };
        content: {
          __typename?: "RewardEmailedStepContent";
          title: string;
          body: string;
          footerLink: { __typename?: "LinkContent"; text: string; url: string };
        };
      }
    | { __typename?: "SeamlessLoginFailureStep"; type: string }
    | { __typename?: "SelectFriendRewardOptionStep"; type: string }
    | { __typename?: "SplashPageStep"; type: string }
    | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
  >;
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
};

export type CaptureAdditionalFriendDetailsActiveStepFragmentFragment = {
  __typename?: "CaptureAdditionalFriendDetailsActiveStep";
  config: { __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig"; firstVisit: boolean };
  validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
};

export type CaptureFriendDetailsActiveStepFragmentFragment = {
  __typename?: "CaptureFriendDetailsActiveStep";
  validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
};

export type FriendJourneyConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  programType: string;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | {
        __typename?: "CaptureAdditionalFriendDetailsStep";
        type: string;
        optionalContent?: {
          __typename?: "CaptureAdditionalFriendDetailsStepContent";
          finePrint?: string | null;
          nextVisit: {
            __typename?: "CaptureAdditionalFriendDetailsNextVisitContent";
            instructions?: string | null;
            showButtons: boolean;
            card: {
              __typename?: "CaptureAdditionalFriendDetailsCardContent";
              title: string;
              body?: string | null;
              finePrint?: string | null;
            };
          };
          firstVisit: {
            __typename?: "CaptureAdditionalFriendDetailsFirstVisitContent";
            instructions: string;
            card: {
              __typename?: "CaptureAdditionalFriendDetailsCardContent";
              title: string;
              body?: string | null;
              finePrint?: string | null;
            };
          };
          form: {
            __typename?: "CaptureAdditionalFriendDetailsFormContent";
            submitError: string;
            inputs: {
              __typename?: "CaptureAdditionalFriendDetailsInputsContent";
              customFields: Array<{
                __typename?: "CustomFieldInputContent";
                key: string;
                content: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  items?: Array<string> | null;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
              }>;
            };
            button: { __typename?: "ButtonContent"; text: string };
          };
          buttons: Array<
            | {
                __typename?: "BasicButtonContent";
                buttonClass?: string | null;
                text: string;
                url: string;
              }
            | {
                __typename?: "TileButtonContent";
                heading: string;
                description: string;
                actionId: string;
                buttonClass?: string | null;
                text: string;
                url: string;
                image: { __typename?: "ImageContent"; altText: string; url: string };
              }
          >;
        } | null;
        optionalConfig?: {
          __typename?: "CaptureAdditionalFriendDetailsStepConfig";
          inputs: {
            __typename?: "CaptureAdditionalFriendDetailsStepConfigInputs";
            customFields: Array<
              | {
                  __typename?: "CustomFieldInputConfig";
                  pattern: string;
                  key: string;
                  position: number;
                  inputFieldType: string;
                }
              | {
                  __typename?: "CustomFieldListInputConfig";
                  items: Array<string>;
                  key: string;
                  position: number;
                  inputFieldType: string;
                }
            >;
          };
        } | null;
      }
    | {
        __typename?: "CaptureFriendDetailsStep";
        type: string;
        content: {
          __typename?: "CaptureFriendDetailsStepContent";
          title: string;
          body: string;
          additionalFinePrint?: string | null;
          image: {
            __typename?: "ResponsiveImage";
            url: string;
            altText: string;
            mobile?: { __typename?: "ImageContent"; url: string; altText: string } | null;
          };
          form: {
            __typename?: "FormContent";
            caption: string;
            submitError: string;
            finePrint: string;
            button: { __typename?: "ButtonContent"; text: string };
            inputs: {
              __typename?: "InputsContent";
              fullName: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              firstName: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              lastName: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              email: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              marketingOptIn: {
                __typename?: "CheckboxInputContent";
                label: string;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              termsOptIn: {
                __typename?: "CheckboxInputContent";
                label: string;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              customFields: Array<{
                __typename?: "CustomFieldInputContent";
                key: string;
                content: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  items?: Array<string> | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
              }>;
            };
          };
          partnerDetails?: {
            __typename?: "PartnerDetails";
            avatarUrl: string;
            bannerUrl: string;
            businessName: string;
            displayName: string;
            offerHeader: string;
            quote: string;
          } | null;
        };
        config: {
          __typename?: "CaptureFriendDetailsStepConfig";
          partialRegistration: boolean;
          displayPartnerRewards: boolean;
          inputs: {
            __typename?: "CaptureFriendDetailsStepConfigInputs";
            fullName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            firstName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            lastName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            email: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            marketingOptIn: {
              __typename?: "MarketingOptInInputConfig";
              show: boolean;
              required: boolean;
            };
            termsOptIn: { __typename?: "TermsOptInInputConfig"; show: boolean };
            customFields: Array<
              | {
                  __typename?: "CustomFieldInputConfig";
                  pattern: string;
                  key: string;
                  position: number;
                  inputFieldType: string;
                }
              | {
                  __typename?: "CustomFieldListInputConfig";
                  items: Array<string>;
                  key: string;
                  position: number;
                  inputFieldType: string;
                }
            >;
          };
          button: { __typename?: "CaptureFriendDetailsStepConfigButton"; show: boolean };
        };
      }
    | { __typename?: "CaptureReferrerDetailsStep"; type: string }
    | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
    | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
    | { __typename?: "ClickToRedirectFriendStep"; type: string }
    | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
    | { __typename?: "FriendRewardClaimedStep"; type: string }
    | { __typename?: "FulfilmentExpiredStep"; type: string }
    | {
        __typename?: "IneligibleForVoucherStep";
        type: string;
        content: {
          __typename?: "IneligibleForVoucherStepContent";
          title: string;
          body: string;
          button: { __typename?: "ButtonContent"; text: string };
        };
        config: {
          __typename?: "IneligibleForVoucherStepConfig";
          button: { __typename?: "IneligibleForVoucherStepConfigButton"; show: boolean };
        };
      }
    | { __typename?: "LookupUserStep"; type: string }
    | { __typename?: "PageDisabledStep"; type: string }
    | { __typename?: "PartnerDashboardStep"; type: string }
    | { __typename?: "PartnerPageDisabledStep"; type: string }
    | { __typename?: "RedeemRewardStep"; type: string }
    | {
        __typename?: "ReferralLimitReachedStep";
        type: string;
        optionalContent?: {
          __typename?: "ReferralLimitReachedStepContent";
          title: string;
          body: string;
          button: { __typename?: "ButtonContent"; text: string };
        } | null;
        optionalConfig?: {
          __typename?: "ReferralLimitReachedStepConfig";
          button: { __typename?: "ReferralLimitReachedStepConfigButton"; show: boolean };
        } | null;
      }
    | { __typename?: "ReferrerDashboardStep"; type: string }
    | { __typename?: "RewardEmailedReturningStep"; type: string }
    | { __typename?: "RewardEmailedStep"; type: string }
    | { __typename?: "SeamlessLoginFailureStep"; type: string }
    | { __typename?: "SelectFriendRewardOptionStep"; type: string }
    | { __typename?: "SplashPageStep"; type: string }
    | {
        __typename?: "SuccessfulFriendRegistrationStep";
        type: string;
        optionalContent?: {
          __typename?: "SuccessfulFriendRegistrationStepContent";
          title: string;
          body: string;
          secondaryButton: { __typename?: "ButtonContent"; text: string };
        } | null;
        optionalConfig?: {
          __typename?: "SuccessfulFriendRegistrationStepConfig";
          button: { __typename?: "SuccessfulFriendRegistrationStepConfigButton"; show: boolean };
        } | null;
      }
  >;
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
};

export type ContinueToClientSiteActiveStepFragmentFragment = {
  __typename?: "ContinueToClientSiteActiveStep";
  config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
};

export type IneligibleForVoucherActiveStepFragmentFragment = {
  __typename?: "IneligibleForVoucherActiveStep";
  config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
};

export type ReferralLimitReachedActiveStepFragmentFragment = {
  __typename?: "ReferralLimitReachedActiveStep";
  config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
};

type FriendJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment = {
  __typename?: "AutomaticallyRedirectFriendActiveStep";
  type: string;
};

type FriendJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment = {
  __typename?: "CaptureAdditionalFriendDetailsActiveStep";
  type: string;
  config: { __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig"; firstVisit: boolean };
  validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
};

type FriendJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment = {
  __typename?: "CaptureFriendDetailsActiveStep";
  type: string;
  config: {
    __typename?: "CaptureFriendDetailsActiveStepConfig";
    rewards?: Array<{
      __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
      title: string;
      body: string;
    }> | null;
  };
  validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
};

type FriendJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment = {
  __typename?: "CaptureReferrerDetailsActiveStep";
  type: string;
};

type FriendJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment = {
  __typename?: "ClickToClaimErrorActiveStep";
  type: string;
};

type FriendJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment = {
  __typename?: "ClickToClaimOptionConfirmationActiveStep";
  type: string;
};

type FriendJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment = {
  __typename?: "ClickToClaimOptionsSelectorActiveStep";
  type: string;
};

type FriendJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment = {
  __typename?: "ClickToRedirectFriendActiveStep";
  type: string;
};

type FriendJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment = {
  __typename?: "ConfirmFriendRewardOptionActiveStep";
  type: string;
};

type FriendJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment = {
  __typename?: "ContinueToClientSiteActiveStep";
  type: string;
  config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
};

type FriendJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardAlreadyClaimedActiveStep";
  type: string;
};

type FriendJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardClaimedActiveStep";
  type: string;
};

type FriendJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment = {
  __typename?: "FulfilmentExpiredActiveStep";
  type: string;
};

type FriendJourneySessionFragment_GenericErrorActiveStep_Fragment = {
  __typename?: "GenericErrorActiveStep";
  type: string;
};

type FriendJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment = {
  __typename?: "IneligibleForVoucherActiveStep";
  type: string;
  config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
};

type FriendJourneySessionFragment_LookupUserActiveStep_Fragment = {
  __typename?: "LookupUserActiveStep";
  type: string;
};

type FriendJourneySessionFragment_PageDisabledActiveStep_Fragment = {
  __typename?: "PageDisabledActiveStep";
  type: string;
};

type FriendJourneySessionFragment_PartnerDashboardActiveStep_Fragment = {
  __typename?: "PartnerDashboardActiveStep";
  type: string;
};

type FriendJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment = {
  __typename?: "PartnerPageDisabledActiveStep";
  type: string;
};

type FriendJourneySessionFragment_RedeemRewardActiveStep_Fragment = {
  __typename?: "RedeemRewardActiveStep";
  type: string;
};

type FriendJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment = {
  __typename?: "ReferralLimitReachedActiveStep";
  type: string;
  config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
};

type FriendJourneySessionFragment_ReferrerDashboardActiveStep_Fragment = {
  __typename?: "ReferrerDashboardActiveStep";
  type: string;
};

type FriendJourneySessionFragment_RewardEmailedActiveStep_Fragment = {
  __typename?: "RewardEmailedActiveStep";
  type: string;
};

type FriendJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment = {
  __typename?: "RewardEmailedReturningActiveStep";
  type: string;
};

type FriendJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment = {
  __typename?: "SeamlessLoginFailureActiveStep";
  type: string;
};

type FriendJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment = {
  __typename?: "SelectFriendRewardOptionActiveStep";
  type: string;
};

type FriendJourneySessionFragment_SplashPageActiveStep_Fragment = {
  __typename?: "SplashPageActiveStep";
  type: string;
};

type FriendJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment = {
  __typename?: "SuccessfulFriendRegistrationActiveStep";
  type: string;
  config: {
    __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
    showSecondaryButton: boolean;
    buttons: Array<
      | {
          __typename?: "BasicButtonContent";
          buttonClass?: string | null;
          text: string;
          url: string;
        }
      | {
          __typename?: "TileButtonContent";
          heading: string;
          description: string;
          actionId: string;
          buttonClass?: string | null;
          text: string;
          url: string;
          image: { __typename?: "ImageContent"; altText: string; url: string };
        }
    >;
  };
};

export type FriendJourneySessionFragmentFragment =
  | FriendJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment
  | FriendJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment
  | FriendJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment
  | FriendJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment
  | FriendJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment
  | FriendJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment
  | FriendJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment
  | FriendJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment
  | FriendJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment
  | FriendJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment
  | FriendJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment
  | FriendJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment
  | FriendJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment
  | FriendJourneySessionFragment_GenericErrorActiveStep_Fragment
  | FriendJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment
  | FriendJourneySessionFragment_LookupUserActiveStep_Fragment
  | FriendJourneySessionFragment_PageDisabledActiveStep_Fragment
  | FriendJourneySessionFragment_PartnerDashboardActiveStep_Fragment
  | FriendJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment
  | FriendJourneySessionFragment_RedeemRewardActiveStep_Fragment
  | FriendJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment
  | FriendJourneySessionFragment_ReferrerDashboardActiveStep_Fragment
  | FriendJourneySessionFragment_RewardEmailedActiveStep_Fragment
  | FriendJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment
  | FriendJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment
  | FriendJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment
  | FriendJourneySessionFragment_SplashPageActiveStep_Fragment
  | FriendJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment;

export type SuccessfulFriendRegistrationActiveStepFragmentFragment = {
  __typename?: "SuccessfulFriendRegistrationActiveStep";
  config: {
    __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
    showSecondaryButton: boolean;
    buttons: Array<
      | {
          __typename?: "BasicButtonContent";
          buttonClass?: string | null;
          text: string;
          url: string;
        }
      | {
          __typename?: "TileButtonContent";
          heading: string;
          description: string;
          actionId: string;
          buttonClass?: string | null;
          text: string;
          url: string;
          image: { __typename?: "ImageContent"; altText: string; url: string };
        }
    >;
  };
};

export type LookupUserJourneyConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  localisation: {
    __typename?: "Localisation";
    date: {
      __typename?: "Date";
      dayNames: Array<string>;
      abbreviatedDayNames: Array<string>;
      monthNames: Array<string>;
      abbreviatedMonthNames: Array<string>;
      shortFormat: string;
      longFormat: string;
      monthOnlyFormat: string;
    };
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
    | { __typename?: "CaptureFriendDetailsStep"; type: string }
    | { __typename?: "CaptureReferrerDetailsStep"; type: string }
    | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
    | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
    | { __typename?: "ClickToRedirectFriendStep"; type: string }
    | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
    | { __typename?: "FriendRewardClaimedStep"; type: string }
    | { __typename?: "FulfilmentExpiredStep"; type: string }
    | { __typename?: "IneligibleForVoucherStep"; type: string }
    | {
        __typename?: "LookupUserStep";
        type: string;
        content: {
          __typename?: "LookupUserStepContent";
          body: string;
          title: string;
          form: {
            __typename?: "LookupUserFormContent";
            submitError: string;
            button: { __typename?: "ButtonContent"; text: string };
            inputs: {
              __typename?: "LookupUserInputsContent";
              accessCode: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
            };
          };
          image: { __typename?: "ImageContent"; altText: string; url: string };
        };
      }
    | { __typename?: "PageDisabledStep"; type: string }
    | { __typename?: "PartnerDashboardStep"; type: string }
    | { __typename?: "PartnerPageDisabledStep"; type: string }
    | { __typename?: "RedeemRewardStep"; type: string }
    | { __typename?: "ReferralLimitReachedStep"; type: string }
    | { __typename?: "ReferrerDashboardStep"; type: string }
    | { __typename?: "RewardEmailedReturningStep"; type: string }
    | { __typename?: "RewardEmailedStep"; type: string }
    | { __typename?: "SeamlessLoginFailureStep"; type: string }
    | { __typename?: "SelectFriendRewardOptionStep"; type: string }
    | { __typename?: "SplashPageStep"; type: string }
    | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
  >;
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
};

export type LookupUserActiveStepFragmentFragment = {
  __typename?: "LookupUserActiveStep";
  config: {
    __typename?: "LookupUserActiveStepConfig";
    redirectToUrl?: string | null;
    showInvalidCodeMessage: boolean;
  };
  validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
};

export type PartnerJourneyConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  localisation: {
    __typename?: "Localisation";
    date: {
      __typename?: "Date";
      dayNames: Array<string>;
      abbreviatedDayNames: Array<string>;
      monthNames: Array<string>;
      abbreviatedMonthNames: Array<string>;
      shortFormat: string;
      longFormat: string;
      monthOnlyFormat: string;
    };
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
    | { __typename?: "CaptureFriendDetailsStep"; type: string }
    | { __typename?: "CaptureReferrerDetailsStep"; type: string }
    | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
    | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
    | { __typename?: "ClickToRedirectFriendStep"; type: string }
    | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
    | { __typename?: "FriendRewardClaimedStep"; type: string }
    | { __typename?: "FulfilmentExpiredStep"; type: string }
    | { __typename?: "IneligibleForVoucherStep"; type: string }
    | { __typename?: "LookupUserStep"; type: string }
    | { __typename?: "PageDisabledStep"; type: string }
    | {
        __typename?: "PartnerDashboardStep";
        type: string;
        content: {
          __typename?: "PartnerDashboardStepContent";
          title: string;
          referralStats: {
            __typename?: "PartnerDashboardReferralStatsContent";
            title: string;
            friendVisitCount: string;
            completeReferralCount: string;
            friendRegistrationCount: string;
            cancelledReferralCount: string;
            pendingReferralCount: string;
          };
          partnerOffer: {
            __typename?: "PartnerDashboardOfferContent";
            title: string;
            heading: string;
            body: string;
            image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
          };
          audienceOffer: {
            __typename?: "PartnerDashboardOfferContent";
            title: string;
            heading: string;
            body: string;
            image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
          };
          sharingTools: {
            __typename?: "PartnerDashboardSharingToolsContent";
            title: string;
            directLink: {
              __typename?: "PartnerDashboardSharingToolsFormContent";
              label: string;
              copyButtonText: string;
              copySuccessNotification: string;
              shareMessage: string;
              nativeSharing: {
                __typename?: "PartnerDashboardSharingToolsNativeSharingContent";
                buttonText: string;
                title: string;
              };
            };
            shareCode: {
              __typename?: "PartnerDashboardSharingToolsFormContent";
              label: string;
              copyButtonText: string;
              copySuccessNotification: string;
              shareMessage: string;
              nativeSharing: {
                __typename?: "PartnerDashboardSharingToolsNativeSharingContent";
                buttonText: string;
                title: string;
              };
            };
          };
          usefulLinks: {
            __typename?: "PartnerDashboardUsefulLinksContent";
            title: string;
            buttons: Array<{ __typename?: "LinkContent"; text: string; url: string }>;
          };
          performance: {
            __typename?: "PartnerDashboardPerformanceContent";
            title: string;
            table: {
              __typename?: "PartnerDashboardPerformanceTableContent";
              total: string;
              downloadButtonText: string;
              headers: {
                __typename?: "PartnerDashboardPerformanceTableHeadersContent";
                month: string;
                visits: string;
                created: string;
                confirmed: string;
                cancelled: string;
                rewardsConfirmed: string;
              };
              tooltips: {
                __typename?: "PartnerDashboardPerformanceTableTooltipsContent";
                visits: string;
                created: string;
                confirmed: string;
                cancelled: string;
              };
            };
          };
          rules: { __typename?: "PartnerDashboardRulesContent"; title: string; body: string };
        };
        config: {
          __typename?: "PartnerDashboardStepConfig";
          showReferralStats: boolean;
          referralStats: Array<string>;
          partnerOffer: { __typename?: "PartnerDashboardStepConfigOffer"; displayRewards: boolean };
          audienceOffer: {
            __typename?: "PartnerDashboardStepConfigOffer";
            displayRewards: boolean;
          };
          sharingTools: {
            __typename?: "PartnerDashboardStepConfigSharingTools";
            displayDirectLink: boolean;
            displayShareCode: boolean;
          };
        };
      }
    | {
        __typename?: "PartnerPageDisabledStep";
        type: string;
        content: { __typename?: "PageDisabledStepContent"; title: string; body: string };
      }
    | { __typename?: "RedeemRewardStep"; type: string }
    | { __typename?: "ReferralLimitReachedStep"; type: string }
    | { __typename?: "ReferrerDashboardStep"; type: string }
    | { __typename?: "RewardEmailedReturningStep"; type: string }
    | { __typename?: "RewardEmailedStep"; type: string }
    | { __typename?: "SeamlessLoginFailureStep"; type: string }
    | { __typename?: "SelectFriendRewardOptionStep"; type: string }
    | { __typename?: "SplashPageStep"; type: string }
    | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
  >;
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
};

export type PartnerDashboardActiveStepFragmentFragment = {
  __typename?: "PartnerDashboardActiveStep";
  config: {
    __typename?: "PartnerDashboardActiveStepConfig";
    referralStats: {
      __typename?: "PartnerDashboardActiveStepConfigReferralStats";
      friendVisitCount: number;
      completeReferralCount: number;
      friendRegistrationCount: number;
      cancelledReferralCount: number;
      pendingReferralCount: number;
    };
    sharingUrls: {
      __typename?: "PartnerDashboardActiveStepConfigSharingUrls";
      directLink: string;
      shareCode: string;
    };
    performance: {
      __typename?: "PartnerDashboardActiveStepConfigPerformance";
      total: {
        __typename?: "PartnerDashboardActiveStepConfigPerformanceTotal";
        visits: number;
        created: number;
        confirmed: number;
        cancelled: number;
        pending: number;
        rewardsConfirmed: { __typename?: "Money"; displayString: string };
      };
      details: Array<{
        __typename?: "PartnerDashboardActiveStepConfigPerformanceDetail";
        date: any;
        visits: number;
        created: number;
        confirmed: number;
        cancelled: number;
        rewardsConfirmed: { __typename?: "Money"; displayString: string };
      }>;
    };
    rewards: {
      __typename?: "PartnerDashboardActiveStepConfigRewards";
      partnerRewards: Array<{
        __typename?: "PartnerDashboardActiveStepConfigRewardDetail";
        title: string;
        body: string;
      }>;
      audienceRewards: Array<{
        __typename?: "PartnerDashboardActiveStepConfigRewardDetail";
        title: string;
        body: string;
      }>;
    };
  };
};

export type RedirectFriendToClientJourneyConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  programType: string;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
    | { __typename?: "CaptureFriendDetailsStep"; type: string }
    | { __typename?: "CaptureReferrerDetailsStep"; type: string }
    | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
    | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
    | {
        __typename?: "ClickToRedirectFriendStep";
        type: string;
        optionalContent?: {
          __typename?: "ClickToRedirectFriendStepContent";
          title: string;
          body: string;
          additionalFinePrint?: string | null;
          image: { __typename?: "ImageContent"; url: string; altText: string };
          form: {
            __typename?: "ButtonOnlyFormContent";
            caption: string;
            finePrint: string;
            button: { __typename?: "ButtonContent"; text: string };
          };
        } | null;
      }
    | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
    | { __typename?: "FriendRewardClaimedStep"; type: string }
    | { __typename?: "FulfilmentExpiredStep"; type: string }
    | { __typename?: "IneligibleForVoucherStep"; type: string }
    | { __typename?: "LookupUserStep"; type: string }
    | { __typename?: "PageDisabledStep"; type: string }
    | { __typename?: "PartnerDashboardStep"; type: string }
    | { __typename?: "PartnerPageDisabledStep"; type: string }
    | { __typename?: "RedeemRewardStep"; type: string }
    | { __typename?: "ReferralLimitReachedStep"; type: string }
    | { __typename?: "ReferrerDashboardStep"; type: string }
    | { __typename?: "RewardEmailedReturningStep"; type: string }
    | { __typename?: "RewardEmailedStep"; type: string }
    | { __typename?: "SeamlessLoginFailureStep"; type: string }
    | { __typename?: "SelectFriendRewardOptionStep"; type: string }
    | { __typename?: "SplashPageStep"; type: string }
    | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
  >;
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
};

type RedirectFriendToClientJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment = {
  __typename?: "AutomaticallyRedirectFriendActiveStep";
  type: string;
  config: {
    __typename?: "AutomaticallyRedirectFriendActiveStepConfig";
    destinationUrl?: string | null;
    requiresFriendRegistration: boolean;
  };
};

type RedirectFriendToClientJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment =
  { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string };

type RedirectFriendToClientJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment = {
  __typename?: "CaptureFriendDetailsActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment = {
  __typename?: "CaptureReferrerDetailsActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment = {
  __typename?: "ClickToClaimErrorActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment =
  { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string };

type RedirectFriendToClientJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment = {
  __typename?: "ClickToClaimOptionsSelectorActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment = {
  __typename?: "ClickToRedirectFriendActiveStep";
  type: string;
  config: {
    __typename?: "ClickToRedirectFriendActiveStepConfig";
    destinationUrl?: string | null;
    requiresFriendRegistration: boolean;
  };
};

type RedirectFriendToClientJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment = {
  __typename?: "ConfirmFriendRewardOptionActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment = {
  __typename?: "ContinueToClientSiteActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardAlreadyClaimedActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardClaimedActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment = {
  __typename?: "FulfilmentExpiredActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_GenericErrorActiveStep_Fragment = {
  __typename?: "GenericErrorActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment = {
  __typename?: "IneligibleForVoucherActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_LookupUserActiveStep_Fragment = {
  __typename?: "LookupUserActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_PageDisabledActiveStep_Fragment = {
  __typename?: "PageDisabledActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_PartnerDashboardActiveStep_Fragment = {
  __typename?: "PartnerDashboardActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment = {
  __typename?: "PartnerPageDisabledActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_RedeemRewardActiveStep_Fragment = {
  __typename?: "RedeemRewardActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment = {
  __typename?: "ReferralLimitReachedActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_ReferrerDashboardActiveStep_Fragment = {
  __typename?: "ReferrerDashboardActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_RewardEmailedActiveStep_Fragment = {
  __typename?: "RewardEmailedActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment = {
  __typename?: "RewardEmailedReturningActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment = {
  __typename?: "SeamlessLoginFailureActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment = {
  __typename?: "SelectFriendRewardOptionActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_SplashPageActiveStep_Fragment = {
  __typename?: "SplashPageActiveStep";
  type: string;
};

type RedirectFriendToClientJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment =
  { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };

export type RedirectFriendToClientJourneySessionFragmentFragment =
  | RedirectFriendToClientJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_GenericErrorActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_LookupUserActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_PageDisabledActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_PartnerDashboardActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_RedeemRewardActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_ReferrerDashboardActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_RewardEmailedActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_SplashPageActiveStep_Fragment
  | RedirectFriendToClientJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment;

export type CaptureReferrerDetailsActiveStepFragmentFragment = {
  __typename?: "CaptureReferrerDetailsActiveStep";
  config: {
    __typename?: "CaptureReferrerDetailsActiveStepConfig";
    showSignInLinkEmailedMessage: boolean;
    showEligibilityCheckRejectionMessage: boolean;
  };
  validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
};

export type ReferrerJourneyConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  localisation: {
    __typename?: "Localisation";
    date: {
      __typename?: "Date";
      dayNames: Array<string>;
      abbreviatedDayNames: Array<string>;
      monthNames: Array<string>;
      abbreviatedMonthNames: Array<string>;
      shortFormat: string;
      longFormat: string;
    };
    sharing: {
      __typename?: "SharingLocalisation";
      tabs: {
        __typename?: "SharingTabsLocalisation";
        socialButtonText: string;
        emailButtonText: string;
      };
      email: {
        __typename?: "SharingEmailLocalisation";
        messageLabelText: string;
        emailClientLabelText: string;
      };
    };
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
    | { __typename?: "CaptureFriendDetailsStep"; type: string }
    | {
        __typename?: "CaptureReferrerDetailsStep";
        type: string;
        optionalConfig?: {
          __typename?: "CaptureReferrerDetailsStepConfig";
          layout: string;
          inputs: {
            __typename?: "CaptureReferrerDetailsStepConfigInputs";
            fullName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            firstName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            lastName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            email: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
            marketingOptIn: {
              __typename?: "MarketingOptInInputConfig";
              show: boolean;
              required: boolean;
            };
            termsOptIn: { __typename?: "TermsOptInInputConfig"; show: boolean };
            customFields: Array<
              | {
                  __typename?: "CustomFieldInputConfig";
                  pattern: string;
                  key: string;
                  position: number;
                  inputFieldType: string;
                }
              | {
                  __typename?: "CustomFieldListInputConfig";
                  items: Array<string>;
                  key: string;
                  position: number;
                  inputFieldType: string;
                }
            >;
          };
          button: { __typename?: "CaptureReferrerDetailsStepConfigButton"; show: boolean };
          offerDetails: {
            __typename?: "OfferDetailsConfig";
            showStepIcons: boolean;
            revealOnLoad: boolean;
          };
        } | null;
        optionalContent?: {
          __typename?: "CaptureReferrerDetailsStepContent";
          title: string;
          signInLinkEmailedMessage: string;
          eligibilityCheckRejectionMessage: string;
          additionalFinePrint?: string | null;
          image?: {
            __typename?: "ResponsiveImage";
            url: string;
            altText: string;
            mobile?: { __typename?: "ImageContent"; url: string; altText: string } | null;
          } | null;
          offerDetails: {
            __typename?: "OfferDetailsContent";
            collapsedTitle: string;
            expandedTitle: string;
            steps: Array<string>;
            footer: string;
          };
          form: {
            __typename?: "FormContent";
            caption: string;
            submitError: string;
            finePrint: string;
            button: { __typename?: "ButtonContent"; text: string };
            inputs: {
              __typename?: "InputsContent";
              fullName: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              firstName: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              lastName: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              email: {
                __typename?: "TextInputContent";
                label: string;
                placeholder: string;
                tooltip?: string | null;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              marketingOptIn: {
                __typename?: "CheckboxInputContent";
                label: string;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              termsOptIn: {
                __typename?: "CheckboxInputContent";
                label: string;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              customFields: Array<{
                __typename?: "CustomFieldInputContent";
                key: string;
                content: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  items?: Array<string> | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
              }>;
            };
          };
        } | null;
      }
    | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
    | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
    | { __typename?: "ClickToRedirectFriendStep"; type: string }
    | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
    | { __typename?: "FriendRewardClaimedStep"; type: string }
    | { __typename?: "FulfilmentExpiredStep"; type: string }
    | { __typename?: "IneligibleForVoucherStep"; type: string }
    | { __typename?: "LookupUserStep"; type: string }
    | {
        __typename?: "PageDisabledStep";
        type: string;
        content: { __typename?: "PageDisabledStepContent"; title: string; body: string };
      }
    | { __typename?: "PartnerDashboardStep"; type: string }
    | { __typename?: "PartnerPageDisabledStep"; type: string }
    | { __typename?: "RedeemRewardStep"; type: string }
    | { __typename?: "ReferralLimitReachedStep"; type: string }
    | {
        __typename?: "ReferrerDashboardStep";
        type: string;
        content: {
          __typename?: "ReferrerDashboardStepContent";
          title: string;
          finePrint: string;
          shareMessage: string;
          logout: { __typename?: "LogoutContent"; message: string; linkText: string };
          image?: {
            __typename?: "ResponsiveImage";
            url: string;
            altText: string;
            mobile?: { __typename?: "ImageContent"; url: string; altText: string } | null;
          } | null;
          offerDetails: {
            __typename?: "OfferDetailsContent";
            collapsedTitle: string;
            expandedTitle: string;
            steps: Array<string>;
            footer: string;
          };
          referralStatus?: {
            __typename?: "ReferralStatusContent";
            stats: {
              __typename?: "ReferralStatusStatsContent";
              friendVisitCount: string;
              completeReferralCount: string;
              friendRegistrationCount: string;
              cancelledReferralCount: string;
              pendingReferralCount: string;
            };
            pill: { __typename?: "ReferralStatusPillContent"; expanded: string; collapsed: string };
            table: {
              __typename?: "ReferralStatusTableContent";
              pending: {
                __typename?: "ReferralStatusTableSectionContent";
                title: string;
                tooltip: string;
              };
              complete: {
                __typename?: "ReferralStatusTableSectionContent";
                title: string;
                tooltip: string;
              };
              headers: {
                __typename?: "ReferralStatusTableHeadersContent";
                name: string;
                date: string;
                status: string;
              };
              statuses: {
                __typename?: "ReferralStatusTableStatusesContent";
                pending: string;
                complete: string;
              };
            };
          } | null;
          rewardCapping: {
            __typename?: "RewardCappingContent";
            warningTitle: string;
            monetaryWarningMessage: string;
            volumeWarningMessage: string;
          };
          manualSharing: {
            __typename?: "ManualSharingContent";
            title: string;
            copyButtonText: string;
            copySuccessNotification: string;
          };
          nativeSharing: { __typename?: "NativeSharingContent"; title: string; buttonText: string };
          shareCode: {
            __typename?: "ReferrerDashboardShareCodeContent";
            copyButtonText: string;
            copySuccessNotification: string;
            label: string;
            shareMessage: string;
            nativeSharing: {
              __typename?: "NativeSharingContent";
              title: string;
              buttonText: string;
            };
          };
        };
        config: {
          __typename?: "ReferrerDashboardStepConfig";
          showReferralStatuses: boolean;
          showPendingReferrals: boolean;
          showReferralStats: boolean;
          referralStats: Array<string>;
          shareNetworks: Array<string>;
          layout: string;
          showDirectLink: boolean;
          showShareCode: boolean;
          offerDetails: { __typename?: "OfferDetailsConfig"; showStepIcons: boolean };
        };
        style?: {
          __typename?: "ReferrerDashboardStepStyle";
          shareButtons?: {
            __typename?: "ShareButtonsStyle";
            css?: any | null;
            _hover?: { __typename?: "HoverStyle"; css?: any | null } | null;
            icon?: {
              __typename?: "ShareButtonsIconStyle";
              css?: any | null;
              _hover?: { __typename?: "HoverStyle"; css?: any | null } | null;
            } | null;
          } | null;
        } | null;
      }
    | { __typename?: "RewardEmailedReturningStep"; type: string }
    | { __typename?: "RewardEmailedStep"; type: string }
    | {
        __typename?: "SeamlessLoginFailureStep";
        type: string;
        content: { __typename?: "SeamlessLoginFailureStepContent"; title: string; body: string };
      }
    | { __typename?: "SelectFriendRewardOptionStep"; type: string }
    | {
        __typename?: "SplashPageStep";
        type: string;
        optionalConfig?: {
          __typename?: "SplashPageStepConfig";
          layout: string;
          inputs: {
            __typename?: "SplashPageStepConfigInputs";
            marketingOptIn: {
              __typename?: "MarketingOptInInputConfig";
              show: boolean;
              required: boolean;
            };
            termsOptIn: { __typename?: "TermsOptInInputConfig"; show: boolean };
          };
          offerDetails: {
            __typename?: "OfferDetailsConfig";
            showStepIcons: boolean;
            revealOnLoad: boolean;
          };
        } | null;
        optionalContent?: {
          __typename?: "SplashPageStepContent";
          title: string;
          eligibilityCheckRejectionMessage: string;
          additionalFinePrint?: string | null;
          image?: { __typename?: "ResponsiveImage"; url: string; altText: string } | null;
          offerDetails: {
            __typename?: "OfferDetailsContent";
            collapsedTitle: string;
            expandedTitle: string;
            steps: Array<string>;
            footer: string;
          };
          form: {
            __typename?: "FormContent";
            caption: string;
            submitError: string;
            finePrint: string;
            button: { __typename?: "ButtonContent"; text: string };
            inputs: {
              __typename?: "InputsContent";
              marketingOptIn: {
                __typename?: "CheckboxInputContent";
                label: string;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
              termsOptIn: {
                __typename?: "CheckboxInputContent";
                label: string;
                validationErrors: Array<{
                  __typename?: "ValidationErrorContent";
                  key: string;
                  value: string;
                }>;
              };
            };
          };
        } | null;
      }
    | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
  >;
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
};

export type ReferrerDashboardActiveStepFragmentFragment = {
  __typename?: "ReferrerDashboardActiveStep";
  config: {
    __typename?: "ReferrerDashboardActiveStepConfig";
    canLogout: boolean;
    manualSharingShortUrl: string;
    nativeSharingShortUrl: string;
    emailSharingShortUrl: string;
    rewardCappingType: string;
    showRewardLimitWarningMessage: boolean;
    shareNetworkShortUrls: {
      __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
      facebook: string;
      twitter: string;
      pinterest: string;
      reddit: string;
      whatsapp: string;
      facebookMessenger: string;
      line: string;
      vkontakte: string;
      zalo: string;
      viber: string;
      telegram: string;
    };
    referralStatus: {
      __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
      friendVisitCount: number;
      completeReferralCount: number;
      friendRegistrationCount: number;
      cancelledReferralCount: number;
      pendingReferralCount: number;
      completeReferrals: Array<{
        __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
        name: string;
        date: any;
        status: string;
      }>;
      pendingReferrals: Array<{
        __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
        name: string;
        date: any;
        status: string;
      }>;
    };
  };
};

type ReferrerJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment = {
  __typename?: "AutomaticallyRedirectFriendActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment = {
  __typename?: "CaptureAdditionalFriendDetailsActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment = {
  __typename?: "CaptureFriendDetailsActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment = {
  __typename?: "CaptureReferrerDetailsActiveStep";
  type: string;
  config: {
    __typename?: "CaptureReferrerDetailsActiveStepConfig";
    showSignInLinkEmailedMessage: boolean;
    showEligibilityCheckRejectionMessage: boolean;
  };
  validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
};

type ReferrerJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment = {
  __typename?: "ClickToClaimErrorActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment = {
  __typename?: "ClickToClaimOptionConfirmationActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment = {
  __typename?: "ClickToClaimOptionsSelectorActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment = {
  __typename?: "ClickToRedirectFriendActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment = {
  __typename?: "ConfirmFriendRewardOptionActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment = {
  __typename?: "ContinueToClientSiteActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardAlreadyClaimedActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardClaimedActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment = {
  __typename?: "FulfilmentExpiredActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_GenericErrorActiveStep_Fragment = {
  __typename?: "GenericErrorActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment = {
  __typename?: "IneligibleForVoucherActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_LookupUserActiveStep_Fragment = {
  __typename?: "LookupUserActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_PageDisabledActiveStep_Fragment = {
  __typename?: "PageDisabledActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_PartnerDashboardActiveStep_Fragment = {
  __typename?: "PartnerDashboardActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment = {
  __typename?: "PartnerPageDisabledActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_RedeemRewardActiveStep_Fragment = {
  __typename?: "RedeemRewardActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment = {
  __typename?: "ReferralLimitReachedActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_ReferrerDashboardActiveStep_Fragment = {
  __typename?: "ReferrerDashboardActiveStep";
  type: string;
  config: {
    __typename?: "ReferrerDashboardActiveStepConfig";
    canLogout: boolean;
    manualSharingShortUrl: string;
    nativeSharingShortUrl: string;
    emailSharingShortUrl: string;
    rewardCappingType: string;
    showRewardLimitWarningMessage: boolean;
    shareNetworkShortUrls: {
      __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
      facebook: string;
      twitter: string;
      pinterest: string;
      reddit: string;
      whatsapp: string;
      facebookMessenger: string;
      line: string;
      vkontakte: string;
      zalo: string;
      viber: string;
      telegram: string;
    };
    referralStatus: {
      __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
      friendVisitCount: number;
      completeReferralCount: number;
      friendRegistrationCount: number;
      cancelledReferralCount: number;
      pendingReferralCount: number;
      completeReferrals: Array<{
        __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
        name: string;
        date: any;
        status: string;
      }>;
      pendingReferrals: Array<{
        __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
        name: string;
        date: any;
        status: string;
      }>;
    };
  };
};

type ReferrerJourneySessionFragment_RewardEmailedActiveStep_Fragment = {
  __typename?: "RewardEmailedActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment = {
  __typename?: "RewardEmailedReturningActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment = {
  __typename?: "SeamlessLoginFailureActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment = {
  __typename?: "SelectFriendRewardOptionActiveStep";
  type: string;
};

type ReferrerJourneySessionFragment_SplashPageActiveStep_Fragment = {
  __typename?: "SplashPageActiveStep";
  type: string;
  config: {
    __typename?: "SplashPageActiveStepConfig";
    showEligibilityCheckRejectionMessage: boolean;
  };
};

type ReferrerJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment = {
  __typename?: "SuccessfulFriendRegistrationActiveStep";
  type: string;
};

export type ReferrerJourneySessionFragmentFragment =
  | ReferrerJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment
  | ReferrerJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment
  | ReferrerJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment
  | ReferrerJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment
  | ReferrerJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment
  | ReferrerJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment
  | ReferrerJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment
  | ReferrerJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment
  | ReferrerJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment
  | ReferrerJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment
  | ReferrerJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment
  | ReferrerJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment
  | ReferrerJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment
  | ReferrerJourneySessionFragment_GenericErrorActiveStep_Fragment
  | ReferrerJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment
  | ReferrerJourneySessionFragment_LookupUserActiveStep_Fragment
  | ReferrerJourneySessionFragment_PageDisabledActiveStep_Fragment
  | ReferrerJourneySessionFragment_PartnerDashboardActiveStep_Fragment
  | ReferrerJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment
  | ReferrerJourneySessionFragment_RedeemRewardActiveStep_Fragment
  | ReferrerJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment
  | ReferrerJourneySessionFragment_ReferrerDashboardActiveStep_Fragment
  | ReferrerJourneySessionFragment_RewardEmailedActiveStep_Fragment
  | ReferrerJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment
  | ReferrerJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment
  | ReferrerJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment
  | ReferrerJourneySessionFragment_SplashPageActiveStep_Fragment
  | ReferrerJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment;

export type SplashPageActiveStepFragmentFragment = {
  __typename?: "SplashPageActiveStep";
  config: {
    __typename?: "SplashPageActiveStepConfig";
    showEligibilityCheckRejectionMessage: boolean;
  };
};

export type RewardRedemptionJourneyConfigFragmentFragment = {
  __typename?: "Config";
  globalStyles: any;
  programType: string;
  customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
  styles: {
    __typename?: "Styles";
    font: {
      __typename?: "Font";
      family: string;
      weight: string;
      sizes: {
        __typename?: "FontSizes";
        extraSmall: string;
        small: string;
        medium: string;
        large: string;
        extraLarge: string;
      };
    };
    colors: { __typename?: "Colors"; highlight: string; error: string };
    layout: { __typename?: "Layout"; backgroundColor: string; color: string };
    button: {
      __typename?: "Button";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      primary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
      secondary: {
        __typename?: "ButtonState";
        css?: any | null;
        color: string;
        backgroundColor: string;
        _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
      };
    };
    textInput: {
      __typename?: "TextInput";
      css?: any | null;
      height: string;
      fontSize: string;
      borderRadius: string;
      color: string;
      backgroundColor: string;
      label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
      _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    form: {
      __typename?: "Form";
      css?: any | null;
      backgroundColor: string;
      captionColor: string;
      borderColor: string;
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    switch: {
      __typename?: "Switch";
      css?: any | null;
      backgroundColor: string;
      label: {
        __typename?: "SwitchLabel";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
      error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
    };
    link: {
      __typename?: "Link";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontSize: string;
      textDecoration: string;
      _hover: { __typename?: "LinkState"; css?: any | null; color: string };
      _active: { __typename?: "LinkState"; css?: any | null; color: string };
      _visited: { __typename?: "LinkState"; css?: any | null; color: string };
    };
    notification: {
      __typename?: "Notification";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontSize: string;
      successful: { __typename?: "NotificationStyle"; iconColor: string };
    };
    card: {
      __typename?: "Card";
      css?: any | null;
      backgroundColor: string;
      title: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      description: {
        __typename?: "CardTextElement";
        css?: any | null;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
    };
    title: {
      __typename?: "Title";
      css?: any | null;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    avatar: {
      __typename?: "AvatarStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
    };
    pill: {
      __typename?: "PillStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      fontFamily: string;
    };
    tabSwitcher: {
      __typename?: "TabSwitcherStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      borderRadius: string;
      fontSize: string;
      fontWeight: string;
      tab: {
        __typename?: "TabStyle";
        css?: any | null;
        _active: {
          __typename?: "TabSwitcherActiveStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
          borderRadius: string;
        };
      };
    };
    strong: {
      __typename?: "StrongStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
    };
    emphasis: {
      __typename?: "EmphasisStyle";
      css?: any | null;
      color: string;
      fontFamily: string;
      fontWeight: string;
      fontStyle: string;
    };
    shareArea: {
      __typename?: "ShareAreaStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
      borderRadius: string;
      height: string;
      _hover: {
        __typename?: "ShareAreaHoverStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
      };
      manualSharingTitle: {
        __typename?: "ManualSharingTitleStyle";
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
      };
    };
    errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
    legal: {
      __typename?: "LegalStyle";
      color: string;
      fontFamily: string;
      fontSize: string;
      lineHeight: string;
      opacity: number;
      css?: any | null;
    };
  };
  urls: {
    __typename?: "Urls";
    termsAndConditionsUrl: string;
    privacyPolicyUrl: string;
    faqsUrl: string;
    userLookupUrl: string;
  };
  steps: Array<
    | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
    | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
    | { __typename?: "CaptureFriendDetailsStep"; type: string }
    | { __typename?: "CaptureReferrerDetailsStep"; type: string }
    | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
    | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
    | { __typename?: "ClickToRedirectFriendStep"; type: string }
    | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
    | { __typename?: "ContinueToClientSiteStep"; type: string }
    | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
    | { __typename?: "FriendRewardClaimedStep"; type: string }
    | { __typename?: "FulfilmentExpiredStep"; type: string }
    | { __typename?: "IneligibleForVoucherStep"; type: string }
    | { __typename?: "LookupUserStep"; type: string }
    | { __typename?: "PageDisabledStep"; type: string }
    | { __typename?: "PartnerDashboardStep"; type: string }
    | { __typename?: "PartnerPageDisabledStep"; type: string }
    | { __typename?: "RedeemRewardStep"; type: string }
    | { __typename?: "ReferralLimitReachedStep"; type: string }
    | { __typename?: "ReferrerDashboardStep"; type: string }
    | { __typename?: "RewardEmailedReturningStep"; type: string }
    | { __typename?: "RewardEmailedStep"; type: string }
    | { __typename?: "SeamlessLoginFailureStep"; type: string }
    | { __typename?: "SelectFriendRewardOptionStep"; type: string }
    | { __typename?: "SplashPageStep"; type: string }
    | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
  >;
};

export type RedeemRewardActiveStepFragmentFragment = {
  __typename?: "RedeemRewardActiveStep";
  config: { __typename?: "RedeemRewardActiveStepConfig"; destinationUrl: string };
};

type RewardRedemptionJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment = {
  __typename?: "AutomaticallyRedirectFriendActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment = {
  __typename?: "CaptureAdditionalFriendDetailsActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment = {
  __typename?: "CaptureFriendDetailsActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment = {
  __typename?: "CaptureReferrerDetailsActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment = {
  __typename?: "ClickToClaimErrorActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment = {
  __typename?: "ClickToClaimOptionConfirmationActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment = {
  __typename?: "ClickToClaimOptionsSelectorActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment = {
  __typename?: "ClickToRedirectFriendActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment = {
  __typename?: "ConfirmFriendRewardOptionActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment = {
  __typename?: "ContinueToClientSiteActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardAlreadyClaimedActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment = {
  __typename?: "FriendRewardClaimedActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment = {
  __typename?: "FulfilmentExpiredActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_GenericErrorActiveStep_Fragment = {
  __typename?: "GenericErrorActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment = {
  __typename?: "IneligibleForVoucherActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_LookupUserActiveStep_Fragment = {
  __typename?: "LookupUserActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_PageDisabledActiveStep_Fragment = {
  __typename?: "PageDisabledActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_PartnerDashboardActiveStep_Fragment = {
  __typename?: "PartnerDashboardActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment = {
  __typename?: "PartnerPageDisabledActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_RedeemRewardActiveStep_Fragment = {
  __typename?: "RedeemRewardActiveStep";
  type: string;
  config: { __typename?: "RedeemRewardActiveStepConfig"; destinationUrl: string };
};

type RewardRedemptionJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment = {
  __typename?: "ReferralLimitReachedActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_ReferrerDashboardActiveStep_Fragment = {
  __typename?: "ReferrerDashboardActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_RewardEmailedActiveStep_Fragment = {
  __typename?: "RewardEmailedActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment = {
  __typename?: "RewardEmailedReturningActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment = {
  __typename?: "SeamlessLoginFailureActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment = {
  __typename?: "SelectFriendRewardOptionActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_SplashPageActiveStep_Fragment = {
  __typename?: "SplashPageActiveStep";
  type: string;
};

type RewardRedemptionJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment = {
  __typename?: "SuccessfulFriendRegistrationActiveStep";
  type: string;
};

export type RewardRedemptionJourneySessionFragmentFragment =
  | RewardRedemptionJourneySessionFragment_AutomaticallyRedirectFriendActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_CaptureAdditionalFriendDetailsActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_CaptureFriendDetailsActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_CaptureReferrerDetailsActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ClickToClaimErrorActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ClickToClaimOptionConfirmationActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ClickToClaimOptionsSelectorActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ClickToRedirectFriendActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ConfirmFriendRewardOptionActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ContinueToClientSiteActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_FriendRewardAlreadyClaimedActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_FriendRewardClaimedActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_FulfilmentExpiredActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_GenericErrorActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_IneligibleForVoucherActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_LookupUserActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_PageDisabledActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_PartnerDashboardActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_PartnerPageDisabledActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_RedeemRewardActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ReferralLimitReachedActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_ReferrerDashboardActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_RewardEmailedActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_RewardEmailedReturningActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_SeamlessLoginFailureActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_SelectFriendRewardOptionActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_SplashPageActiveStep_Fragment
  | RewardRedemptionJourneySessionFragment_SuccessfulFriendRegistrationActiveStep_Fragment;

export type SharedStyleFragmentFragment = {
  __typename?: "Styles";
  font: {
    __typename?: "Font";
    family: string;
    weight: string;
    sizes: {
      __typename?: "FontSizes";
      extraSmall: string;
      small: string;
      medium: string;
      large: string;
      extraLarge: string;
    };
  };
  colors: { __typename?: "Colors"; highlight: string; error: string };
  layout: { __typename?: "Layout"; backgroundColor: string; color: string };
  button: {
    __typename?: "Button";
    css?: any | null;
    fontFamily: string;
    fontSize: string;
    fontWeight: string;
    borderRadius: string;
    height: string;
    primary: {
      __typename?: "ButtonState";
      css?: any | null;
      color: string;
      backgroundColor: string;
      _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
    };
    secondary: {
      __typename?: "ButtonState";
      css?: any | null;
      color: string;
      backgroundColor: string;
      _disabled?: { __typename?: "DisabledStyle"; color: string; backgroundColor: string } | null;
    };
  };
  textInput: {
    __typename?: "TextInput";
    css?: any | null;
    height: string;
    fontSize: string;
    borderRadius: string;
    color: string;
    backgroundColor: string;
    label: { __typename?: "TextInputLabel"; fontSize: string; fontWeight: string; color: string };
    _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
    error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
  };
  form: {
    __typename?: "Form";
    css?: any | null;
    backgroundColor: string;
    captionColor: string;
    borderColor: string;
    error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
  };
  switch: {
    __typename?: "Switch";
    css?: any | null;
    backgroundColor: string;
    label: {
      __typename?: "SwitchLabel";
      css?: any | null;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
    error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
  };
  link: {
    __typename?: "Link";
    css?: any | null;
    color: string;
    fontFamily: string;
    fontWeight: string;
    fontSize: string;
    textDecoration: string;
    _hover: { __typename?: "LinkState"; css?: any | null; color: string };
    _active: { __typename?: "LinkState"; css?: any | null; color: string };
    _visited: { __typename?: "LinkState"; css?: any | null; color: string };
  };
  notification: {
    __typename?: "Notification";
    css?: any | null;
    backgroundColor: string;
    color: string;
    fontSize: string;
    successful: { __typename?: "NotificationStyle"; iconColor: string };
  };
  card: {
    __typename?: "Card";
    css?: any | null;
    backgroundColor: string;
    title: {
      __typename?: "CardTextElement";
      css?: any | null;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
    description: {
      __typename?: "CardTextElement";
      css?: any | null;
      fontSize: string;
      fontWeight: string;
      color: string;
    };
  };
  title: {
    __typename?: "Title";
    css?: any | null;
    fontFamily: string;
    fontSize: string;
    fontWeight: string;
    color: string;
  };
  avatar: {
    __typename?: "AvatarStyle";
    css?: any | null;
    backgroundColor: string;
    color: string;
    borderRadius: string;
  };
  pill: {
    __typename?: "PillStyle";
    css?: any | null;
    backgroundColor: string;
    color: string;
    borderRadius: string;
    fontSize: string;
    fontWeight: string;
    fontFamily: string;
  };
  tabSwitcher: {
    __typename?: "TabSwitcherStyle";
    css?: any | null;
    backgroundColor: string;
    color: string;
    borderRadius: string;
    fontSize: string;
    fontWeight: string;
    tab: {
      __typename?: "TabStyle";
      css?: any | null;
      _active: {
        __typename?: "TabSwitcherActiveStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
    };
  };
  strong: {
    __typename?: "StrongStyle";
    css?: any | null;
    color: string;
    fontFamily: string;
    fontWeight: string;
  };
  emphasis: {
    __typename?: "EmphasisStyle";
    css?: any | null;
    color: string;
    fontFamily: string;
    fontWeight: string;
    fontStyle: string;
  };
  shareArea: {
    __typename?: "ShareAreaStyle";
    css?: any | null;
    backgroundColor: string;
    color: string;
    fontFamily: string;
    fontSize: string;
    fontWeight: string;
    borderRadius: string;
    height: string;
    _hover: {
      __typename?: "ShareAreaHoverStyle";
      css?: any | null;
      backgroundColor: string;
      color: string;
    };
    manualSharingTitle: {
      __typename?: "ManualSharingTitleStyle";
      fontFamily: string;
      fontSize: string;
      fontWeight: string;
    };
  };
  errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
  warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
  legal: {
    __typename?: "LegalStyle";
    color: string;
    fontFamily: string;
    fontSize: string;
    lineHeight: string;
    opacity: number;
    css?: any | null;
  };
};

export type UrlsFragmentFragment = {
  __typename?: "Urls";
  termsAndConditionsUrl: string;
  privacyPolicyUrl: string;
  faqsUrl: string;
  userLookupUrl: string;
};

export type ClickToClaimFriendGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ClickToClaimFriendGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    localisation: {
      __typename?: "Localisation";
      date: {
        __typename?: "Date";
        dayNames: Array<string>;
        abbreviatedDayNames: Array<string>;
        monthNames: Array<string>;
        abbreviatedMonthNames: Array<string>;
        shortFormat: string;
        longFormat: string;
        monthOnlyFormat: string;
      };
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
      | { __typename?: "CaptureFriendDetailsStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
      | { __typename?: "ClickToRedirectFriendStep"; type: string }
      | {
          __typename?: "ConfirmFriendRewardOptionStep";
          type: string;
          config: {
            __typename?: "ConfirmFriendRewardOptionStepConfig";
            footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
          };
          content: {
            __typename?: "ConfirmFriendRewardOptionStepContent";
            title: string;
            confirmButton: string;
            cancelButton: string;
            claimRewardError: string;
            footerLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | {
          __typename?: "FriendRewardAlreadyClaimedStep";
          type: string;
          content: {
            __typename?: "FriendRewardAlreadyClaimedStepContent";
            title: string;
            body: string;
          };
        }
      | {
          __typename?: "FriendRewardClaimedStep";
          type: string;
          config: {
            __typename?: "FriendRewardClaimedStepConfig";
            footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
          };
          content: {
            __typename?: "FriendRewardClaimedStepContent";
            title: string;
            body: string;
            footerLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | {
          __typename?: "FulfilmentExpiredStep";
          type: string;
          config: {
            __typename?: "FulfilmentExpiredStepConfig";
            footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
          };
          content: {
            __typename?: "FulfilmentExpiredStepContent";
            title: string;
            body: string;
            footerLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | { __typename?: "IneligibleForVoucherStep"; type: string }
      | { __typename?: "LookupUserStep"; type: string }
      | { __typename?: "PageDisabledStep"; type: string }
      | { __typename?: "PartnerDashboardStep"; type: string }
      | { __typename?: "PartnerPageDisabledStep"; type: string }
      | { __typename?: "RedeemRewardStep"; type: string }
      | { __typename?: "ReferralLimitReachedStep"; type: string }
      | { __typename?: "ReferrerDashboardStep"; type: string }
      | { __typename?: "RewardEmailedReturningStep"; type: string }
      | { __typename?: "RewardEmailedStep"; type: string }
      | { __typename?: "SeamlessLoginFailureStep"; type: string }
      | {
          __typename?: "SelectFriendRewardOptionStep";
          type: string;
          config: {
            __typename?: "SelectFriendRewardOptionStepConfig";
            introLink: { __typename?: "ClickToClaimLink"; show: boolean };
            rewardTypes: Array<{
              __typename?: "ClickToClaimFriendRewardType";
              id: string;
              rewardOptions: Array<{
                __typename?: "RewardOption";
                key: string;
                position: number;
                title: string;
                body: string;
                containerBackgroundColor: string;
                selectButtonText: string;
                image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
              }>;
            }>;
          };
          content: {
            __typename?: "SelectFriendRewardOptionStepContent";
            title: string;
            body: string;
            claimRewardError: string;
            expiryInfo: string;
            introLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | { __typename?: "SplashPageStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
    >;
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
  };
};

export type ClickToClaimFriendGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ClickToClaimFriendGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
      | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
      | { __typename?: "LookupUserActiveStep"; type: string }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | { __typename?: "PartnerDashboardActiveStep"; type: string }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | { __typename?: "RedeemRewardActiveStep"; type: string }
      | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
      | { __typename?: "ReferrerDashboardActiveStep"; type: string }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | {
          __typename?: "SelectFriendRewardOptionActiveStep";
          type: string;
          config: {
            __typename?: "SelectFriendRewardOptionActiveStepConfig";
            rewardTypeId: string;
            allowedRewardOptions: Array<string>;
          };
        }
      | { __typename?: "SplashPageActiveStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
  };
};

export type ClickToClaimReferrerGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ClickToClaimReferrerGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    localisation: {
      __typename?: "Localisation";
      date: {
        __typename?: "Date";
        dayNames: Array<string>;
        abbreviatedDayNames: Array<string>;
        monthNames: Array<string>;
        abbreviatedMonthNames: Array<string>;
        shortFormat: string;
        longFormat: string;
        monthOnlyFormat: string;
      };
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
      | { __typename?: "CaptureFriendDetailsStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsStep"; type: string }
      | {
          __typename?: "ClickToClaimOptionConfirmationStep";
          type: string;
          config: {
            __typename?: "ClickToClaimOptionConfirmationStepConfig";
            footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
          };
          content: {
            __typename?: "ClickToClaimOptionConfirmationStepContent";
            title: string;
            confirmButton: string;
            cancelButton: string;
            claimRewardError: string;
            footerLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | {
          __typename?: "ClickToClaimOptionsSelectorStep";
          type: string;
          config: {
            __typename?: "ClickToClaimOptionsSelectorStepConfig";
            introLink: { __typename?: "ClickToClaimLink"; show: boolean };
            rewardTypes: Array<{
              __typename?: "ClickToClaimRewardType";
              id: string;
              rewardOptions: Array<{
                __typename?: "RewardOption";
                key: string;
                position: number;
                title: string;
                body: string;
                containerBackgroundColor: string;
                selectButtonText: string;
                image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
              }>;
            }>;
          };
          content: {
            __typename?: "ClickToClaimOptionsSelectorStepContent";
            title: string;
            body: string;
            claimRewardError: string;
            expiryInfo: string;
            introLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | { __typename?: "ClickToRedirectFriendStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
      | { __typename?: "FriendRewardClaimedStep"; type: string }
      | {
          __typename?: "FulfilmentExpiredStep";
          type: string;
          config: {
            __typename?: "FulfilmentExpiredStepConfig";
            footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
          };
          content: {
            __typename?: "FulfilmentExpiredStepContent";
            title: string;
            body: string;
            footerLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | { __typename?: "IneligibleForVoucherStep"; type: string }
      | { __typename?: "LookupUserStep"; type: string }
      | { __typename?: "PageDisabledStep"; type: string }
      | { __typename?: "PartnerDashboardStep"; type: string }
      | { __typename?: "PartnerPageDisabledStep"; type: string }
      | { __typename?: "RedeemRewardStep"; type: string }
      | { __typename?: "ReferralLimitReachedStep"; type: string }
      | { __typename?: "ReferrerDashboardStep"; type: string }
      | {
          __typename?: "RewardEmailedReturningStep";
          type: string;
          content: {
            __typename?: "RewardEmailedReturningStepContent";
            title: string;
            body: string;
          };
        }
      | {
          __typename?: "RewardEmailedStep";
          type: string;
          config: {
            __typename?: "RewardEmailedStepConfig";
            footerLink: { __typename?: "ClickToClaimLink"; show: boolean };
          };
          content: {
            __typename?: "RewardEmailedStepContent";
            title: string;
            body: string;
            footerLink: { __typename?: "LinkContent"; text: string; url: string };
          };
        }
      | { __typename?: "SeamlessLoginFailureStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionStep"; type: string }
      | { __typename?: "SplashPageStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
    >;
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
  };
};

export type ClickToClaimReferrerGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ClickToClaimReferrerGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | {
          __typename?: "ClickToClaimOptionsSelectorActiveStep";
          type: string;
          config: {
            __typename?: "ClickToClaimOptionsSelectorActiveStepConfig";
            rewardTypeId: string;
            allowedRewardOptions: Array<string>;
          };
        }
      | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
      | { __typename?: "LookupUserActiveStep"; type: string }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | { __typename?: "PartnerDashboardActiveStep"; type: string }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | { __typename?: "RedeemRewardActiveStep"; type: string }
      | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
      | { __typename?: "ReferrerDashboardActiveStep"; type: string }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "SplashPageActiveStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
  };
};

export type ClaimRewardMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  optionKey: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  userType: Scalars["String"]["input"];
}>;

export type ClaimRewardMutation = {
  __typename?: "Mutation";
  claimReward?: {
    __typename?: "ClaimRewardPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type FriendJourneyGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type FriendJourneyGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    programType: string;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | {
          __typename?: "CaptureAdditionalFriendDetailsStep";
          type: string;
          optionalContent?: {
            __typename?: "CaptureAdditionalFriendDetailsStepContent";
            finePrint?: string | null;
            nextVisit: {
              __typename?: "CaptureAdditionalFriendDetailsNextVisitContent";
              instructions?: string | null;
              showButtons: boolean;
              card: {
                __typename?: "CaptureAdditionalFriendDetailsCardContent";
                title: string;
                body?: string | null;
                finePrint?: string | null;
              };
            };
            firstVisit: {
              __typename?: "CaptureAdditionalFriendDetailsFirstVisitContent";
              instructions: string;
              card: {
                __typename?: "CaptureAdditionalFriendDetailsCardContent";
                title: string;
                body?: string | null;
                finePrint?: string | null;
              };
            };
            form: {
              __typename?: "CaptureAdditionalFriendDetailsFormContent";
              submitError: string;
              inputs: {
                __typename?: "CaptureAdditionalFriendDetailsInputsContent";
                customFields: Array<{
                  __typename?: "CustomFieldInputContent";
                  key: string;
                  content: {
                    __typename?: "TextInputContent";
                    label: string;
                    placeholder: string;
                    items?: Array<string> | null;
                    tooltip?: string | null;
                    validationErrors: Array<{
                      __typename?: "ValidationErrorContent";
                      key: string;
                      value: string;
                    }>;
                  };
                }>;
              };
              button: { __typename?: "ButtonContent"; text: string };
            };
            buttons: Array<
              | {
                  __typename?: "BasicButtonContent";
                  buttonClass?: string | null;
                  text: string;
                  url: string;
                }
              | {
                  __typename?: "TileButtonContent";
                  heading: string;
                  description: string;
                  actionId: string;
                  buttonClass?: string | null;
                  text: string;
                  url: string;
                  image: { __typename?: "ImageContent"; altText: string; url: string };
                }
            >;
          } | null;
          optionalConfig?: {
            __typename?: "CaptureAdditionalFriendDetailsStepConfig";
            inputs: {
              __typename?: "CaptureAdditionalFriendDetailsStepConfigInputs";
              customFields: Array<
                | {
                    __typename?: "CustomFieldInputConfig";
                    pattern: string;
                    key: string;
                    position: number;
                    inputFieldType: string;
                  }
                | {
                    __typename?: "CustomFieldListInputConfig";
                    items: Array<string>;
                    key: string;
                    position: number;
                    inputFieldType: string;
                  }
              >;
            };
          } | null;
        }
      | {
          __typename?: "CaptureFriendDetailsStep";
          type: string;
          content: {
            __typename?: "CaptureFriendDetailsStepContent";
            title: string;
            body: string;
            additionalFinePrint?: string | null;
            image: {
              __typename?: "ResponsiveImage";
              url: string;
              altText: string;
              mobile?: { __typename?: "ImageContent"; url: string; altText: string } | null;
            };
            form: {
              __typename?: "FormContent";
              caption: string;
              submitError: string;
              finePrint: string;
              button: { __typename?: "ButtonContent"; text: string };
              inputs: {
                __typename?: "InputsContent";
                fullName: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                firstName: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                lastName: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                email: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                marketingOptIn: {
                  __typename?: "CheckboxInputContent";
                  label: string;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                termsOptIn: {
                  __typename?: "CheckboxInputContent";
                  label: string;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                customFields: Array<{
                  __typename?: "CustomFieldInputContent";
                  key: string;
                  content: {
                    __typename?: "TextInputContent";
                    label: string;
                    placeholder: string;
                    items?: Array<string> | null;
                    validationErrors: Array<{
                      __typename?: "ValidationErrorContent";
                      key: string;
                      value: string;
                    }>;
                  };
                }>;
              };
            };
            partnerDetails?: {
              __typename?: "PartnerDetails";
              avatarUrl: string;
              bannerUrl: string;
              businessName: string;
              displayName: string;
              offerHeader: string;
              quote: string;
            } | null;
          };
          config: {
            __typename?: "CaptureFriendDetailsStepConfig";
            partialRegistration: boolean;
            displayPartnerRewards: boolean;
            inputs: {
              __typename?: "CaptureFriendDetailsStepConfigInputs";
              fullName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              firstName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              lastName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              email: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              marketingOptIn: {
                __typename?: "MarketingOptInInputConfig";
                show: boolean;
                required: boolean;
              };
              termsOptIn: { __typename?: "TermsOptInInputConfig"; show: boolean };
              customFields: Array<
                | {
                    __typename?: "CustomFieldInputConfig";
                    pattern: string;
                    key: string;
                    position: number;
                    inputFieldType: string;
                  }
                | {
                    __typename?: "CustomFieldListInputConfig";
                    items: Array<string>;
                    key: string;
                    position: number;
                    inputFieldType: string;
                  }
              >;
            };
            button: { __typename?: "CaptureFriendDetailsStepConfigButton"; show: boolean };
          };
        }
      | { __typename?: "CaptureReferrerDetailsStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
      | { __typename?: "ClickToRedirectFriendStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
      | { __typename?: "FriendRewardClaimedStep"; type: string }
      | { __typename?: "FulfilmentExpiredStep"; type: string }
      | {
          __typename?: "IneligibleForVoucherStep";
          type: string;
          content: {
            __typename?: "IneligibleForVoucherStepContent";
            title: string;
            body: string;
            button: { __typename?: "ButtonContent"; text: string };
          };
          config: {
            __typename?: "IneligibleForVoucherStepConfig";
            button: { __typename?: "IneligibleForVoucherStepConfigButton"; show: boolean };
          };
        }
      | { __typename?: "LookupUserStep"; type: string }
      | { __typename?: "PageDisabledStep"; type: string }
      | { __typename?: "PartnerDashboardStep"; type: string }
      | { __typename?: "PartnerPageDisabledStep"; type: string }
      | { __typename?: "RedeemRewardStep"; type: string }
      | {
          __typename?: "ReferralLimitReachedStep";
          type: string;
          optionalContent?: {
            __typename?: "ReferralLimitReachedStepContent";
            title: string;
            body: string;
            button: { __typename?: "ButtonContent"; text: string };
          } | null;
          optionalConfig?: {
            __typename?: "ReferralLimitReachedStepConfig";
            button: { __typename?: "ReferralLimitReachedStepConfigButton"; show: boolean };
          } | null;
        }
      | { __typename?: "ReferrerDashboardStep"; type: string }
      | { __typename?: "RewardEmailedReturningStep"; type: string }
      | { __typename?: "RewardEmailedStep"; type: string }
      | { __typename?: "SeamlessLoginFailureStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionStep"; type: string }
      | { __typename?: "SplashPageStep"; type: string }
      | {
          __typename?: "SuccessfulFriendRegistrationStep";
          type: string;
          optionalContent?: {
            __typename?: "SuccessfulFriendRegistrationStepContent";
            title: string;
            body: string;
            secondaryButton: { __typename?: "ButtonContent"; text: string };
          } | null;
          optionalConfig?: {
            __typename?: "SuccessfulFriendRegistrationStepConfig";
            button: { __typename?: "SuccessfulFriendRegistrationStepConfigButton"; show: boolean };
          } | null;
        }
    >;
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
  };
};

export type AddFriendActionIdMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  actionId: Scalars["String"]["input"];
}>;

export type AddFriendActionIdMutation = {
  __typename?: "Mutation";
  addFriendActionId?: {
    __typename?: "AddFriendActionIdPayload";
    ok: boolean;
    errors: Array<string>;
  } | null;
};

export type AddFriendDetailsMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  customFields: Array<CustomFieldInput> | CustomFieldInput;
}>;

export type AddFriendDetailsMutation = {
  __typename?: "Mutation";
  addFriendDetails?: {
    __typename?: "AddFriendDetailsPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | {
            __typename?: "CaptureAdditionalFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig";
              firstVisit: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | {
            __typename?: "CaptureFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureFriendDetailsActiveStepConfig";
              rewards?: Array<{
                __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
                title: string;
                body: string;
              }> | null;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "ContinueToClientSiteActiveStep";
            type: string;
            config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | {
            __typename?: "IneligibleForVoucherActiveStep";
            type: string;
            config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | {
            __typename?: "ReferralLimitReachedActiveStep";
            type: string;
            config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | {
            __typename?: "SuccessfulFriendRegistrationActiveStep";
            type: string;
            config: {
              __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
              showSecondaryButton: boolean;
              buttons: Array<
                | {
                    __typename?: "BasicButtonContent";
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                  }
                | {
                    __typename?: "TileButtonContent";
                    heading: string;
                    description: string;
                    actionId: string;
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                    image: { __typename?: "ImageContent"; altText: string; url: string };
                  }
              >;
            };
          };
    };
  } | null;
};

export type AddFriendReferralMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type AddFriendReferralMutation = {
  __typename?: "Mutation";
  addFriendReferral?: {
    __typename?: "AddFriendReferralPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | {
            __typename?: "CaptureAdditionalFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig";
              firstVisit: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | {
            __typename?: "CaptureFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureFriendDetailsActiveStepConfig";
              rewards?: Array<{
                __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
                title: string;
                body: string;
              }> | null;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "ContinueToClientSiteActiveStep";
            type: string;
            config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | {
            __typename?: "IneligibleForVoucherActiveStep";
            type: string;
            config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | {
            __typename?: "ReferralLimitReachedActiveStep";
            type: string;
            config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | {
            __typename?: "SuccessfulFriendRegistrationActiveStep";
            type: string;
            config: {
              __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
              showSecondaryButton: boolean;
              buttons: Array<
                | {
                    __typename?: "BasicButtonContent";
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                  }
                | {
                    __typename?: "TileButtonContent";
                    heading: string;
                    description: string;
                    actionId: string;
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                    image: { __typename?: "ImageContent"; altText: string; url: string };
                  }
              >;
            };
          };
    };
  } | null;
};

export type LoginViaIdentifierMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  identifier: Scalars["String"]["input"];
}>;

export type LoginViaIdentifierMutation = {
  __typename?: "Mutation";
  loginViaIdentifier?: {
    __typename?: "LoginViaIdentifierPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | {
            __typename?: "CaptureAdditionalFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig";
              firstVisit: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | {
            __typename?: "CaptureFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureFriendDetailsActiveStepConfig";
              rewards?: Array<{
                __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
                title: string;
                body: string;
              }> | null;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "ContinueToClientSiteActiveStep";
            type: string;
            config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | {
            __typename?: "IneligibleForVoucherActiveStep";
            type: string;
            config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | {
            __typename?: "ReferralLimitReachedActiveStep";
            type: string;
            config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | {
            __typename?: "SuccessfulFriendRegistrationActiveStep";
            type: string;
            config: {
              __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
              showSecondaryButton: boolean;
              buttons: Array<
                | {
                    __typename?: "BasicButtonContent";
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                  }
                | {
                    __typename?: "TileButtonContent";
                    heading: string;
                    description: string;
                    actionId: string;
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                    image: { __typename?: "ImageContent"; altText: string; url: string };
                  }
              >;
            };
          };
    };
  } | null;
};

export type PartiallyRegisterFriendMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type PartiallyRegisterFriendMutation = {
  __typename?: "Mutation";
  partiallyRegisterFriend?: {
    __typename?: "PartiallyRegisterFriendPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | {
            __typename?: "CaptureAdditionalFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig";
              firstVisit: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | {
            __typename?: "CaptureFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureFriendDetailsActiveStepConfig";
              rewards?: Array<{
                __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
                title: string;
                body: string;
              }> | null;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "ContinueToClientSiteActiveStep";
            type: string;
            config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | {
            __typename?: "IneligibleForVoucherActiveStep";
            type: string;
            config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | {
            __typename?: "ReferralLimitReachedActiveStep";
            type: string;
            config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | {
            __typename?: "SuccessfulFriendRegistrationActiveStep";
            type: string;
            config: {
              __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
              showSecondaryButton: boolean;
              buttons: Array<
                | {
                    __typename?: "BasicButtonContent";
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                  }
                | {
                    __typename?: "TileButtonContent";
                    heading: string;
                    description: string;
                    actionId: string;
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                    image: { __typename?: "ImageContent"; altText: string; url: string };
                  }
              >;
            };
          };
    };
  } | null;
};

export type RegisterFriendMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  customFields?: InputMaybe<Array<CustomFieldInput> | CustomFieldInput>;
}>;

export type RegisterFriendMutation = {
  __typename?: "Mutation";
  registerFriend?: {
    __typename?: "RegisterFriendPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | {
            __typename?: "CaptureAdditionalFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig";
              firstVisit: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | {
            __typename?: "CaptureFriendDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureFriendDetailsActiveStepConfig";
              rewards?: Array<{
                __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
                title: string;
                body: string;
              }> | null;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "ContinueToClientSiteActiveStep";
            type: string;
            config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | {
            __typename?: "IneligibleForVoucherActiveStep";
            type: string;
            config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | {
            __typename?: "ReferralLimitReachedActiveStep";
            type: string;
            config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
          }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | {
            __typename?: "SuccessfulFriendRegistrationActiveStep";
            type: string;
            config: {
              __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
              showSecondaryButton: boolean;
              buttons: Array<
                | {
                    __typename?: "BasicButtonContent";
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                  }
                | {
                    __typename?: "TileButtonContent";
                    heading: string;
                    description: string;
                    actionId: string;
                    buttonClass?: string | null;
                    text: string;
                    url: string;
                    image: { __typename?: "ImageContent"; altText: string; url: string };
                  }
              >;
            };
          };
    };
  } | null;
};

export type FriendJourneyGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FriendJourneyGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
      | {
          __typename?: "CaptureAdditionalFriendDetailsActiveStep";
          type: string;
          config: {
            __typename?: "CaptureAdditionalFriendDetailsActiveStepConfig";
            firstVisit: boolean;
          };
          validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
        }
      | {
          __typename?: "CaptureFriendDetailsActiveStep";
          type: string;
          config: {
            __typename?: "CaptureFriendDetailsActiveStepConfig";
            rewards?: Array<{
              __typename?: "CaptureFriendDetailsActiveStepConfigRewardDetail";
              title: string;
              body: string;
            }> | null;
          };
          validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
        }
      | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
      | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | {
          __typename?: "ContinueToClientSiteActiveStep";
          type: string;
          config: { __typename?: "ContinueToClientSiteActiveStepConfig"; destinationUrl: string };
        }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | {
          __typename?: "IneligibleForVoucherActiveStep";
          type: string;
          config: { __typename?: "IneligibleForVoucherActiveStepConfig"; destinationUrl: string };
        }
      | { __typename?: "LookupUserActiveStep"; type: string }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | { __typename?: "PartnerDashboardActiveStep"; type: string }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | { __typename?: "RedeemRewardActiveStep"; type: string }
      | {
          __typename?: "ReferralLimitReachedActiveStep";
          type: string;
          config: { __typename?: "ReferralLimitReachedActiveStepConfig"; destinationUrl: string };
        }
      | { __typename?: "ReferrerDashboardActiveStep"; type: string }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "SplashPageActiveStep"; type: string }
      | {
          __typename?: "SuccessfulFriendRegistrationActiveStep";
          type: string;
          config: {
            __typename?: "SuccessfulFriendRegistrationActiveStepConfig";
            showSecondaryButton: boolean;
            buttons: Array<
              | {
                  __typename?: "BasicButtonContent";
                  buttonClass?: string | null;
                  text: string;
                  url: string;
                }
              | {
                  __typename?: "TileButtonContent";
                  heading: string;
                  description: string;
                  actionId: string;
                  buttonClass?: string | null;
                  text: string;
                  url: string;
                  image: { __typename?: "ImageContent"; altText: string; url: string };
                }
            >;
          };
        };
  };
};

export type LookupUserJourneyGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type LookupUserJourneyGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    localisation: {
      __typename?: "Localisation";
      date: {
        __typename?: "Date";
        dayNames: Array<string>;
        abbreviatedDayNames: Array<string>;
        monthNames: Array<string>;
        abbreviatedMonthNames: Array<string>;
        shortFormat: string;
        longFormat: string;
        monthOnlyFormat: string;
      };
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
      | { __typename?: "CaptureFriendDetailsStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
      | { __typename?: "ClickToRedirectFriendStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
      | { __typename?: "FriendRewardClaimedStep"; type: string }
      | { __typename?: "FulfilmentExpiredStep"; type: string }
      | { __typename?: "IneligibleForVoucherStep"; type: string }
      | {
          __typename?: "LookupUserStep";
          type: string;
          content: {
            __typename?: "LookupUserStepContent";
            body: string;
            title: string;
            form: {
              __typename?: "LookupUserFormContent";
              submitError: string;
              button: { __typename?: "ButtonContent"; text: string };
              inputs: {
                __typename?: "LookupUserInputsContent";
                accessCode: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
              };
            };
            image: { __typename?: "ImageContent"; altText: string; url: string };
          };
        }
      | { __typename?: "PageDisabledStep"; type: string }
      | { __typename?: "PartnerDashboardStep"; type: string }
      | { __typename?: "PartnerPageDisabledStep"; type: string }
      | { __typename?: "RedeemRewardStep"; type: string }
      | { __typename?: "ReferralLimitReachedStep"; type: string }
      | { __typename?: "ReferrerDashboardStep"; type: string }
      | { __typename?: "RewardEmailedReturningStep"; type: string }
      | { __typename?: "RewardEmailedStep"; type: string }
      | { __typename?: "SeamlessLoginFailureStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionStep"; type: string }
      | { __typename?: "SplashPageStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
    >;
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
  };
};

export type LookupUserViaCodeMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  code: Scalars["String"]["input"];
}>;

export type LookupUserViaCodeMutation = {
  __typename?: "Mutation";
  lookupUserViaCode?: {
    __typename?: "LookupUserViaCodePayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | {
            __typename?: "LookupUserActiveStep";
            type: string;
            config: {
              __typename?: "LookupUserActiveStepConfig";
              redirectToUrl?: string | null;
              showInvalidCodeMessage: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type LookupUserJourneyGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LookupUserJourneyGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
      | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
      | {
          __typename?: "LookupUserActiveStep";
          type: string;
          config: {
            __typename?: "LookupUserActiveStepConfig";
            redirectToUrl?: string | null;
            showInvalidCodeMessage: boolean;
          };
          validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
        }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | { __typename?: "PartnerDashboardActiveStep"; type: string }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | { __typename?: "RedeemRewardActiveStep"; type: string }
      | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
      | { __typename?: "ReferrerDashboardActiveStep"; type: string }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "SplashPageActiveStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
  };
};

export type PartnerJourneyGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type PartnerJourneyGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    localisation: {
      __typename?: "Localisation";
      date: {
        __typename?: "Date";
        dayNames: Array<string>;
        abbreviatedDayNames: Array<string>;
        monthNames: Array<string>;
        abbreviatedMonthNames: Array<string>;
        shortFormat: string;
        longFormat: string;
        monthOnlyFormat: string;
      };
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
      | { __typename?: "CaptureFriendDetailsStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
      | { __typename?: "ClickToRedirectFriendStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
      | { __typename?: "FriendRewardClaimedStep"; type: string }
      | { __typename?: "FulfilmentExpiredStep"; type: string }
      | { __typename?: "IneligibleForVoucherStep"; type: string }
      | { __typename?: "LookupUserStep"; type: string }
      | { __typename?: "PageDisabledStep"; type: string }
      | {
          __typename?: "PartnerDashboardStep";
          type: string;
          content: {
            __typename?: "PartnerDashboardStepContent";
            title: string;
            referralStats: {
              __typename?: "PartnerDashboardReferralStatsContent";
              title: string;
              friendVisitCount: string;
              completeReferralCount: string;
              friendRegistrationCount: string;
              cancelledReferralCount: string;
              pendingReferralCount: string;
            };
            partnerOffer: {
              __typename?: "PartnerDashboardOfferContent";
              title: string;
              heading: string;
              body: string;
              image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
            };
            audienceOffer: {
              __typename?: "PartnerDashboardOfferContent";
              title: string;
              heading: string;
              body: string;
              image?: { __typename?: "ImageContent"; url: string; altText: string } | null;
            };
            sharingTools: {
              __typename?: "PartnerDashboardSharingToolsContent";
              title: string;
              directLink: {
                __typename?: "PartnerDashboardSharingToolsFormContent";
                label: string;
                copyButtonText: string;
                copySuccessNotification: string;
                shareMessage: string;
                nativeSharing: {
                  __typename?: "PartnerDashboardSharingToolsNativeSharingContent";
                  buttonText: string;
                  title: string;
                };
              };
              shareCode: {
                __typename?: "PartnerDashboardSharingToolsFormContent";
                label: string;
                copyButtonText: string;
                copySuccessNotification: string;
                shareMessage: string;
                nativeSharing: {
                  __typename?: "PartnerDashboardSharingToolsNativeSharingContent";
                  buttonText: string;
                  title: string;
                };
              };
            };
            usefulLinks: {
              __typename?: "PartnerDashboardUsefulLinksContent";
              title: string;
              buttons: Array<{ __typename?: "LinkContent"; text: string; url: string }>;
            };
            performance: {
              __typename?: "PartnerDashboardPerformanceContent";
              title: string;
              table: {
                __typename?: "PartnerDashboardPerformanceTableContent";
                total: string;
                downloadButtonText: string;
                headers: {
                  __typename?: "PartnerDashboardPerformanceTableHeadersContent";
                  month: string;
                  visits: string;
                  created: string;
                  confirmed: string;
                  cancelled: string;
                  rewardsConfirmed: string;
                };
                tooltips: {
                  __typename?: "PartnerDashboardPerformanceTableTooltipsContent";
                  visits: string;
                  created: string;
                  confirmed: string;
                  cancelled: string;
                };
              };
            };
            rules: { __typename?: "PartnerDashboardRulesContent"; title: string; body: string };
          };
          config: {
            __typename?: "PartnerDashboardStepConfig";
            showReferralStats: boolean;
            referralStats: Array<string>;
            partnerOffer: {
              __typename?: "PartnerDashboardStepConfigOffer";
              displayRewards: boolean;
            };
            audienceOffer: {
              __typename?: "PartnerDashboardStepConfigOffer";
              displayRewards: boolean;
            };
            sharingTools: {
              __typename?: "PartnerDashboardStepConfigSharingTools";
              displayDirectLink: boolean;
              displayShareCode: boolean;
            };
          };
        }
      | {
          __typename?: "PartnerPageDisabledStep";
          type: string;
          content: { __typename?: "PageDisabledStepContent"; title: string; body: string };
        }
      | { __typename?: "RedeemRewardStep"; type: string }
      | { __typename?: "ReferralLimitReachedStep"; type: string }
      | { __typename?: "ReferrerDashboardStep"; type: string }
      | { __typename?: "RewardEmailedReturningStep"; type: string }
      | { __typename?: "RewardEmailedStep"; type: string }
      | { __typename?: "SeamlessLoginFailureStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionStep"; type: string }
      | { __typename?: "SplashPageStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
    >;
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
  };
};

export type PartnerLoginViaAuthTokenMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  authToken: Scalars["String"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PartnerLoginViaAuthTokenMutation = {
  __typename?: "Mutation";
  partnerLoginViaAuthToken?: {
    __typename?: "PartnerLoginViaAuthTokenPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      contentReplacements: any;
      locale?: string | null;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | {
            __typename?: "PartnerDashboardActiveStep";
            type: string;
            config: {
              __typename?: "PartnerDashboardActiveStepConfig";
              referralStats: {
                __typename?: "PartnerDashboardActiveStepConfigReferralStats";
                friendVisitCount: number;
                completeReferralCount: number;
                friendRegistrationCount: number;
                cancelledReferralCount: number;
                pendingReferralCount: number;
              };
              sharingUrls: {
                __typename?: "PartnerDashboardActiveStepConfigSharingUrls";
                directLink: string;
                shareCode: string;
              };
              performance: {
                __typename?: "PartnerDashboardActiveStepConfigPerformance";
                total: {
                  __typename?: "PartnerDashboardActiveStepConfigPerformanceTotal";
                  visits: number;
                  created: number;
                  confirmed: number;
                  cancelled: number;
                  pending: number;
                  rewardsConfirmed: { __typename?: "Money"; displayString: string };
                };
                details: Array<{
                  __typename?: "PartnerDashboardActiveStepConfigPerformanceDetail";
                  date: any;
                  visits: number;
                  created: number;
                  confirmed: number;
                  cancelled: number;
                  rewardsConfirmed: { __typename?: "Money"; displayString: string };
                }>;
              };
              rewards: {
                __typename?: "PartnerDashboardActiveStepConfigRewards";
                partnerRewards: Array<{
                  __typename?: "PartnerDashboardActiveStepConfigRewardDetail";
                  title: string;
                  body: string;
                }>;
                audienceRewards: Array<{
                  __typename?: "PartnerDashboardActiveStepConfigRewardDetail";
                  title: string;
                  body: string;
                }>;
              };
            };
          }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type PartnerJourneyGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PartnerJourneyGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
      | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
      | { __typename?: "LookupUserActiveStep"; type: string }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | {
          __typename?: "PartnerDashboardActiveStep";
          type: string;
          config: {
            __typename?: "PartnerDashboardActiveStepConfig";
            referralStats: {
              __typename?: "PartnerDashboardActiveStepConfigReferralStats";
              friendVisitCount: number;
              completeReferralCount: number;
              friendRegistrationCount: number;
              cancelledReferralCount: number;
              pendingReferralCount: number;
            };
            sharingUrls: {
              __typename?: "PartnerDashboardActiveStepConfigSharingUrls";
              directLink: string;
              shareCode: string;
            };
            performance: {
              __typename?: "PartnerDashboardActiveStepConfigPerformance";
              total: {
                __typename?: "PartnerDashboardActiveStepConfigPerformanceTotal";
                visits: number;
                created: number;
                confirmed: number;
                cancelled: number;
                pending: number;
                rewardsConfirmed: { __typename?: "Money"; displayString: string };
              };
              details: Array<{
                __typename?: "PartnerDashboardActiveStepConfigPerformanceDetail";
                date: any;
                visits: number;
                created: number;
                confirmed: number;
                cancelled: number;
                rewardsConfirmed: { __typename?: "Money"; displayString: string };
              }>;
            };
            rewards: {
              __typename?: "PartnerDashboardActiveStepConfigRewards";
              partnerRewards: Array<{
                __typename?: "PartnerDashboardActiveStepConfigRewardDetail";
                title: string;
                body: string;
              }>;
              audienceRewards: Array<{
                __typename?: "PartnerDashboardActiveStepConfigRewardDetail";
                title: string;
                body: string;
              }>;
            };
          };
        }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | { __typename?: "RedeemRewardActiveStep"; type: string }
      | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
      | { __typename?: "ReferrerDashboardActiveStep"; type: string }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "SplashPageActiveStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
  };
};

export type RedirectFriendToClientJourneyGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type RedirectFriendToClientJourneyGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    programType: string;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
      | { __typename?: "CaptureFriendDetailsStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
      | {
          __typename?: "ClickToRedirectFriendStep";
          type: string;
          optionalContent?: {
            __typename?: "ClickToRedirectFriendStepContent";
            title: string;
            body: string;
            additionalFinePrint?: string | null;
            image: { __typename?: "ImageContent"; url: string; altText: string };
            form: {
              __typename?: "ButtonOnlyFormContent";
              caption: string;
              finePrint: string;
              button: { __typename?: "ButtonContent"; text: string };
            };
          } | null;
        }
      | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
      | { __typename?: "FriendRewardClaimedStep"; type: string }
      | { __typename?: "FulfilmentExpiredStep"; type: string }
      | { __typename?: "IneligibleForVoucherStep"; type: string }
      | { __typename?: "LookupUserStep"; type: string }
      | { __typename?: "PageDisabledStep"; type: string }
      | { __typename?: "PartnerDashboardStep"; type: string }
      | { __typename?: "PartnerPageDisabledStep"; type: string }
      | { __typename?: "RedeemRewardStep"; type: string }
      | { __typename?: "ReferralLimitReachedStep"; type: string }
      | { __typename?: "ReferrerDashboardStep"; type: string }
      | { __typename?: "RewardEmailedReturningStep"; type: string }
      | { __typename?: "RewardEmailedStep"; type: string }
      | { __typename?: "SeamlessLoginFailureStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionStep"; type: string }
      | { __typename?: "SplashPageStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
    >;
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
  };
};

export type RegisterFriendForRedirectionMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type RegisterFriendForRedirectionMutation = {
  __typename?: "Mutation";
  registerFriendForRedirection?: {
    __typename?: "RegisterFriendForRedirectionPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | {
            __typename?: "AutomaticallyRedirectFriendActiveStep";
            type: string;
            config: {
              __typename?: "AutomaticallyRedirectFriendActiveStepConfig";
              destinationUrl?: string | null;
              requiresFriendRegistration: boolean;
            };
          }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | {
            __typename?: "ClickToRedirectFriendActiveStep";
            type: string;
            config: {
              __typename?: "ClickToRedirectFriendActiveStepConfig";
              destinationUrl?: string | null;
              requiresFriendRegistration: boolean;
            };
          }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | { __typename?: "ReferrerDashboardActiveStep"; type: string }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "SplashPageActiveStep"; type: string }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type RedirectFriendToClientJourneyGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RedirectFriendToClientJourneyGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | {
          __typename?: "AutomaticallyRedirectFriendActiveStep";
          type: string;
          config: {
            __typename?: "AutomaticallyRedirectFriendActiveStepConfig";
            destinationUrl?: string | null;
            requiresFriendRegistration: boolean;
          };
        }
      | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
      | {
          __typename?: "ClickToRedirectFriendActiveStep";
          type: string;
          config: {
            __typename?: "ClickToRedirectFriendActiveStepConfig";
            destinationUrl?: string | null;
            requiresFriendRegistration: boolean;
          };
        }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
      | { __typename?: "LookupUserActiveStep"; type: string }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | { __typename?: "PartnerDashboardActiveStep"; type: string }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | { __typename?: "RedeemRewardActiveStep"; type: string }
      | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
      | { __typename?: "ReferrerDashboardActiveStep"; type: string }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "SplashPageActiveStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
  };
};

export type ReferrerJourneyGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ReferrerJourneyGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    localisation: {
      __typename?: "Localisation";
      date: {
        __typename?: "Date";
        dayNames: Array<string>;
        abbreviatedDayNames: Array<string>;
        monthNames: Array<string>;
        abbreviatedMonthNames: Array<string>;
        shortFormat: string;
        longFormat: string;
      };
      sharing: {
        __typename?: "SharingLocalisation";
        tabs: {
          __typename?: "SharingTabsLocalisation";
          socialButtonText: string;
          emailButtonText: string;
        };
        email: {
          __typename?: "SharingEmailLocalisation";
          messageLabelText: string;
          emailClientLabelText: string;
        };
      };
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
      | { __typename?: "CaptureFriendDetailsStep"; type: string }
      | {
          __typename?: "CaptureReferrerDetailsStep";
          type: string;
          optionalConfig?: {
            __typename?: "CaptureReferrerDetailsStepConfig";
            layout: string;
            inputs: {
              __typename?: "CaptureReferrerDetailsStepConfigInputs";
              fullName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              firstName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              lastName: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              email: { __typename?: "TextInputConfig"; show: boolean; pattern: string };
              marketingOptIn: {
                __typename?: "MarketingOptInInputConfig";
                show: boolean;
                required: boolean;
              };
              termsOptIn: { __typename?: "TermsOptInInputConfig"; show: boolean };
              customFields: Array<
                | {
                    __typename?: "CustomFieldInputConfig";
                    pattern: string;
                    key: string;
                    position: number;
                    inputFieldType: string;
                  }
                | {
                    __typename?: "CustomFieldListInputConfig";
                    items: Array<string>;
                    key: string;
                    position: number;
                    inputFieldType: string;
                  }
              >;
            };
            button: { __typename?: "CaptureReferrerDetailsStepConfigButton"; show: boolean };
            offerDetails: {
              __typename?: "OfferDetailsConfig";
              showStepIcons: boolean;
              revealOnLoad: boolean;
            };
          } | null;
          optionalContent?: {
            __typename?: "CaptureReferrerDetailsStepContent";
            title: string;
            signInLinkEmailedMessage: string;
            eligibilityCheckRejectionMessage: string;
            additionalFinePrint?: string | null;
            image?: {
              __typename?: "ResponsiveImage";
              url: string;
              altText: string;
              mobile?: { __typename?: "ImageContent"; url: string; altText: string } | null;
            } | null;
            offerDetails: {
              __typename?: "OfferDetailsContent";
              collapsedTitle: string;
              expandedTitle: string;
              steps: Array<string>;
              footer: string;
            };
            form: {
              __typename?: "FormContent";
              caption: string;
              submitError: string;
              finePrint: string;
              button: { __typename?: "ButtonContent"; text: string };
              inputs: {
                __typename?: "InputsContent";
                fullName: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                firstName: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                lastName: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                email: {
                  __typename?: "TextInputContent";
                  label: string;
                  placeholder: string;
                  tooltip?: string | null;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                marketingOptIn: {
                  __typename?: "CheckboxInputContent";
                  label: string;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                termsOptIn: {
                  __typename?: "CheckboxInputContent";
                  label: string;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                customFields: Array<{
                  __typename?: "CustomFieldInputContent";
                  key: string;
                  content: {
                    __typename?: "TextInputContent";
                    label: string;
                    placeholder: string;
                    items?: Array<string> | null;
                    validationErrors: Array<{
                      __typename?: "ValidationErrorContent";
                      key: string;
                      value: string;
                    }>;
                  };
                }>;
              };
            };
          } | null;
        }
      | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
      | { __typename?: "ClickToRedirectFriendStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
      | { __typename?: "FriendRewardClaimedStep"; type: string }
      | { __typename?: "FulfilmentExpiredStep"; type: string }
      | { __typename?: "IneligibleForVoucherStep"; type: string }
      | { __typename?: "LookupUserStep"; type: string }
      | {
          __typename?: "PageDisabledStep";
          type: string;
          content: { __typename?: "PageDisabledStepContent"; title: string; body: string };
        }
      | { __typename?: "PartnerDashboardStep"; type: string }
      | { __typename?: "PartnerPageDisabledStep"; type: string }
      | { __typename?: "RedeemRewardStep"; type: string }
      | { __typename?: "ReferralLimitReachedStep"; type: string }
      | {
          __typename?: "ReferrerDashboardStep";
          type: string;
          content: {
            __typename?: "ReferrerDashboardStepContent";
            title: string;
            finePrint: string;
            shareMessage: string;
            logout: { __typename?: "LogoutContent"; message: string; linkText: string };
            image?: {
              __typename?: "ResponsiveImage";
              url: string;
              altText: string;
              mobile?: { __typename?: "ImageContent"; url: string; altText: string } | null;
            } | null;
            offerDetails: {
              __typename?: "OfferDetailsContent";
              collapsedTitle: string;
              expandedTitle: string;
              steps: Array<string>;
              footer: string;
            };
            referralStatus?: {
              __typename?: "ReferralStatusContent";
              stats: {
                __typename?: "ReferralStatusStatsContent";
                friendVisitCount: string;
                completeReferralCount: string;
                friendRegistrationCount: string;
                cancelledReferralCount: string;
                pendingReferralCount: string;
              };
              pill: {
                __typename?: "ReferralStatusPillContent";
                expanded: string;
                collapsed: string;
              };
              table: {
                __typename?: "ReferralStatusTableContent";
                pending: {
                  __typename?: "ReferralStatusTableSectionContent";
                  title: string;
                  tooltip: string;
                };
                complete: {
                  __typename?: "ReferralStatusTableSectionContent";
                  title: string;
                  tooltip: string;
                };
                headers: {
                  __typename?: "ReferralStatusTableHeadersContent";
                  name: string;
                  date: string;
                  status: string;
                };
                statuses: {
                  __typename?: "ReferralStatusTableStatusesContent";
                  pending: string;
                  complete: string;
                };
              };
            } | null;
            rewardCapping: {
              __typename?: "RewardCappingContent";
              warningTitle: string;
              monetaryWarningMessage: string;
              volumeWarningMessage: string;
            };
            manualSharing: {
              __typename?: "ManualSharingContent";
              title: string;
              copyButtonText: string;
              copySuccessNotification: string;
            };
            nativeSharing: {
              __typename?: "NativeSharingContent";
              title: string;
              buttonText: string;
            };
            shareCode: {
              __typename?: "ReferrerDashboardShareCodeContent";
              copyButtonText: string;
              copySuccessNotification: string;
              label: string;
              shareMessage: string;
              nativeSharing: {
                __typename?: "NativeSharingContent";
                title: string;
                buttonText: string;
              };
            };
          };
          config: {
            __typename?: "ReferrerDashboardStepConfig";
            showReferralStatuses: boolean;
            showPendingReferrals: boolean;
            showReferralStats: boolean;
            referralStats: Array<string>;
            shareNetworks: Array<string>;
            layout: string;
            showDirectLink: boolean;
            showShareCode: boolean;
            offerDetails: { __typename?: "OfferDetailsConfig"; showStepIcons: boolean };
          };
          style?: {
            __typename?: "ReferrerDashboardStepStyle";
            shareButtons?: {
              __typename?: "ShareButtonsStyle";
              css?: any | null;
              _hover?: { __typename?: "HoverStyle"; css?: any | null } | null;
              icon?: {
                __typename?: "ShareButtonsIconStyle";
                css?: any | null;
                _hover?: { __typename?: "HoverStyle"; css?: any | null } | null;
              } | null;
            } | null;
          } | null;
        }
      | { __typename?: "RewardEmailedReturningStep"; type: string }
      | { __typename?: "RewardEmailedStep"; type: string }
      | {
          __typename?: "SeamlessLoginFailureStep";
          type: string;
          content: { __typename?: "SeamlessLoginFailureStepContent"; title: string; body: string };
        }
      | { __typename?: "SelectFriendRewardOptionStep"; type: string }
      | {
          __typename?: "SplashPageStep";
          type: string;
          optionalConfig?: {
            __typename?: "SplashPageStepConfig";
            layout: string;
            inputs: {
              __typename?: "SplashPageStepConfigInputs";
              marketingOptIn: {
                __typename?: "MarketingOptInInputConfig";
                show: boolean;
                required: boolean;
              };
              termsOptIn: { __typename?: "TermsOptInInputConfig"; show: boolean };
            };
            offerDetails: {
              __typename?: "OfferDetailsConfig";
              showStepIcons: boolean;
              revealOnLoad: boolean;
            };
          } | null;
          optionalContent?: {
            __typename?: "SplashPageStepContent";
            title: string;
            eligibilityCheckRejectionMessage: string;
            additionalFinePrint?: string | null;
            image?: { __typename?: "ResponsiveImage"; url: string; altText: string } | null;
            offerDetails: {
              __typename?: "OfferDetailsContent";
              collapsedTitle: string;
              expandedTitle: string;
              steps: Array<string>;
              footer: string;
            };
            form: {
              __typename?: "FormContent";
              caption: string;
              submitError: string;
              finePrint: string;
              button: { __typename?: "ButtonContent"; text: string };
              inputs: {
                __typename?: "InputsContent";
                marketingOptIn: {
                  __typename?: "CheckboxInputContent";
                  label: string;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
                termsOptIn: {
                  __typename?: "CheckboxInputContent";
                  label: string;
                  validationErrors: Array<{
                    __typename?: "ValidationErrorContent";
                    key: string;
                    value: string;
                  }>;
                };
              };
            };
          } | null;
        }
      | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
    >;
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
  };
};

export type LoginViaAuthTokenMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  authToken: Scalars["String"]["input"];
}>;

export type LoginViaAuthTokenMutation = {
  __typename?: "Mutation";
  loginViaAuthToken?: {
    __typename?: "LoginViaAuthTokenPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      contentReplacements: any;
      locale?: string | null;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | {
            __typename?: "CaptureReferrerDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureReferrerDetailsActiveStepConfig";
              showSignInLinkEmailedMessage: boolean;
              showEligibilityCheckRejectionMessage: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | {
            __typename?: "ReferrerDashboardActiveStep";
            type: string;
            config: {
              __typename?: "ReferrerDashboardActiveStepConfig";
              canLogout: boolean;
              manualSharingShortUrl: string;
              nativeSharingShortUrl: string;
              emailSharingShortUrl: string;
              rewardCappingType: string;
              showRewardLimitWarningMessage: boolean;
              shareNetworkShortUrls: {
                __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
                facebook: string;
                twitter: string;
                pinterest: string;
                reddit: string;
                whatsapp: string;
                facebookMessenger: string;
                line: string;
                vkontakte: string;
                zalo: string;
                viber: string;
                telegram: string;
              };
              referralStatus: {
                __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
                friendVisitCount: number;
                completeReferralCount: number;
                friendRegistrationCount: number;
                cancelledReferralCount: number;
                pendingReferralCount: number;
                completeReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
                pendingReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
              };
            };
          }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "SplashPageActiveStep";
            type: string;
            config: {
              __typename?: "SplashPageActiveStepConfig";
              showEligibilityCheckRejectionMessage: boolean;
            };
          }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type LoginViaSeamlessMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  signedKeys: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  signature: Scalars["String"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  customFieldValue?: InputMaybe<Scalars["String"]["input"]>;
  customFields?: InputMaybe<Array<CustomFieldInput> | CustomFieldInput>;
  fullName: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  clientData?: InputMaybe<Scalars["JSON"]["input"]>;
  splashPageSubmit?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type LoginViaSeamlessMutation = {
  __typename?: "Mutation";
  loginViaSeamless?: {
    __typename?: "LoginViaSeamlessPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      contentReplacements: any;
      locale?: string | null;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | {
            __typename?: "CaptureReferrerDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureReferrerDetailsActiveStepConfig";
              showSignInLinkEmailedMessage: boolean;
              showEligibilityCheckRejectionMessage: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | {
            __typename?: "ReferrerDashboardActiveStep";
            type: string;
            config: {
              __typename?: "ReferrerDashboardActiveStepConfig";
              canLogout: boolean;
              manualSharingShortUrl: string;
              nativeSharingShortUrl: string;
              emailSharingShortUrl: string;
              rewardCappingType: string;
              showRewardLimitWarningMessage: boolean;
              shareNetworkShortUrls: {
                __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
                facebook: string;
                twitter: string;
                pinterest: string;
                reddit: string;
                whatsapp: string;
                facebookMessenger: string;
                line: string;
                vkontakte: string;
                zalo: string;
                viber: string;
                telegram: string;
              };
              referralStatus: {
                __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
                friendVisitCount: number;
                completeReferralCount: number;
                friendRegistrationCount: number;
                cancelledReferralCount: number;
                pendingReferralCount: number;
                completeReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
                pendingReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
              };
            };
          }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "SplashPageActiveStep";
            type: string;
            config: {
              __typename?: "SplashPageActiveStepConfig";
              showEligibilityCheckRejectionMessage: boolean;
            };
          }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type LogoutReferrerMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
}>;

export type LogoutReferrerMutation = {
  __typename?: "Mutation";
  logoutReferrer?: {
    __typename?: "LogoutReferrerPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | {
            __typename?: "CaptureReferrerDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureReferrerDetailsActiveStepConfig";
              showSignInLinkEmailedMessage: boolean;
              showEligibilityCheckRejectionMessage: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | {
            __typename?: "ReferrerDashboardActiveStep";
            type: string;
            config: {
              __typename?: "ReferrerDashboardActiveStepConfig";
              canLogout: boolean;
              manualSharingShortUrl: string;
              nativeSharingShortUrl: string;
              emailSharingShortUrl: string;
              rewardCappingType: string;
              showRewardLimitWarningMessage: boolean;
              shareNetworkShortUrls: {
                __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
                facebook: string;
                twitter: string;
                pinterest: string;
                reddit: string;
                whatsapp: string;
                facebookMessenger: string;
                line: string;
                vkontakte: string;
                zalo: string;
                viber: string;
                telegram: string;
              };
              referralStatus: {
                __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
                friendVisitCount: number;
                completeReferralCount: number;
                friendRegistrationCount: number;
                cancelledReferralCount: number;
                pendingReferralCount: number;
                completeReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
                pendingReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
              };
            };
          }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "SplashPageActiveStep";
            type: string;
            config: {
              __typename?: "SplashPageActiveStepConfig";
              showEligibilityCheckRejectionMessage: boolean;
            };
          }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type RegisterReferrerMutationVariables = Exact<{
  flowId: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Scalars["String"]["input"]>;
  fullName?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  marketingOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  customFields?: InputMaybe<Array<CustomFieldInput> | CustomFieldInput>;
  utmParams?: InputMaybe<UtmParamsInput>;
}>;

export type RegisterReferrerMutation = {
  __typename?: "Mutation";
  registerReferrer?: {
    __typename?: "RegisterReferrerPayload";
    ok: boolean;
    errors: Array<string>;
    session: {
      __typename?: "Session";
      locale?: string | null;
      contentReplacements: any;
      activeStep:
        | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
        | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
        | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
        | {
            __typename?: "CaptureReferrerDetailsActiveStep";
            type: string;
            config: {
              __typename?: "CaptureReferrerDetailsActiveStepConfig";
              showSignInLinkEmailedMessage: boolean;
              showEligibilityCheckRejectionMessage: boolean;
            };
            validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
          }
        | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
        | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
        | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
        | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
        | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
        | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
        | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
        | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
        | { __typename?: "GenericErrorActiveStep"; type: string }
        | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
        | { __typename?: "LookupUserActiveStep"; type: string }
        | { __typename?: "PageDisabledActiveStep"; type: string }
        | { __typename?: "PartnerDashboardActiveStep"; type: string }
        | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
        | { __typename?: "RedeemRewardActiveStep"; type: string }
        | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
        | {
            __typename?: "ReferrerDashboardActiveStep";
            type: string;
            config: {
              __typename?: "ReferrerDashboardActiveStepConfig";
              canLogout: boolean;
              manualSharingShortUrl: string;
              nativeSharingShortUrl: string;
              emailSharingShortUrl: string;
              rewardCappingType: string;
              showRewardLimitWarningMessage: boolean;
              shareNetworkShortUrls: {
                __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
                facebook: string;
                twitter: string;
                pinterest: string;
                reddit: string;
                whatsapp: string;
                facebookMessenger: string;
                line: string;
                vkontakte: string;
                zalo: string;
                viber: string;
                telegram: string;
              };
              referralStatus: {
                __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
                friendVisitCount: number;
                completeReferralCount: number;
                friendRegistrationCount: number;
                cancelledReferralCount: number;
                pendingReferralCount: number;
                completeReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
                pendingReferrals: Array<{
                  __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                  name: string;
                  date: any;
                  status: string;
                }>;
              };
            };
          }
        | { __typename?: "RewardEmailedActiveStep"; type: string }
        | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
        | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
        | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
        | {
            __typename?: "SplashPageActiveStep";
            type: string;
            config: {
              __typename?: "SplashPageActiveStepConfig";
              showEligibilityCheckRejectionMessage: boolean;
            };
          }
        | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
    };
  } | null;
};

export type ReferrerJourneyGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ReferrerJourneyGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
      | {
          __typename?: "CaptureReferrerDetailsActiveStep";
          type: string;
          config: {
            __typename?: "CaptureReferrerDetailsActiveStepConfig";
            showSignInLinkEmailedMessage: boolean;
            showEligibilityCheckRejectionMessage: boolean;
          };
          validationErrors: Array<{ __typename?: "ValidationError"; key: string; field: string }>;
        }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
      | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
      | { __typename?: "LookupUserActiveStep"; type: string }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | { __typename?: "PartnerDashboardActiveStep"; type: string }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | { __typename?: "RedeemRewardActiveStep"; type: string }
      | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
      | {
          __typename?: "ReferrerDashboardActiveStep";
          type: string;
          config: {
            __typename?: "ReferrerDashboardActiveStepConfig";
            canLogout: boolean;
            manualSharingShortUrl: string;
            nativeSharingShortUrl: string;
            emailSharingShortUrl: string;
            rewardCappingType: string;
            showRewardLimitWarningMessage: boolean;
            shareNetworkShortUrls: {
              __typename?: "ReferrerDashboardActiveStepConfigShareNetworkShortUrls";
              facebook: string;
              twitter: string;
              pinterest: string;
              reddit: string;
              whatsapp: string;
              facebookMessenger: string;
              line: string;
              vkontakte: string;
              zalo: string;
              viber: string;
              telegram: string;
            };
            referralStatus: {
              __typename?: "ReferrerDashboardActiveStepConfigReferralStatus";
              friendVisitCount: number;
              completeReferralCount: number;
              friendRegistrationCount: number;
              cancelledReferralCount: number;
              pendingReferralCount: number;
              completeReferrals: Array<{
                __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                name: string;
                date: any;
                status: string;
              }>;
              pendingReferrals: Array<{
                __typename?: "ReferrerDashboardActiveStepConfigReferralStatusReferral";
                name: string;
                date: any;
                status: string;
              }>;
            };
          };
        }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
      | {
          __typename?: "SplashPageActiveStep";
          type: string;
          config: {
            __typename?: "SplashPageActiveStepConfig";
            showEligibilityCheckRejectionMessage: boolean;
          };
        }
      | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
  };
};

export type RewardRedemptionJourneyGetConfigQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  campaignSlug?: InputMaybe<Scalars["String"]["input"]>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type RewardRedemptionJourneyGetConfigQuery = {
  __typename?: "Query";
  config: {
    __typename?: "Config";
    globalStyles: any;
    programType: string;
    customFonts: Array<{ __typename?: "CustomFont"; family: string; url: string }>;
    styles: {
      __typename?: "Styles";
      font: {
        __typename?: "Font";
        family: string;
        weight: string;
        sizes: {
          __typename?: "FontSizes";
          extraSmall: string;
          small: string;
          medium: string;
          large: string;
          extraLarge: string;
        };
      };
      colors: { __typename?: "Colors"; highlight: string; error: string };
      layout: { __typename?: "Layout"; backgroundColor: string; color: string };
      button: {
        __typename?: "Button";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        primary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
        secondary: {
          __typename?: "ButtonState";
          css?: any | null;
          color: string;
          backgroundColor: string;
          _disabled?: {
            __typename?: "DisabledStyle";
            color: string;
            backgroundColor: string;
          } | null;
        };
      };
      textInput: {
        __typename?: "TextInput";
        css?: any | null;
        height: string;
        fontSize: string;
        borderRadius: string;
        color: string;
        backgroundColor: string;
        label: {
          __typename?: "TextInputLabel";
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _focus: { __typename?: "TextInputState"; css?: any | null; borderColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      form: {
        __typename?: "Form";
        css?: any | null;
        backgroundColor: string;
        captionColor: string;
        borderColor: string;
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      switch: {
        __typename?: "Switch";
        css?: any | null;
        backgroundColor: string;
        label: {
          __typename?: "SwitchLabel";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        _active: { __typename?: "SwitchState"; css?: any | null; backgroundColor: string };
        error: { __typename?: "ErrorStyle"; css?: any | null; color: string };
      };
      link: {
        __typename?: "Link";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontSize: string;
        textDecoration: string;
        _hover: { __typename?: "LinkState"; css?: any | null; color: string };
        _active: { __typename?: "LinkState"; css?: any | null; color: string };
        _visited: { __typename?: "LinkState"; css?: any | null; color: string };
      };
      notification: {
        __typename?: "Notification";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontSize: string;
        successful: { __typename?: "NotificationStyle"; iconColor: string };
      };
      card: {
        __typename?: "Card";
        css?: any | null;
        backgroundColor: string;
        title: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
        description: {
          __typename?: "CardTextElement";
          css?: any | null;
          fontSize: string;
          fontWeight: string;
          color: string;
        };
      };
      title: {
        __typename?: "Title";
        css?: any | null;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        color: string;
      };
      avatar: {
        __typename?: "AvatarStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
      };
      pill: {
        __typename?: "PillStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        fontFamily: string;
      };
      tabSwitcher: {
        __typename?: "TabSwitcherStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        borderRadius: string;
        fontSize: string;
        fontWeight: string;
        tab: {
          __typename?: "TabStyle";
          css?: any | null;
          _active: {
            __typename?: "TabSwitcherActiveStyle";
            css?: any | null;
            backgroundColor: string;
            color: string;
            borderRadius: string;
          };
        };
      };
      strong: {
        __typename?: "StrongStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
      };
      emphasis: {
        __typename?: "EmphasisStyle";
        css?: any | null;
        color: string;
        fontFamily: string;
        fontWeight: string;
        fontStyle: string;
      };
      shareArea: {
        __typename?: "ShareAreaStyle";
        css?: any | null;
        backgroundColor: string;
        color: string;
        fontFamily: string;
        fontSize: string;
        fontWeight: string;
        borderRadius: string;
        height: string;
        _hover: {
          __typename?: "ShareAreaHoverStyle";
          css?: any | null;
          backgroundColor: string;
          color: string;
        };
        manualSharingTitle: {
          __typename?: "ManualSharingTitleStyle";
          fontFamily: string;
          fontSize: string;
          fontWeight: string;
        };
      };
      errorBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      warningBanner: { __typename?: "BannerStyle"; backgroundColor: string; color: string };
      legal: {
        __typename?: "LegalStyle";
        color: string;
        fontFamily: string;
        fontSize: string;
        lineHeight: string;
        opacity: number;
        css?: any | null;
      };
    };
    urls: {
      __typename?: "Urls";
      termsAndConditionsUrl: string;
      privacyPolicyUrl: string;
      faqsUrl: string;
      userLookupUrl: string;
    };
    steps: Array<
      | { __typename?: "AutomaticallyRedirectFriendStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsStep"; type: string }
      | { __typename?: "CaptureFriendDetailsStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorStep"; type: string }
      | { __typename?: "ClickToRedirectFriendStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionStep"; type: string }
      | { __typename?: "ContinueToClientSiteStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedStep"; type: string }
      | { __typename?: "FriendRewardClaimedStep"; type: string }
      | { __typename?: "FulfilmentExpiredStep"; type: string }
      | { __typename?: "IneligibleForVoucherStep"; type: string }
      | { __typename?: "LookupUserStep"; type: string }
      | { __typename?: "PageDisabledStep"; type: string }
      | { __typename?: "PartnerDashboardStep"; type: string }
      | { __typename?: "PartnerPageDisabledStep"; type: string }
      | { __typename?: "RedeemRewardStep"; type: string }
      | { __typename?: "ReferralLimitReachedStep"; type: string }
      | { __typename?: "ReferrerDashboardStep"; type: string }
      | { __typename?: "RewardEmailedReturningStep"; type: string }
      | { __typename?: "RewardEmailedStep"; type: string }
      | { __typename?: "SeamlessLoginFailureStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionStep"; type: string }
      | { __typename?: "SplashPageStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationStep"; type: string }
    >;
  };
};

export type RewardRedemptionJourneyGetSessionQueryVariables = Exact<{
  flow: Scalars["String"]["input"];
  flowId: Scalars["ID"]["input"];
  id: Scalars["ID"]["input"];
  requestedLocale?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RewardRedemptionJourneyGetSessionQuery = {
  __typename?: "Query";
  session: {
    __typename?: "Session";
    contentReplacements: any;
    locale?: string | null;
    activeStep:
      | { __typename?: "AutomaticallyRedirectFriendActiveStep"; type: string }
      | { __typename?: "CaptureAdditionalFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureFriendDetailsActiveStep"; type: string }
      | { __typename?: "CaptureReferrerDetailsActiveStep"; type: string }
      | { __typename?: "ClickToClaimErrorActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionConfirmationActiveStep"; type: string }
      | { __typename?: "ClickToClaimOptionsSelectorActiveStep"; type: string }
      | { __typename?: "ClickToRedirectFriendActiveStep"; type: string }
      | { __typename?: "ConfirmFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "ContinueToClientSiteActiveStep"; type: string }
      | { __typename?: "FriendRewardAlreadyClaimedActiveStep"; type: string }
      | { __typename?: "FriendRewardClaimedActiveStep"; type: string }
      | { __typename?: "FulfilmentExpiredActiveStep"; type: string }
      | { __typename?: "GenericErrorActiveStep"; type: string }
      | { __typename?: "IneligibleForVoucherActiveStep"; type: string }
      | { __typename?: "LookupUserActiveStep"; type: string }
      | { __typename?: "PageDisabledActiveStep"; type: string }
      | { __typename?: "PartnerDashboardActiveStep"; type: string }
      | { __typename?: "PartnerPageDisabledActiveStep"; type: string }
      | {
          __typename?: "RedeemRewardActiveStep";
          type: string;
          config: { __typename?: "RedeemRewardActiveStepConfig"; destinationUrl: string };
        }
      | { __typename?: "ReferralLimitReachedActiveStep"; type: string }
      | { __typename?: "ReferrerDashboardActiveStep"; type: string }
      | { __typename?: "RewardEmailedActiveStep"; type: string }
      | { __typename?: "RewardEmailedReturningActiveStep"; type: string }
      | { __typename?: "SeamlessLoginFailureActiveStep"; type: string }
      | { __typename?: "SelectFriendRewardOptionActiveStep"; type: string }
      | { __typename?: "SplashPageActiveStep"; type: string }
      | { __typename?: "SuccessfulFriendRegistrationActiveStep"; type: string };
  };
};

export type TrackEventMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  campaignSlug: Scalars["String"]["input"];
  event: EventEnum;
  params: Scalars["JSON"]["input"];
  flowId?: InputMaybe<Scalars["ID"]["input"]>;
  utmParams?: InputMaybe<UtmParamsInput>;
}>;

export type TrackEventMutation = {
  __typename?: "Mutation";
  trackEvent?: { __typename?: "TrackEventPayload"; ok: boolean; errors: Array<string> } | null;
};

export const CustomFontsFragmentFragmentDoc = gql`
  fragment CustomFontsFragment on CustomFont {
    family
    url
  }
`;
export const SharedStyleFragmentFragmentDoc = gql`
  fragment SharedStyleFragment on Styles {
    font {
      family
      weight
      sizes {
        extraSmall
        small
        medium
        large
        extraLarge
      }
    }
    colors {
      highlight
      error
    }
    layout {
      backgroundColor
      color
    }
    button {
      css
      fontFamily
      fontSize
      fontWeight
      borderRadius
      height
      primary {
        css
        color
        backgroundColor
        _disabled {
          color
          backgroundColor
        }
      }
      secondary {
        css
        color
        backgroundColor
        _disabled {
          color
          backgroundColor
        }
      }
    }
    textInput {
      css
      height
      fontSize
      borderRadius
      color
      backgroundColor
      label {
        fontSize
        fontWeight
        color
      }
      _focus {
        css
        borderColor
      }
      error {
        css
        color
      }
    }
    form {
      css
      backgroundColor
      captionColor
      borderColor
      error {
        css
        color
      }
    }
    switch {
      css
      backgroundColor
      label {
        css
        fontSize
        fontWeight
        color
      }
      _active {
        css
        backgroundColor
      }
      error {
        css
        color
      }
    }
    link {
      css
      color
      fontFamily
      fontWeight
      fontSize
      textDecoration
      _hover {
        css
        color
      }
      _active {
        css
        color
      }
      _visited {
        css
        color
      }
    }
    notification {
      css
      backgroundColor
      color
      fontSize
      successful {
        iconColor
      }
    }
    card {
      css
      backgroundColor
      title {
        css
        fontSize
        fontWeight
        color
      }
      description {
        css
        fontSize
        fontWeight
        color
      }
    }
    title {
      css
      fontFamily
      fontSize
      fontWeight
      color
    }
    avatar {
      css
      backgroundColor
      color
      borderRadius
    }
    pill {
      css
      backgroundColor
      color
      borderRadius
      fontSize
      fontWeight
      fontFamily
    }
    tabSwitcher {
      css
      backgroundColor
      color
      borderRadius
      fontSize
      fontWeight
      tab {
        css
        _active {
          css
          backgroundColor
          color
          borderRadius
        }
      }
    }
    strong {
      css
      color
      fontFamily
      fontWeight
    }
    emphasis {
      css
      color
      fontFamily
      fontWeight
      fontStyle
    }
    shareArea {
      css
      backgroundColor
      color
      fontFamily
      fontSize
      fontWeight
      borderRadius
      height
      _hover {
        css
        backgroundColor
        color
      }
      manualSharingTitle {
        fontFamily
        fontSize
        fontWeight
      }
    }
    errorBanner {
      backgroundColor
      color
    }
    warningBanner {
      backgroundColor
      color
    }
    legal {
      color
      fontFamily
      fontSize
      lineHeight
      opacity
      css
    }
  }
`;
export const UrlsFragmentFragmentDoc = gql`
  fragment UrlsFragment on Urls {
    termsAndConditionsUrl
    privacyPolicyUrl
    faqsUrl
    userLookupUrl
  }
`;
export const ClickToClaimFriendConfigFragmentFragmentDoc = gql`
  fragment ClickToClaimFriendConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    localisation {
      date {
        dayNames
        abbreviatedDayNames
        monthNames
        abbreviatedMonthNames
        shortFormat
        longFormat
        monthOnlyFormat
      }
    }
    steps {
      type
      ... on SelectFriendRewardOptionStep {
        config {
          introLink {
            show
          }
          rewardTypes {
            id
            rewardOptions {
              key
              position
              title
              body
              containerBackgroundColor
              image {
                url
                altText
              }
              selectButtonText
            }
          }
        }
        content {
          title
          body
          introLink {
            text
            url
          }
          claimRewardError
          expiryInfo
        }
      }
      ... on ConfirmFriendRewardOptionStep {
        config {
          footerLink {
            show
          }
        }
        content {
          title
          confirmButton
          cancelButton
          claimRewardError
          footerLink {
            text
            url
          }
        }
      }
      ... on FriendRewardClaimedStep {
        config {
          footerLink {
            show
          }
        }
        content {
          title
          body
          footerLink {
            text
            url
          }
        }
      }
      ... on FriendRewardAlreadyClaimedStep {
        content {
          title
          body
        }
      }
      ... on FulfilmentExpiredStep {
        config {
          footerLink {
            show
          }
        }
        content {
          title
          body
          footerLink {
            text
            url
          }
        }
      }
    }
    urls {
      ...UrlsFragment
    }
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const ClickToClaimReferrerConfigFragmentFragmentDoc = gql`
  fragment ClickToClaimReferrerConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    localisation {
      date {
        dayNames
        abbreviatedDayNames
        monthNames
        abbreviatedMonthNames
        shortFormat
        longFormat
        monthOnlyFormat
      }
    }
    steps {
      type
      ... on ClickToClaimOptionsSelectorStep {
        config {
          introLink {
            show
          }
          rewardTypes {
            id
            rewardOptions {
              key
              position
              title
              body
              containerBackgroundColor
              image {
                url
                altText
              }
              selectButtonText
            }
          }
        }
        content {
          title
          body
          introLink {
            text
            url
          }
          claimRewardError
          expiryInfo
        }
      }
      ... on ClickToClaimOptionConfirmationStep {
        config {
          footerLink {
            show
          }
        }
        content {
          title
          confirmButton
          cancelButton
          claimRewardError
          footerLink {
            text
            url
          }
        }
      }
      ... on RewardEmailedStep {
        config {
          footerLink {
            show
          }
        }
        content {
          title
          body
          footerLink {
            text
            url
          }
        }
      }
      ... on RewardEmailedReturningStep {
        content {
          title
          body
        }
      }
      ... on FulfilmentExpiredStep {
        config {
          footerLink {
            show
          }
        }
        content {
          title
          body
          footerLink {
            text
            url
          }
        }
      }
    }
    urls {
      ...UrlsFragment
    }
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const FriendJourneyConfigFragmentFragmentDoc = gql`
  fragment FriendJourneyConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    steps {
      type
      ... on CaptureFriendDetailsStep {
        content {
          title
          body
          image {
            url
            altText
            mobile {
              url
              altText
            }
          }
          form {
            caption
            button {
              text
            }
            inputs {
              fullName {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              firstName {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              lastName {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              email {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              marketingOptIn {
                label
                validationErrors {
                  key
                  value
                }
              }
              termsOptIn {
                label
                validationErrors {
                  key
                  value
                }
              }
              customFields {
                key
                content {
                  label
                  placeholder
                  validationErrors {
                    key
                    value
                  }
                  items
                }
              }
            }
            submitError
            finePrint
          }
          additionalFinePrint
          partnerDetails {
            avatarUrl
            bannerUrl
            businessName
            displayName
            offerHeader
            quote
          }
        }
        config {
          partialRegistration
          inputs {
            fullName {
              show
              pattern
            }
            firstName {
              show
              pattern
            }
            lastName {
              show
              pattern
            }
            email {
              show
              pattern
            }
            marketingOptIn {
              show
              required
            }
            termsOptIn {
              show
            }
            customFields {
              key
              position
              inputFieldType
              ... on CustomFieldInputConfig {
                pattern
              }
              ... on CustomFieldListInputConfig {
                items
              }
            }
          }
          button {
            show
          }
          displayPartnerRewards
        }
      }
      ... on CaptureAdditionalFriendDetailsStep {
        optionalContent {
          nextVisit {
            card {
              title
              body
              finePrint
            }
            instructions
            showButtons
          }
          firstVisit {
            card {
              title
              body
              finePrint
            }
            instructions
          }
          form {
            inputs {
              customFields {
                key
                content {
                  label
                  placeholder
                  validationErrors {
                    key
                    value
                  }
                  items
                  tooltip
                }
              }
            }
            button {
              text
            }
            submitError
          }
          buttons {
            buttonClass
            text
            url
            ... on TileButtonContent {
              image {
                altText
                url
              }
              heading
              description
              actionId
              buttonClass
            }
          }
          finePrint
        }
        optionalConfig {
          inputs {
            customFields {
              key
              position
              inputFieldType
              ... on CustomFieldInputConfig {
                pattern
              }
              ... on CustomFieldListInputConfig {
                items
              }
            }
          }
        }
      }
      ... on SuccessfulFriendRegistrationStep {
        optionalContent {
          title
          body
          secondaryButton {
            text
          }
        }
        optionalConfig {
          button {
            show
          }
        }
      }
      ... on IneligibleForVoucherStep {
        content {
          title
          body
          button {
            text
          }
        }
        config {
          button {
            show
          }
        }
      }
      ... on ReferralLimitReachedStep {
        optionalContent {
          title
          body
          button {
            text
          }
        }
        optionalConfig {
          button {
            show
          }
        }
      }
    }
    urls {
      ...UrlsFragment
    }
    programType
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const CaptureFriendDetailsActiveStepFragmentFragmentDoc = gql`
  fragment CaptureFriendDetailsActiveStepFragment on CaptureFriendDetailsActiveStep {
    validationErrors {
      key
      field
    }
  }
`;
export const CaptureAdditionalFriendDetailsActiveStepFragmentFragmentDoc = gql`
  fragment CaptureAdditionalFriendDetailsActiveStepFragment on CaptureAdditionalFriendDetailsActiveStep {
    config {
      firstVisit
    }
    validationErrors {
      key
      field
    }
  }
`;
export const SuccessfulFriendRegistrationActiveStepFragmentFragmentDoc = gql`
  fragment SuccessfulFriendRegistrationActiveStepFragment on SuccessfulFriendRegistrationActiveStep {
    config {
      showSecondaryButton
      buttons {
        buttonClass
        text
        url
        ... on TileButtonContent {
          image {
            altText
            url
          }
          heading
          description
          actionId
          buttonClass
        }
      }
    }
  }
`;
export const IneligibleForVoucherActiveStepFragmentFragmentDoc = gql`
  fragment IneligibleForVoucherActiveStepFragment on IneligibleForVoucherActiveStep {
    config {
      destinationUrl
    }
  }
`;
export const ReferralLimitReachedActiveStepFragmentFragmentDoc = gql`
  fragment ReferralLimitReachedActiveStepFragment on ReferralLimitReachedActiveStep {
    config {
      destinationUrl
    }
  }
`;
export const ContinueToClientSiteActiveStepFragmentFragmentDoc = gql`
  fragment ContinueToClientSiteActiveStepFragment on ContinueToClientSiteActiveStep {
    config {
      destinationUrl
    }
  }
`;
export const FriendJourneySessionFragmentFragmentDoc = gql`
  fragment FriendJourneySessionFragment on ActiveStepInterface {
    type
    ... on CaptureFriendDetailsActiveStep {
      ...CaptureFriendDetailsActiveStepFragment
      config {
        rewards {
          title
          body
        }
      }
    }
    ... on CaptureAdditionalFriendDetailsActiveStep {
      ...CaptureAdditionalFriendDetailsActiveStepFragment
    }
    ... on SuccessfulFriendRegistrationActiveStep {
      ...SuccessfulFriendRegistrationActiveStepFragment
    }
    ... on IneligibleForVoucherActiveStep {
      ...IneligibleForVoucherActiveStepFragment
    }
    ... on ReferralLimitReachedActiveStep {
      ...ReferralLimitReachedActiveStepFragment
    }
    ... on ContinueToClientSiteActiveStep {
      ...ContinueToClientSiteActiveStepFragment
    }
  }
  ${CaptureFriendDetailsActiveStepFragmentFragmentDoc}
  ${CaptureAdditionalFriendDetailsActiveStepFragmentFragmentDoc}
  ${SuccessfulFriendRegistrationActiveStepFragmentFragmentDoc}
  ${IneligibleForVoucherActiveStepFragmentFragmentDoc}
  ${ReferralLimitReachedActiveStepFragmentFragmentDoc}
  ${ContinueToClientSiteActiveStepFragmentFragmentDoc}
`;
export const LookupUserJourneyConfigFragmentFragmentDoc = gql`
  fragment LookupUserJourneyConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    localisation {
      date {
        dayNames
        abbreviatedDayNames
        monthNames
        abbreviatedMonthNames
        shortFormat
        longFormat
        monthOnlyFormat
      }
    }
    steps {
      type
      ... on LookupUserStep {
        content {
          body
          form {
            button {
              text
            }
            inputs {
              accessCode {
                label
                placeholder
                tooltip
                validationErrors {
                  key
                  value
                }
              }
            }
            submitError
          }
          image {
            altText
            url
          }
          title
        }
      }
    }
    urls {
      ...UrlsFragment
    }
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const LookupUserActiveStepFragmentFragmentDoc = gql`
  fragment LookupUserActiveStepFragment on LookupUserActiveStep {
    config {
      redirectToUrl
      showInvalidCodeMessage
    }
    validationErrors {
      key
      field
    }
  }
`;
export const PartnerJourneyConfigFragmentFragmentDoc = gql`
  fragment PartnerJourneyConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    localisation {
      date {
        dayNames
        abbreviatedDayNames
        monthNames
        abbreviatedMonthNames
        shortFormat
        longFormat
        monthOnlyFormat
      }
    }
    steps {
      type
      ... on PartnerPageDisabledStep {
        content {
          title
          body
        }
      }
      ... on PartnerDashboardStep {
        content {
          title
          referralStats {
            title
            friendVisitCount
            completeReferralCount
            friendRegistrationCount
            cancelledReferralCount
            pendingReferralCount
          }
          partnerOffer {
            title
            heading
            body
            image {
              url
              altText
            }
          }
          audienceOffer {
            title
            heading
            body
            image {
              url
              altText
            }
          }
          sharingTools {
            title
            directLink {
              label
              copyButtonText
              copySuccessNotification
              nativeSharing {
                buttonText
                title
              }
              shareMessage
            }
            shareCode {
              label
              copyButtonText
              copySuccessNotification
              nativeSharing {
                buttonText
                title
              }
              shareMessage
            }
          }
          usefulLinks {
            title
            buttons {
              text
              url
            }
          }
          performance {
            title
            table {
              headers {
                month
                visits
                created
                confirmed
                cancelled
                rewardsConfirmed
              }
              tooltips {
                visits
                created
                confirmed
                cancelled
              }
              total
              downloadButtonText
            }
          }
          rules {
            title
            body
          }
        }
        config {
          partnerOffer {
            displayRewards
          }
          audienceOffer {
            displayRewards
          }
          sharingTools {
            displayDirectLink
            displayShareCode
          }
          showReferralStats
          referralStats
        }
      }
    }
    urls {
      ...UrlsFragment
    }
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const PartnerDashboardActiveStepFragmentFragmentDoc = gql`
  fragment PartnerDashboardActiveStepFragment on PartnerDashboardActiveStep {
    config {
      referralStats {
        friendVisitCount
        completeReferralCount
        friendRegistrationCount
        cancelledReferralCount
        pendingReferralCount
      }
      sharingUrls {
        directLink
        shareCode
      }
      performance {
        total {
          visits
          created
          confirmed
          cancelled
          pending
          rewardsConfirmed {
            displayString
          }
        }
        details {
          date
          visits
          created
          confirmed
          cancelled
          rewardsConfirmed {
            displayString
          }
        }
      }
      rewards {
        partnerRewards {
          title
          body
        }
        audienceRewards {
          title
          body
        }
      }
    }
  }
`;
export const RedirectFriendToClientJourneyConfigFragmentFragmentDoc = gql`
  fragment RedirectFriendToClientJourneyConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    steps {
      type
      ... on ClickToRedirectFriendStep {
        optionalContent {
          title
          body
          image {
            url
            altText
          }
          form {
            caption
            button {
              text
            }
            finePrint
          }
          additionalFinePrint
        }
      }
    }
    urls {
      ...UrlsFragment
    }
    programType
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const RedirectFriendToClientJourneySessionFragmentFragmentDoc = gql`
  fragment RedirectFriendToClientJourneySessionFragment on ActiveStepInterface {
    type
    ... on ClickToRedirectFriendActiveStep {
      config {
        destinationUrl
        requiresFriendRegistration
      }
    }
    ... on AutomaticallyRedirectFriendActiveStep {
      config {
        destinationUrl
        requiresFriendRegistration
      }
    }
  }
`;
export const ReferrerJourneyConfigFragmentFragmentDoc = gql`
  fragment ReferrerJourneyConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    localisation {
      date {
        dayNames
        abbreviatedDayNames
        monthNames
        abbreviatedMonthNames
        shortFormat
        longFormat
      }
      sharing {
        tabs {
          socialButtonText
          emailButtonText
        }
        email {
          messageLabelText
          emailClientLabelText
        }
      }
    }
    steps {
      type
      ... on PageDisabledStep {
        content {
          title
          body
        }
      }
      ... on SeamlessLoginFailureStep {
        content {
          title
          body
        }
      }
      ... on SplashPageStep {
        optionalConfig {
          inputs {
            marketingOptIn {
              show
              required
            }
            termsOptIn {
              show
            }
          }
          offerDetails {
            showStepIcons
            revealOnLoad
          }
          layout
        }
        optionalContent {
          title
          image {
            url
            altText
          }
          offerDetails {
            collapsedTitle
            expandedTitle
            steps
            footer
          }
          form {
            caption
            submitError
            button {
              text
            }
            inputs {
              marketingOptIn {
                label
                validationErrors {
                  key
                  value
                }
              }
              termsOptIn {
                label
                validationErrors {
                  key
                  value
                }
              }
            }
            finePrint
          }
          eligibilityCheckRejectionMessage
          additionalFinePrint
        }
      }
      ... on CaptureReferrerDetailsStep {
        optionalConfig {
          inputs {
            fullName {
              show
              pattern
            }
            firstName {
              show
              pattern
            }
            lastName {
              show
              pattern
            }
            email {
              show
              pattern
            }
            marketingOptIn {
              show
              required
            }
            termsOptIn {
              show
            }
            customFields {
              key
              position
              inputFieldType
              ... on CustomFieldInputConfig {
                pattern
              }
              ... on CustomFieldListInputConfig {
                items
              }
            }
          }
          button {
            show
          }
          offerDetails {
            showStepIcons
            revealOnLoad
          }
          layout
        }
        optionalContent {
          title
          image {
            url
            altText
            mobile {
              url
              altText
            }
          }
          offerDetails {
            collapsedTitle
            expandedTitle
            steps
            footer
          }
          form {
            caption
            submitError
            button {
              text
            }
            inputs {
              fullName {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              firstName {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              lastName {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              email {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                tooltip
              }
              marketingOptIn {
                label
                validationErrors {
                  key
                  value
                }
              }
              termsOptIn {
                label
                validationErrors {
                  key
                  value
                }
              }
              customFields {
                key
                content {
                  label
                  placeholder
                  validationErrors {
                    key
                    value
                  }
                  items
                }
              }
            }
            finePrint
          }
          signInLinkEmailedMessage
          eligibilityCheckRejectionMessage
          additionalFinePrint
        }
      }
      ... on ReferrerDashboardStep {
        content {
          title
          logout {
            message
            linkText
          }
          image {
            url
            altText
            mobile {
              url
              altText
            }
          }
          offerDetails {
            collapsedTitle
            expandedTitle
            steps
            footer
          }
          referralStatus {
            stats {
              friendVisitCount
              completeReferralCount
              friendRegistrationCount
              cancelledReferralCount
              pendingReferralCount
            }
            pill {
              expanded
              collapsed
            }
            table {
              pending {
                title
                tooltip
              }
              complete {
                title
                tooltip
              }
              headers {
                name
                date
                status
              }
              statuses {
                pending
                complete
              }
            }
          }
          rewardCapping {
            warningTitle
            monetaryWarningMessage
            volumeWarningMessage
          }
          finePrint
          referralStatus {
            stats {
              friendVisitCount
              completeReferralCount
              friendRegistrationCount
              cancelledReferralCount
              pendingReferralCount
            }
            pill {
              expanded
              collapsed
            }
            table {
              pending {
                title
                tooltip
              }
              complete {
                title
                tooltip
              }
              headers {
                name
                date
                status
              }
              statuses {
                pending
                complete
              }
            }
          }
          shareMessage
          manualSharing {
            title
            copyButtonText
            copySuccessNotification
          }
          nativeSharing {
            title
            buttonText
          }
          shareCode {
            copyButtonText
            copySuccessNotification
            label
            nativeSharing {
              title
              buttonText
            }
            shareMessage
          }
        }
        config {
          showReferralStatuses
          showPendingReferrals
          showReferralStats
          referralStats
          shareNetworks
          layout
          offerDetails {
            showStepIcons
          }
          showDirectLink
          showShareCode
        }
        style {
          shareButtons {
            css
            _hover {
              css
            }
            icon {
              css
              _hover {
                css
              }
            }
          }
        }
      }
    }
    urls {
      ...UrlsFragment
    }
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const SplashPageActiveStepFragmentFragmentDoc = gql`
  fragment SplashPageActiveStepFragment on SplashPageActiveStep {
    config {
      showEligibilityCheckRejectionMessage
    }
  }
`;
export const CaptureReferrerDetailsActiveStepFragmentFragmentDoc = gql`
  fragment CaptureReferrerDetailsActiveStepFragment on CaptureReferrerDetailsActiveStep {
    config {
      showSignInLinkEmailedMessage
      showEligibilityCheckRejectionMessage
    }
    validationErrors {
      key
      field
    }
  }
`;
export const ReferrerDashboardActiveStepFragmentFragmentDoc = gql`
  fragment ReferrerDashboardActiveStepFragment on ReferrerDashboardActiveStep {
    config {
      canLogout
      manualSharingShortUrl
      nativeSharingShortUrl
      emailSharingShortUrl
      shareNetworkShortUrls {
        facebook
        twitter
        pinterest
        reddit
        whatsapp
        facebookMessenger
        line
        vkontakte
        zalo
        viber
        telegram
      }
      rewardCappingType
      showRewardLimitWarningMessage
      referralStatus {
        friendVisitCount
        completeReferralCount
        friendRegistrationCount
        cancelledReferralCount
        pendingReferralCount
        completeReferrals {
          name
          date
          status
        }
        pendingReferrals {
          name
          date
          status
        }
      }
    }
  }
`;
export const ReferrerJourneySessionFragmentFragmentDoc = gql`
  fragment ReferrerJourneySessionFragment on ActiveStepInterface {
    type
    ... on SplashPageActiveStep {
      ...SplashPageActiveStepFragment
    }
    ... on CaptureReferrerDetailsActiveStep {
      ...CaptureReferrerDetailsActiveStepFragment
    }
    ... on ReferrerDashboardActiveStep {
      ...ReferrerDashboardActiveStepFragment
    }
  }
  ${SplashPageActiveStepFragmentFragmentDoc}
  ${CaptureReferrerDetailsActiveStepFragmentFragmentDoc}
  ${ReferrerDashboardActiveStepFragmentFragmentDoc}
`;
export const RewardRedemptionJourneyConfigFragmentFragmentDoc = gql`
  fragment RewardRedemptionJourneyConfigFragment on Config {
    customFonts {
      ...CustomFontsFragment
    }
    styles {
      ...SharedStyleFragment
    }
    globalStyles
    urls {
      ...UrlsFragment
    }
    programType
    steps {
      type
    }
  }
  ${CustomFontsFragmentFragmentDoc}
  ${SharedStyleFragmentFragmentDoc}
  ${UrlsFragmentFragmentDoc}
`;
export const RedeemRewardActiveStepFragmentFragmentDoc = gql`
  fragment RedeemRewardActiveStepFragment on RedeemRewardActiveStep {
    config {
      destinationUrl
    }
  }
`;
export const RewardRedemptionJourneySessionFragmentFragmentDoc = gql`
  fragment RewardRedemptionJourneySessionFragment on ActiveStepInterface {
    type
    ... on RedeemRewardActiveStep {
      ...RedeemRewardActiveStepFragment
    }
  }
  ${RedeemRewardActiveStepFragmentFragmentDoc}
`;
export const ClickToClaimFriendGetConfigDocument = gql`
  query clickToClaimFriendGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...ClickToClaimFriendConfigFragment
    }
  }
  ${ClickToClaimFriendConfigFragmentFragmentDoc}
`;

/**
 * __useClickToClaimFriendGetConfigQuery__
 *
 * To run a query within a React component, call `useClickToClaimFriendGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useClickToClaimFriendGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClickToClaimFriendGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useClickToClaimFriendGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClickToClaimFriendGetConfigQuery,
    ClickToClaimFriendGetConfigQueryVariables
  > &
    ({ variables: ClickToClaimFriendGetConfigQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClickToClaimFriendGetConfigQuery,
    ClickToClaimFriendGetConfigQueryVariables
  >(ClickToClaimFriendGetConfigDocument, options);
}
export function useClickToClaimFriendGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClickToClaimFriendGetConfigQuery,
    ClickToClaimFriendGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClickToClaimFriendGetConfigQuery,
    ClickToClaimFriendGetConfigQueryVariables
  >(ClickToClaimFriendGetConfigDocument, options);
}
export function useClickToClaimFriendGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClickToClaimFriendGetConfigQuery,
        ClickToClaimFriendGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClickToClaimFriendGetConfigQuery,
    ClickToClaimFriendGetConfigQueryVariables
  >(ClickToClaimFriendGetConfigDocument, options);
}
export type ClickToClaimFriendGetConfigQueryHookResult = ReturnType<
  typeof useClickToClaimFriendGetConfigQuery
>;
export type ClickToClaimFriendGetConfigLazyQueryHookResult = ReturnType<
  typeof useClickToClaimFriendGetConfigLazyQuery
>;
export type ClickToClaimFriendGetConfigSuspenseQueryHookResult = ReturnType<
  typeof useClickToClaimFriendGetConfigSuspenseQuery
>;
export type ClickToClaimFriendGetConfigQueryResult = Apollo.QueryResult<
  ClickToClaimFriendGetConfigQuery,
  ClickToClaimFriendGetConfigQueryVariables
>;
export const ClickToClaimFriendGetSessionDocument = gql`
  query clickToClaimFriendGetSession(
    $flow: String!
    $flowId: ID!
    $id: ID!
    $requestedLocale: String
  ) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        type
        ... on SelectFriendRewardOptionActiveStep {
          config {
            rewardTypeId
            allowedRewardOptions
          }
        }
      }
      contentReplacements
      locale
    }
  }
`;

/**
 * __useClickToClaimFriendGetSessionQuery__
 *
 * To run a query within a React component, call `useClickToClaimFriendGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useClickToClaimFriendGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClickToClaimFriendGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function useClickToClaimFriendGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClickToClaimFriendGetSessionQuery,
    ClickToClaimFriendGetSessionQueryVariables
  > &
    ({ variables: ClickToClaimFriendGetSessionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClickToClaimFriendGetSessionQuery,
    ClickToClaimFriendGetSessionQueryVariables
  >(ClickToClaimFriendGetSessionDocument, options);
}
export function useClickToClaimFriendGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClickToClaimFriendGetSessionQuery,
    ClickToClaimFriendGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClickToClaimFriendGetSessionQuery,
    ClickToClaimFriendGetSessionQueryVariables
  >(ClickToClaimFriendGetSessionDocument, options);
}
export function useClickToClaimFriendGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClickToClaimFriendGetSessionQuery,
        ClickToClaimFriendGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClickToClaimFriendGetSessionQuery,
    ClickToClaimFriendGetSessionQueryVariables
  >(ClickToClaimFriendGetSessionDocument, options);
}
export type ClickToClaimFriendGetSessionQueryHookResult = ReturnType<
  typeof useClickToClaimFriendGetSessionQuery
>;
export type ClickToClaimFriendGetSessionLazyQueryHookResult = ReturnType<
  typeof useClickToClaimFriendGetSessionLazyQuery
>;
export type ClickToClaimFriendGetSessionSuspenseQueryHookResult = ReturnType<
  typeof useClickToClaimFriendGetSessionSuspenseQuery
>;
export type ClickToClaimFriendGetSessionQueryResult = Apollo.QueryResult<
  ClickToClaimFriendGetSessionQuery,
  ClickToClaimFriendGetSessionQueryVariables
>;
export const ClickToClaimReferrerGetConfigDocument = gql`
  query clickToClaimReferrerGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...ClickToClaimReferrerConfigFragment
    }
  }
  ${ClickToClaimReferrerConfigFragmentFragmentDoc}
`;

/**
 * __useClickToClaimReferrerGetConfigQuery__
 *
 * To run a query within a React component, call `useClickToClaimReferrerGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useClickToClaimReferrerGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClickToClaimReferrerGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useClickToClaimReferrerGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClickToClaimReferrerGetConfigQuery,
    ClickToClaimReferrerGetConfigQueryVariables
  > &
    (
      | { variables: ClickToClaimReferrerGetConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClickToClaimReferrerGetConfigQuery,
    ClickToClaimReferrerGetConfigQueryVariables
  >(ClickToClaimReferrerGetConfigDocument, options);
}
export function useClickToClaimReferrerGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClickToClaimReferrerGetConfigQuery,
    ClickToClaimReferrerGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClickToClaimReferrerGetConfigQuery,
    ClickToClaimReferrerGetConfigQueryVariables
  >(ClickToClaimReferrerGetConfigDocument, options);
}
export function useClickToClaimReferrerGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClickToClaimReferrerGetConfigQuery,
        ClickToClaimReferrerGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClickToClaimReferrerGetConfigQuery,
    ClickToClaimReferrerGetConfigQueryVariables
  >(ClickToClaimReferrerGetConfigDocument, options);
}
export type ClickToClaimReferrerGetConfigQueryHookResult = ReturnType<
  typeof useClickToClaimReferrerGetConfigQuery
>;
export type ClickToClaimReferrerGetConfigLazyQueryHookResult = ReturnType<
  typeof useClickToClaimReferrerGetConfigLazyQuery
>;
export type ClickToClaimReferrerGetConfigSuspenseQueryHookResult = ReturnType<
  typeof useClickToClaimReferrerGetConfigSuspenseQuery
>;
export type ClickToClaimReferrerGetConfigQueryResult = Apollo.QueryResult<
  ClickToClaimReferrerGetConfigQuery,
  ClickToClaimReferrerGetConfigQueryVariables
>;
export const ClickToClaimReferrerGetSessionDocument = gql`
  query clickToClaimReferrerGetSession(
    $flow: String!
    $flowId: ID!
    $id: ID!
    $requestedLocale: String
  ) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        type
        ... on ClickToClaimOptionsSelectorActiveStep {
          config {
            rewardTypeId
            allowedRewardOptions
          }
        }
      }
      contentReplacements
      locale
    }
  }
`;

/**
 * __useClickToClaimReferrerGetSessionQuery__
 *
 * To run a query within a React component, call `useClickToClaimReferrerGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useClickToClaimReferrerGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClickToClaimReferrerGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function useClickToClaimReferrerGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ClickToClaimReferrerGetSessionQuery,
    ClickToClaimReferrerGetSessionQueryVariables
  > &
    (
      | { variables: ClickToClaimReferrerGetSessionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ClickToClaimReferrerGetSessionQuery,
    ClickToClaimReferrerGetSessionQueryVariables
  >(ClickToClaimReferrerGetSessionDocument, options);
}
export function useClickToClaimReferrerGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ClickToClaimReferrerGetSessionQuery,
    ClickToClaimReferrerGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ClickToClaimReferrerGetSessionQuery,
    ClickToClaimReferrerGetSessionQueryVariables
  >(ClickToClaimReferrerGetSessionDocument, options);
}
export function useClickToClaimReferrerGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ClickToClaimReferrerGetSessionQuery,
        ClickToClaimReferrerGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ClickToClaimReferrerGetSessionQuery,
    ClickToClaimReferrerGetSessionQueryVariables
  >(ClickToClaimReferrerGetSessionDocument, options);
}
export type ClickToClaimReferrerGetSessionQueryHookResult = ReturnType<
  typeof useClickToClaimReferrerGetSessionQuery
>;
export type ClickToClaimReferrerGetSessionLazyQueryHookResult = ReturnType<
  typeof useClickToClaimReferrerGetSessionLazyQuery
>;
export type ClickToClaimReferrerGetSessionSuspenseQueryHookResult = ReturnType<
  typeof useClickToClaimReferrerGetSessionSuspenseQuery
>;
export type ClickToClaimReferrerGetSessionQueryResult = Apollo.QueryResult<
  ClickToClaimReferrerGetSessionQuery,
  ClickToClaimReferrerGetSessionQueryVariables
>;
export const ClaimRewardDocument = gql`
  mutation claimReward($flowId: String!, $optionKey: ID!, $id: ID!, $userType: String!) {
    claimReward(input: { flowId: $flowId, optionKey: $optionKey, id: $id, userType: $userType }) {
      ok
      session {
        activeStep {
          type
        }
        contentReplacements
      }
      errors
    }
  }
`;
export type ClaimRewardMutationFn = Apollo.MutationFunction<
  ClaimRewardMutation,
  ClaimRewardMutationVariables
>;

/**
 * __useClaimRewardMutation__
 *
 * To run a mutation, you first call `useClaimRewardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimRewardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimRewardMutation, { data, loading, error }] = useClaimRewardMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      optionKey: // value for 'optionKey'
 *      id: // value for 'id'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useClaimRewardMutation(
  baseOptions?: Apollo.MutationHookOptions<ClaimRewardMutation, ClaimRewardMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ClaimRewardMutation, ClaimRewardMutationVariables>(
    ClaimRewardDocument,
    options,
  );
}
export type ClaimRewardMutationHookResult = ReturnType<typeof useClaimRewardMutation>;
export type ClaimRewardMutationResult = Apollo.MutationResult<ClaimRewardMutation>;
export type ClaimRewardMutationOptions = Apollo.BaseMutationOptions<
  ClaimRewardMutation,
  ClaimRewardMutationVariables
>;
export const FriendJourneyGetConfigDocument = gql`
  query friendJourneyGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...FriendJourneyConfigFragment
    }
  }
  ${FriendJourneyConfigFragmentFragmentDoc}
`;

/**
 * __useFriendJourneyGetConfigQuery__
 *
 * To run a query within a React component, call `useFriendJourneyGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendJourneyGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendJourneyGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useFriendJourneyGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    FriendJourneyGetConfigQuery,
    FriendJourneyGetConfigQueryVariables
  > &
    ({ variables: FriendJourneyGetConfigQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FriendJourneyGetConfigQuery, FriendJourneyGetConfigQueryVariables>(
    FriendJourneyGetConfigDocument,
    options,
  );
}
export function useFriendJourneyGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FriendJourneyGetConfigQuery,
    FriendJourneyGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FriendJourneyGetConfigQuery, FriendJourneyGetConfigQueryVariables>(
    FriendJourneyGetConfigDocument,
    options,
  );
}
export function useFriendJourneyGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FriendJourneyGetConfigQuery,
        FriendJourneyGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FriendJourneyGetConfigQuery, FriendJourneyGetConfigQueryVariables>(
    FriendJourneyGetConfigDocument,
    options,
  );
}
export type FriendJourneyGetConfigQueryHookResult = ReturnType<
  typeof useFriendJourneyGetConfigQuery
>;
export type FriendJourneyGetConfigLazyQueryHookResult = ReturnType<
  typeof useFriendJourneyGetConfigLazyQuery
>;
export type FriendJourneyGetConfigSuspenseQueryHookResult = ReturnType<
  typeof useFriendJourneyGetConfigSuspenseQuery
>;
export type FriendJourneyGetConfigQueryResult = Apollo.QueryResult<
  FriendJourneyGetConfigQuery,
  FriendJourneyGetConfigQueryVariables
>;
export const AddFriendActionIdDocument = gql`
  mutation addFriendActionId($flowId: String!, $id: ID!, $actionId: String!) {
    addFriendActionId(input: { flowId: $flowId, id: $id, actionId: $actionId }) {
      ok
      errors
    }
  }
`;
export type AddFriendActionIdMutationFn = Apollo.MutationFunction<
  AddFriendActionIdMutation,
  AddFriendActionIdMutationVariables
>;

/**
 * __useAddFriendActionIdMutation__
 *
 * To run a mutation, you first call `useAddFriendActionIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFriendActionIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFriendActionIdMutation, { data, loading, error }] = useAddFriendActionIdMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useAddFriendActionIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFriendActionIdMutation,
    AddFriendActionIdMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFriendActionIdMutation, AddFriendActionIdMutationVariables>(
    AddFriendActionIdDocument,
    options,
  );
}
export type AddFriendActionIdMutationHookResult = ReturnType<typeof useAddFriendActionIdMutation>;
export type AddFriendActionIdMutationResult = Apollo.MutationResult<AddFriendActionIdMutation>;
export type AddFriendActionIdMutationOptions = Apollo.BaseMutationOptions<
  AddFriendActionIdMutation,
  AddFriendActionIdMutationVariables
>;
export const AddFriendDetailsDocument = gql`
  mutation addFriendDetails($flowId: String!, $id: ID!, $customFields: [CustomFieldInput!]!) {
    addFriendDetails(input: { flowId: $flowId, id: $id, customFields: $customFields }) {
      ok
      session {
        locale
        contentReplacements
        activeStep {
          ...FriendJourneySessionFragment
        }
      }
      errors
    }
  }
  ${FriendJourneySessionFragmentFragmentDoc}
`;
export type AddFriendDetailsMutationFn = Apollo.MutationFunction<
  AddFriendDetailsMutation,
  AddFriendDetailsMutationVariables
>;

/**
 * __useAddFriendDetailsMutation__
 *
 * To run a mutation, you first call `useAddFriendDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFriendDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFriendDetailsMutation, { data, loading, error }] = useAddFriendDetailsMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      customFields: // value for 'customFields'
 *   },
 * });
 */
export function useAddFriendDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFriendDetailsMutation,
    AddFriendDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFriendDetailsMutation, AddFriendDetailsMutationVariables>(
    AddFriendDetailsDocument,
    options,
  );
}
export type AddFriendDetailsMutationHookResult = ReturnType<typeof useAddFriendDetailsMutation>;
export type AddFriendDetailsMutationResult = Apollo.MutationResult<AddFriendDetailsMutation>;
export type AddFriendDetailsMutationOptions = Apollo.BaseMutationOptions<
  AddFriendDetailsMutation,
  AddFriendDetailsMutationVariables
>;
export const AddFriendReferralDocument = gql`
  mutation addFriendReferral($flowId: String!, $id: ID!) {
    addFriendReferral(input: { flowId: $flowId, id: $id }) {
      ok
      session {
        locale
        contentReplacements
        activeStep {
          ...FriendJourneySessionFragment
        }
      }
      errors
    }
  }
  ${FriendJourneySessionFragmentFragmentDoc}
`;
export type AddFriendReferralMutationFn = Apollo.MutationFunction<
  AddFriendReferralMutation,
  AddFriendReferralMutationVariables
>;

/**
 * __useAddFriendReferralMutation__
 *
 * To run a mutation, you first call `useAddFriendReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFriendReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFriendReferralMutation, { data, loading, error }] = useAddFriendReferralMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddFriendReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFriendReferralMutation,
    AddFriendReferralMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddFriendReferralMutation, AddFriendReferralMutationVariables>(
    AddFriendReferralDocument,
    options,
  );
}
export type AddFriendReferralMutationHookResult = ReturnType<typeof useAddFriendReferralMutation>;
export type AddFriendReferralMutationResult = Apollo.MutationResult<AddFriendReferralMutation>;
export type AddFriendReferralMutationOptions = Apollo.BaseMutationOptions<
  AddFriendReferralMutation,
  AddFriendReferralMutationVariables
>;
export const LoginViaIdentifierDocument = gql`
  mutation loginViaIdentifier($flowId: String!, $id: ID!, $identifier: String!) {
    loginViaIdentifier(input: { flowId: $flowId, id: $id, identifier: $identifier }) {
      ok
      session {
        locale
        contentReplacements
        activeStep {
          ...FriendJourneySessionFragment
        }
      }
      errors
    }
  }
  ${FriendJourneySessionFragmentFragmentDoc}
`;
export type LoginViaIdentifierMutationFn = Apollo.MutationFunction<
  LoginViaIdentifierMutation,
  LoginViaIdentifierMutationVariables
>;

/**
 * __useLoginViaIdentifierMutation__
 *
 * To run a mutation, you first call `useLoginViaIdentifierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginViaIdentifierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginViaIdentifierMutation, { data, loading, error }] = useLoginViaIdentifierMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      identifier: // value for 'identifier'
 *   },
 * });
 */
export function useLoginViaIdentifierMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginViaIdentifierMutation,
    LoginViaIdentifierMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginViaIdentifierMutation, LoginViaIdentifierMutationVariables>(
    LoginViaIdentifierDocument,
    options,
  );
}
export type LoginViaIdentifierMutationHookResult = ReturnType<typeof useLoginViaIdentifierMutation>;
export type LoginViaIdentifierMutationResult = Apollo.MutationResult<LoginViaIdentifierMutation>;
export type LoginViaIdentifierMutationOptions = Apollo.BaseMutationOptions<
  LoginViaIdentifierMutation,
  LoginViaIdentifierMutationVariables
>;
export const PartiallyRegisterFriendDocument = gql`
  mutation partiallyRegisterFriend(
    $flowId: String!
    $id: ID!
    $locale: String
    $fullName: String
    $firstName: String
    $lastName: String
    $email: String!
    $marketingOptIn: Boolean
    $termsOptIn: Boolean
  ) {
    partiallyRegisterFriend(
      input: {
        flowId: $flowId
        id: $id
        locale: $locale
        fullName: $fullName
        firstName: $firstName
        lastName: $lastName
        email: $email
        termsOptIn: $termsOptIn
        marketingOptIn: $marketingOptIn
      }
    ) {
      ok
      session {
        locale
        contentReplacements
        activeStep {
          ...FriendJourneySessionFragment
        }
      }
      errors
    }
  }
  ${FriendJourneySessionFragmentFragmentDoc}
`;
export type PartiallyRegisterFriendMutationFn = Apollo.MutationFunction<
  PartiallyRegisterFriendMutation,
  PartiallyRegisterFriendMutationVariables
>;

/**
 * __usePartiallyRegisterFriendMutation__
 *
 * To run a mutation, you first call `usePartiallyRegisterFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartiallyRegisterFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partiallyRegisterFriendMutation, { data, loading, error }] = usePartiallyRegisterFriendMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      fullName: // value for 'fullName'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      marketingOptIn: // value for 'marketingOptIn'
 *      termsOptIn: // value for 'termsOptIn'
 *   },
 * });
 */
export function usePartiallyRegisterFriendMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PartiallyRegisterFriendMutation,
    PartiallyRegisterFriendMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PartiallyRegisterFriendMutation,
    PartiallyRegisterFriendMutationVariables
  >(PartiallyRegisterFriendDocument, options);
}
export type PartiallyRegisterFriendMutationHookResult = ReturnType<
  typeof usePartiallyRegisterFriendMutation
>;
export type PartiallyRegisterFriendMutationResult =
  Apollo.MutationResult<PartiallyRegisterFriendMutation>;
export type PartiallyRegisterFriendMutationOptions = Apollo.BaseMutationOptions<
  PartiallyRegisterFriendMutation,
  PartiallyRegisterFriendMutationVariables
>;
export const RegisterFriendDocument = gql`
  mutation registerFriend(
    $flowId: String!
    $id: ID!
    $locale: String
    $fullName: String
    $firstName: String
    $lastName: String
    $email: String!
    $marketingOptIn: Boolean
    $termsOptIn: Boolean
    $customFields: [CustomFieldInput!]
  ) {
    registerFriend(
      input: {
        flowId: $flowId
        id: $id
        locale: $locale
        fullName: $fullName
        firstName: $firstName
        lastName: $lastName
        email: $email
        termsOptIn: $termsOptIn
        marketingOptIn: $marketingOptIn
        customFields: $customFields
      }
    ) {
      ok
      session {
        locale
        contentReplacements
        activeStep {
          ...FriendJourneySessionFragment
        }
      }
      errors
    }
  }
  ${FriendJourneySessionFragmentFragmentDoc}
`;
export type RegisterFriendMutationFn = Apollo.MutationFunction<
  RegisterFriendMutation,
  RegisterFriendMutationVariables
>;

/**
 * __useRegisterFriendMutation__
 *
 * To run a mutation, you first call `useRegisterFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerFriendMutation, { data, loading, error }] = useRegisterFriendMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      fullName: // value for 'fullName'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      marketingOptIn: // value for 'marketingOptIn'
 *      termsOptIn: // value for 'termsOptIn'
 *      customFields: // value for 'customFields'
 *   },
 * });
 */
export function useRegisterFriendMutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterFriendMutation, RegisterFriendMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterFriendMutation, RegisterFriendMutationVariables>(
    RegisterFriendDocument,
    options,
  );
}
export type RegisterFriendMutationHookResult = ReturnType<typeof useRegisterFriendMutation>;
export type RegisterFriendMutationResult = Apollo.MutationResult<RegisterFriendMutation>;
export type RegisterFriendMutationOptions = Apollo.BaseMutationOptions<
  RegisterFriendMutation,
  RegisterFriendMutationVariables
>;
export const FriendJourneyGetSessionDocument = gql`
  query friendJourneyGetSession($flow: String!, $flowId: ID!, $id: ID!, $requestedLocale: String) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        ...FriendJourneySessionFragment
      }
      contentReplacements
      locale
    }
  }
  ${FriendJourneySessionFragmentFragmentDoc}
`;

/**
 * __useFriendJourneyGetSessionQuery__
 *
 * To run a query within a React component, call `useFriendJourneyGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFriendJourneyGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFriendJourneyGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function useFriendJourneyGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    FriendJourneyGetSessionQuery,
    FriendJourneyGetSessionQueryVariables
  > &
    ({ variables: FriendJourneyGetSessionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FriendJourneyGetSessionQuery, FriendJourneyGetSessionQueryVariables>(
    FriendJourneyGetSessionDocument,
    options,
  );
}
export function useFriendJourneyGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FriendJourneyGetSessionQuery,
    FriendJourneyGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FriendJourneyGetSessionQuery, FriendJourneyGetSessionQueryVariables>(
    FriendJourneyGetSessionDocument,
    options,
  );
}
export function useFriendJourneyGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        FriendJourneyGetSessionQuery,
        FriendJourneyGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    FriendJourneyGetSessionQuery,
    FriendJourneyGetSessionQueryVariables
  >(FriendJourneyGetSessionDocument, options);
}
export type FriendJourneyGetSessionQueryHookResult = ReturnType<
  typeof useFriendJourneyGetSessionQuery
>;
export type FriendJourneyGetSessionLazyQueryHookResult = ReturnType<
  typeof useFriendJourneyGetSessionLazyQuery
>;
export type FriendJourneyGetSessionSuspenseQueryHookResult = ReturnType<
  typeof useFriendJourneyGetSessionSuspenseQuery
>;
export type FriendJourneyGetSessionQueryResult = Apollo.QueryResult<
  FriendJourneyGetSessionQuery,
  FriendJourneyGetSessionQueryVariables
>;
export const LookupUserJourneyGetConfigDocument = gql`
  query lookupUserJourneyGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...LookupUserJourneyConfigFragment
    }
  }
  ${LookupUserJourneyConfigFragmentFragmentDoc}
`;

/**
 * __useLookupUserJourneyGetConfigQuery__
 *
 * To run a query within a React component, call `useLookupUserJourneyGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupUserJourneyGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupUserJourneyGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useLookupUserJourneyGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    LookupUserJourneyGetConfigQuery,
    LookupUserJourneyGetConfigQueryVariables
  > &
    ({ variables: LookupUserJourneyGetConfigQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LookupUserJourneyGetConfigQuery, LookupUserJourneyGetConfigQueryVariables>(
    LookupUserJourneyGetConfigDocument,
    options,
  );
}
export function useLookupUserJourneyGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookupUserJourneyGetConfigQuery,
    LookupUserJourneyGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LookupUserJourneyGetConfigQuery,
    LookupUserJourneyGetConfigQueryVariables
  >(LookupUserJourneyGetConfigDocument, options);
}
export function useLookupUserJourneyGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LookupUserJourneyGetConfigQuery,
        LookupUserJourneyGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LookupUserJourneyGetConfigQuery,
    LookupUserJourneyGetConfigQueryVariables
  >(LookupUserJourneyGetConfigDocument, options);
}
export type LookupUserJourneyGetConfigQueryHookResult = ReturnType<
  typeof useLookupUserJourneyGetConfigQuery
>;
export type LookupUserJourneyGetConfigLazyQueryHookResult = ReturnType<
  typeof useLookupUserJourneyGetConfigLazyQuery
>;
export type LookupUserJourneyGetConfigSuspenseQueryHookResult = ReturnType<
  typeof useLookupUserJourneyGetConfigSuspenseQuery
>;
export type LookupUserJourneyGetConfigQueryResult = Apollo.QueryResult<
  LookupUserJourneyGetConfigQuery,
  LookupUserJourneyGetConfigQueryVariables
>;
export const LookupUserViaCodeDocument = gql`
  mutation lookupUserViaCode($flowId: String!, $id: ID!, $code: String!) {
    lookupUserViaCode(input: { flowId: $flowId, id: $id, code: $code }) {
      ok
      errors
      session {
        locale
        contentReplacements
        activeStep {
          type
          ... on LookupUserActiveStep {
            ...LookupUserActiveStepFragment
          }
        }
      }
    }
  }
  ${LookupUserActiveStepFragmentFragmentDoc}
`;
export type LookupUserViaCodeMutationFn = Apollo.MutationFunction<
  LookupUserViaCodeMutation,
  LookupUserViaCodeMutationVariables
>;

/**
 * __useLookupUserViaCodeMutation__
 *
 * To run a mutation, you first call `useLookupUserViaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLookupUserViaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lookupUserViaCodeMutation, { data, loading, error }] = useLookupUserViaCodeMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLookupUserViaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LookupUserViaCodeMutation,
    LookupUserViaCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LookupUserViaCodeMutation, LookupUserViaCodeMutationVariables>(
    LookupUserViaCodeDocument,
    options,
  );
}
export type LookupUserViaCodeMutationHookResult = ReturnType<typeof useLookupUserViaCodeMutation>;
export type LookupUserViaCodeMutationResult = Apollo.MutationResult<LookupUserViaCodeMutation>;
export type LookupUserViaCodeMutationOptions = Apollo.BaseMutationOptions<
  LookupUserViaCodeMutation,
  LookupUserViaCodeMutationVariables
>;
export const LookupUserJourneyGetSessionDocument = gql`
  query lookupUserJourneyGetSession(
    $flow: String!
    $flowId: ID!
    $id: ID!
    $requestedLocale: String
  ) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        type
        ... on LookupUserActiveStep {
          ...LookupUserActiveStepFragment
        }
      }
      contentReplacements
      locale
    }
  }
  ${LookupUserActiveStepFragmentFragmentDoc}
`;

/**
 * __useLookupUserJourneyGetSessionQuery__
 *
 * To run a query within a React component, call `useLookupUserJourneyGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupUserJourneyGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupUserJourneyGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function useLookupUserJourneyGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    LookupUserJourneyGetSessionQuery,
    LookupUserJourneyGetSessionQueryVariables
  > &
    ({ variables: LookupUserJourneyGetSessionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LookupUserJourneyGetSessionQuery,
    LookupUserJourneyGetSessionQueryVariables
  >(LookupUserJourneyGetSessionDocument, options);
}
export function useLookupUserJourneyGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LookupUserJourneyGetSessionQuery,
    LookupUserJourneyGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LookupUserJourneyGetSessionQuery,
    LookupUserJourneyGetSessionQueryVariables
  >(LookupUserJourneyGetSessionDocument, options);
}
export function useLookupUserJourneyGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        LookupUserJourneyGetSessionQuery,
        LookupUserJourneyGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    LookupUserJourneyGetSessionQuery,
    LookupUserJourneyGetSessionQueryVariables
  >(LookupUserJourneyGetSessionDocument, options);
}
export type LookupUserJourneyGetSessionQueryHookResult = ReturnType<
  typeof useLookupUserJourneyGetSessionQuery
>;
export type LookupUserJourneyGetSessionLazyQueryHookResult = ReturnType<
  typeof useLookupUserJourneyGetSessionLazyQuery
>;
export type LookupUserJourneyGetSessionSuspenseQueryHookResult = ReturnType<
  typeof useLookupUserJourneyGetSessionSuspenseQuery
>;
export type LookupUserJourneyGetSessionQueryResult = Apollo.QueryResult<
  LookupUserJourneyGetSessionQuery,
  LookupUserJourneyGetSessionQueryVariables
>;
export const PartnerJourneyGetConfigDocument = gql`
  query partnerJourneyGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...PartnerJourneyConfigFragment
    }
  }
  ${PartnerJourneyConfigFragmentFragmentDoc}
`;

/**
 * __usePartnerJourneyGetConfigQuery__
 *
 * To run a query within a React component, call `usePartnerJourneyGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerJourneyGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerJourneyGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function usePartnerJourneyGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    PartnerJourneyGetConfigQuery,
    PartnerJourneyGetConfigQueryVariables
  > &
    ({ variables: PartnerJourneyGetConfigQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerJourneyGetConfigQuery, PartnerJourneyGetConfigQueryVariables>(
    PartnerJourneyGetConfigDocument,
    options,
  );
}
export function usePartnerJourneyGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PartnerJourneyGetConfigQuery,
    PartnerJourneyGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerJourneyGetConfigQuery, PartnerJourneyGetConfigQueryVariables>(
    PartnerJourneyGetConfigDocument,
    options,
  );
}
export function usePartnerJourneyGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PartnerJourneyGetConfigQuery,
        PartnerJourneyGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PartnerJourneyGetConfigQuery,
    PartnerJourneyGetConfigQueryVariables
  >(PartnerJourneyGetConfigDocument, options);
}
export type PartnerJourneyGetConfigQueryHookResult = ReturnType<
  typeof usePartnerJourneyGetConfigQuery
>;
export type PartnerJourneyGetConfigLazyQueryHookResult = ReturnType<
  typeof usePartnerJourneyGetConfigLazyQuery
>;
export type PartnerJourneyGetConfigSuspenseQueryHookResult = ReturnType<
  typeof usePartnerJourneyGetConfigSuspenseQuery
>;
export type PartnerJourneyGetConfigQueryResult = Apollo.QueryResult<
  PartnerJourneyGetConfigQuery,
  PartnerJourneyGetConfigQueryVariables
>;
export const PartnerLoginViaAuthTokenDocument = gql`
  mutation partnerLoginViaAuthToken(
    $flowId: String!
    $id: ID!
    $authToken: String!
    $locale: String
  ) {
    partnerLoginViaAuthToken(
      input: { flowId: $flowId, id: $id, authToken: $authToken, locale: $locale }
    ) {
      ok
      errors
      session {
        activeStep {
          type
          ... on PartnerDashboardActiveStep {
            ...PartnerDashboardActiveStepFragment
          }
        }
        contentReplacements
        locale
      }
    }
  }
  ${PartnerDashboardActiveStepFragmentFragmentDoc}
`;
export type PartnerLoginViaAuthTokenMutationFn = Apollo.MutationFunction<
  PartnerLoginViaAuthTokenMutation,
  PartnerLoginViaAuthTokenMutationVariables
>;

/**
 * __usePartnerLoginViaAuthTokenMutation__
 *
 * To run a mutation, you first call `usePartnerLoginViaAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartnerLoginViaAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partnerLoginViaAuthTokenMutation, { data, loading, error }] = usePartnerLoginViaAuthTokenMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      authToken: // value for 'authToken'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function usePartnerLoginViaAuthTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PartnerLoginViaAuthTokenMutation,
    PartnerLoginViaAuthTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PartnerLoginViaAuthTokenMutation,
    PartnerLoginViaAuthTokenMutationVariables
  >(PartnerLoginViaAuthTokenDocument, options);
}
export type PartnerLoginViaAuthTokenMutationHookResult = ReturnType<
  typeof usePartnerLoginViaAuthTokenMutation
>;
export type PartnerLoginViaAuthTokenMutationResult =
  Apollo.MutationResult<PartnerLoginViaAuthTokenMutation>;
export type PartnerLoginViaAuthTokenMutationOptions = Apollo.BaseMutationOptions<
  PartnerLoginViaAuthTokenMutation,
  PartnerLoginViaAuthTokenMutationVariables
>;
export const PartnerJourneyGetSessionDocument = gql`
  query partnerJourneyGetSession($flow: String!, $flowId: ID!, $id: ID!, $requestedLocale: String) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        type
        ... on PartnerDashboardActiveStep {
          ...PartnerDashboardActiveStepFragment
        }
      }
      contentReplacements
      locale
    }
  }
  ${PartnerDashboardActiveStepFragmentFragmentDoc}
`;

/**
 * __usePartnerJourneyGetSessionQuery__
 *
 * To run a query within a React component, call `usePartnerJourneyGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerJourneyGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerJourneyGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function usePartnerJourneyGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    PartnerJourneyGetSessionQuery,
    PartnerJourneyGetSessionQueryVariables
  > &
    ({ variables: PartnerJourneyGetSessionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PartnerJourneyGetSessionQuery, PartnerJourneyGetSessionQueryVariables>(
    PartnerJourneyGetSessionDocument,
    options,
  );
}
export function usePartnerJourneyGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PartnerJourneyGetSessionQuery,
    PartnerJourneyGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PartnerJourneyGetSessionQuery, PartnerJourneyGetSessionQueryVariables>(
    PartnerJourneyGetSessionDocument,
    options,
  );
}
export function usePartnerJourneyGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        PartnerJourneyGetSessionQuery,
        PartnerJourneyGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    PartnerJourneyGetSessionQuery,
    PartnerJourneyGetSessionQueryVariables
  >(PartnerJourneyGetSessionDocument, options);
}
export type PartnerJourneyGetSessionQueryHookResult = ReturnType<
  typeof usePartnerJourneyGetSessionQuery
>;
export type PartnerJourneyGetSessionLazyQueryHookResult = ReturnType<
  typeof usePartnerJourneyGetSessionLazyQuery
>;
export type PartnerJourneyGetSessionSuspenseQueryHookResult = ReturnType<
  typeof usePartnerJourneyGetSessionSuspenseQuery
>;
export type PartnerJourneyGetSessionQueryResult = Apollo.QueryResult<
  PartnerJourneyGetSessionQuery,
  PartnerJourneyGetSessionQueryVariables
>;
export const RedirectFriendToClientJourneyGetConfigDocument = gql`
  query redirectFriendToClientJourneyGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...RedirectFriendToClientJourneyConfigFragment
    }
  }
  ${RedirectFriendToClientJourneyConfigFragmentFragmentDoc}
`;

/**
 * __useRedirectFriendToClientJourneyGetConfigQuery__
 *
 * To run a query within a React component, call `useRedirectFriendToClientJourneyGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedirectFriendToClientJourneyGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedirectFriendToClientJourneyGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useRedirectFriendToClientJourneyGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    RedirectFriendToClientJourneyGetConfigQuery,
    RedirectFriendToClientJourneyGetConfigQueryVariables
  > &
    (
      | { variables: RedirectFriendToClientJourneyGetConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RedirectFriendToClientJourneyGetConfigQuery,
    RedirectFriendToClientJourneyGetConfigQueryVariables
  >(RedirectFriendToClientJourneyGetConfigDocument, options);
}
export function useRedirectFriendToClientJourneyGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RedirectFriendToClientJourneyGetConfigQuery,
    RedirectFriendToClientJourneyGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RedirectFriendToClientJourneyGetConfigQuery,
    RedirectFriendToClientJourneyGetConfigQueryVariables
  >(RedirectFriendToClientJourneyGetConfigDocument, options);
}
export function useRedirectFriendToClientJourneyGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RedirectFriendToClientJourneyGetConfigQuery,
        RedirectFriendToClientJourneyGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RedirectFriendToClientJourneyGetConfigQuery,
    RedirectFriendToClientJourneyGetConfigQueryVariables
  >(RedirectFriendToClientJourneyGetConfigDocument, options);
}
export type RedirectFriendToClientJourneyGetConfigQueryHookResult = ReturnType<
  typeof useRedirectFriendToClientJourneyGetConfigQuery
>;
export type RedirectFriendToClientJourneyGetConfigLazyQueryHookResult = ReturnType<
  typeof useRedirectFriendToClientJourneyGetConfigLazyQuery
>;
export type RedirectFriendToClientJourneyGetConfigSuspenseQueryHookResult = ReturnType<
  typeof useRedirectFriendToClientJourneyGetConfigSuspenseQuery
>;
export type RedirectFriendToClientJourneyGetConfigQueryResult = Apollo.QueryResult<
  RedirectFriendToClientJourneyGetConfigQuery,
  RedirectFriendToClientJourneyGetConfigQueryVariables
>;
export const RegisterFriendForRedirectionDocument = gql`
  mutation registerFriendForRedirection($flowId: String!, $id: ID!) {
    registerFriendForRedirection(input: { flowId: $flowId, id: $id }) {
      ok
      session {
        locale
        contentReplacements
        activeStep {
          ...RedirectFriendToClientJourneySessionFragment
        }
      }
      errors
    }
  }
  ${RedirectFriendToClientJourneySessionFragmentFragmentDoc}
`;
export type RegisterFriendForRedirectionMutationFn = Apollo.MutationFunction<
  RegisterFriendForRedirectionMutation,
  RegisterFriendForRedirectionMutationVariables
>;

/**
 * __useRegisterFriendForRedirectionMutation__
 *
 * To run a mutation, you first call `useRegisterFriendForRedirectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterFriendForRedirectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerFriendForRedirectionMutation, { data, loading, error }] = useRegisterFriendForRedirectionMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegisterFriendForRedirectionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterFriendForRedirectionMutation,
    RegisterFriendForRedirectionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterFriendForRedirectionMutation,
    RegisterFriendForRedirectionMutationVariables
  >(RegisterFriendForRedirectionDocument, options);
}
export type RegisterFriendForRedirectionMutationHookResult = ReturnType<
  typeof useRegisterFriendForRedirectionMutation
>;
export type RegisterFriendForRedirectionMutationResult =
  Apollo.MutationResult<RegisterFriendForRedirectionMutation>;
export type RegisterFriendForRedirectionMutationOptions = Apollo.BaseMutationOptions<
  RegisterFriendForRedirectionMutation,
  RegisterFriendForRedirectionMutationVariables
>;
export const RedirectFriendToClientJourneyGetSessionDocument = gql`
  query redirectFriendToClientJourneyGetSession(
    $flow: String!
    $flowId: ID!
    $id: ID!
    $requestedLocale: String
  ) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        ...RedirectFriendToClientJourneySessionFragment
      }
      contentReplacements
      locale
    }
  }
  ${RedirectFriendToClientJourneySessionFragmentFragmentDoc}
`;

/**
 * __useRedirectFriendToClientJourneyGetSessionQuery__
 *
 * To run a query within a React component, call `useRedirectFriendToClientJourneyGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRedirectFriendToClientJourneyGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRedirectFriendToClientJourneyGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function useRedirectFriendToClientJourneyGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RedirectFriendToClientJourneyGetSessionQuery,
    RedirectFriendToClientJourneyGetSessionQueryVariables
  > &
    (
      | { variables: RedirectFriendToClientJourneyGetSessionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RedirectFriendToClientJourneyGetSessionQuery,
    RedirectFriendToClientJourneyGetSessionQueryVariables
  >(RedirectFriendToClientJourneyGetSessionDocument, options);
}
export function useRedirectFriendToClientJourneyGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RedirectFriendToClientJourneyGetSessionQuery,
    RedirectFriendToClientJourneyGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RedirectFriendToClientJourneyGetSessionQuery,
    RedirectFriendToClientJourneyGetSessionQueryVariables
  >(RedirectFriendToClientJourneyGetSessionDocument, options);
}
export function useRedirectFriendToClientJourneyGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RedirectFriendToClientJourneyGetSessionQuery,
        RedirectFriendToClientJourneyGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RedirectFriendToClientJourneyGetSessionQuery,
    RedirectFriendToClientJourneyGetSessionQueryVariables
  >(RedirectFriendToClientJourneyGetSessionDocument, options);
}
export type RedirectFriendToClientJourneyGetSessionQueryHookResult = ReturnType<
  typeof useRedirectFriendToClientJourneyGetSessionQuery
>;
export type RedirectFriendToClientJourneyGetSessionLazyQueryHookResult = ReturnType<
  typeof useRedirectFriendToClientJourneyGetSessionLazyQuery
>;
export type RedirectFriendToClientJourneyGetSessionSuspenseQueryHookResult = ReturnType<
  typeof useRedirectFriendToClientJourneyGetSessionSuspenseQuery
>;
export type RedirectFriendToClientJourneyGetSessionQueryResult = Apollo.QueryResult<
  RedirectFriendToClientJourneyGetSessionQuery,
  RedirectFriendToClientJourneyGetSessionQueryVariables
>;
export const ReferrerJourneyGetConfigDocument = gql`
  query referrerJourneyGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...ReferrerJourneyConfigFragment
    }
  }
  ${ReferrerJourneyConfigFragmentFragmentDoc}
`;

/**
 * __useReferrerJourneyGetConfigQuery__
 *
 * To run a query within a React component, call `useReferrerJourneyGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferrerJourneyGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferrerJourneyGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useReferrerJourneyGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReferrerJourneyGetConfigQuery,
    ReferrerJourneyGetConfigQueryVariables
  > &
    ({ variables: ReferrerJourneyGetConfigQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferrerJourneyGetConfigQuery, ReferrerJourneyGetConfigQueryVariables>(
    ReferrerJourneyGetConfigDocument,
    options,
  );
}
export function useReferrerJourneyGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferrerJourneyGetConfigQuery,
    ReferrerJourneyGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferrerJourneyGetConfigQuery, ReferrerJourneyGetConfigQueryVariables>(
    ReferrerJourneyGetConfigDocument,
    options,
  );
}
export function useReferrerJourneyGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferrerJourneyGetConfigQuery,
        ReferrerJourneyGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferrerJourneyGetConfigQuery,
    ReferrerJourneyGetConfigQueryVariables
  >(ReferrerJourneyGetConfigDocument, options);
}
export type ReferrerJourneyGetConfigQueryHookResult = ReturnType<
  typeof useReferrerJourneyGetConfigQuery
>;
export type ReferrerJourneyGetConfigLazyQueryHookResult = ReturnType<
  typeof useReferrerJourneyGetConfigLazyQuery
>;
export type ReferrerJourneyGetConfigSuspenseQueryHookResult = ReturnType<
  typeof useReferrerJourneyGetConfigSuspenseQuery
>;
export type ReferrerJourneyGetConfigQueryResult = Apollo.QueryResult<
  ReferrerJourneyGetConfigQuery,
  ReferrerJourneyGetConfigQueryVariables
>;
export const LoginViaAuthTokenDocument = gql`
  mutation loginViaAuthToken($flowId: String!, $id: ID!, $authToken: String!) {
    loginViaAuthToken(input: { flowId: $flowId, id: $id, authToken: $authToken }) {
      ok
      errors
      session {
        activeStep {
          ...ReferrerJourneySessionFragment
        }
        contentReplacements
        locale
      }
    }
  }
  ${ReferrerJourneySessionFragmentFragmentDoc}
`;
export type LoginViaAuthTokenMutationFn = Apollo.MutationFunction<
  LoginViaAuthTokenMutation,
  LoginViaAuthTokenMutationVariables
>;

/**
 * __useLoginViaAuthTokenMutation__
 *
 * To run a mutation, you first call `useLoginViaAuthTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginViaAuthTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginViaAuthTokenMutation, { data, loading, error }] = useLoginViaAuthTokenMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      authToken: // value for 'authToken'
 *   },
 * });
 */
export function useLoginViaAuthTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginViaAuthTokenMutation,
    LoginViaAuthTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginViaAuthTokenMutation, LoginViaAuthTokenMutationVariables>(
    LoginViaAuthTokenDocument,
    options,
  );
}
export type LoginViaAuthTokenMutationHookResult = ReturnType<typeof useLoginViaAuthTokenMutation>;
export type LoginViaAuthTokenMutationResult = Apollo.MutationResult<LoginViaAuthTokenMutation>;
export type LoginViaAuthTokenMutationOptions = Apollo.BaseMutationOptions<
  LoginViaAuthTokenMutation,
  LoginViaAuthTokenMutationVariables
>;
export const LoginViaSeamlessDocument = gql`
  mutation loginViaSeamless(
    $flowId: String!
    $id: ID!
    $signedKeys: [String!]!
    $signature: String!
    $locale: String
    $customFieldValue: String
    $customFields: [CustomFieldInput!]
    $fullName: String!
    $email: String!
    $marketingOptIn: Boolean
    $termsOptIn: Boolean
    $clientData: JSON
    $splashPageSubmit: Boolean
  ) {
    loginViaSeamless(
      input: {
        flowId: $flowId
        id: $id
        signedKeys: $signedKeys
        signature: $signature
        locale: $locale
        fullName: $fullName
        email: $email
        marketingOptIn: $marketingOptIn
        termsOptIn: $termsOptIn
        customFieldValue: $customFieldValue
        customFields: $customFields
        clientData: $clientData
        splashPageSubmit: $splashPageSubmit
      }
    ) {
      ok
      errors
      session {
        activeStep {
          ...ReferrerJourneySessionFragment
        }
        contentReplacements
        locale
      }
    }
  }
  ${ReferrerJourneySessionFragmentFragmentDoc}
`;
export type LoginViaSeamlessMutationFn = Apollo.MutationFunction<
  LoginViaSeamlessMutation,
  LoginViaSeamlessMutationVariables
>;

/**
 * __useLoginViaSeamlessMutation__
 *
 * To run a mutation, you first call `useLoginViaSeamlessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginViaSeamlessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginViaSeamlessMutation, { data, loading, error }] = useLoginViaSeamlessMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      signedKeys: // value for 'signedKeys'
 *      signature: // value for 'signature'
 *      locale: // value for 'locale'
 *      customFieldValue: // value for 'customFieldValue'
 *      customFields: // value for 'customFields'
 *      fullName: // value for 'fullName'
 *      email: // value for 'email'
 *      marketingOptIn: // value for 'marketingOptIn'
 *      termsOptIn: // value for 'termsOptIn'
 *      clientData: // value for 'clientData'
 *      splashPageSubmit: // value for 'splashPageSubmit'
 *   },
 * });
 */
export function useLoginViaSeamlessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginViaSeamlessMutation,
    LoginViaSeamlessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginViaSeamlessMutation, LoginViaSeamlessMutationVariables>(
    LoginViaSeamlessDocument,
    options,
  );
}
export type LoginViaSeamlessMutationHookResult = ReturnType<typeof useLoginViaSeamlessMutation>;
export type LoginViaSeamlessMutationResult = Apollo.MutationResult<LoginViaSeamlessMutation>;
export type LoginViaSeamlessMutationOptions = Apollo.BaseMutationOptions<
  LoginViaSeamlessMutation,
  LoginViaSeamlessMutationVariables
>;
export const LogoutReferrerDocument = gql`
  mutation logoutReferrer($flowId: String!, $id: ID!) {
    logoutReferrer(input: { flowId: $flowId, id: $id }) {
      ok
      session {
        locale
        contentReplacements
        activeStep {
          ...ReferrerJourneySessionFragment
        }
      }
      errors
    }
  }
  ${ReferrerJourneySessionFragmentFragmentDoc}
`;
export type LogoutReferrerMutationFn = Apollo.MutationFunction<
  LogoutReferrerMutation,
  LogoutReferrerMutationVariables
>;

/**
 * __useLogoutReferrerMutation__
 *
 * To run a mutation, you first call `useLogoutReferrerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutReferrerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutReferrerMutation, { data, loading, error }] = useLogoutReferrerMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLogoutReferrerMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutReferrerMutation, LogoutReferrerMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutReferrerMutation, LogoutReferrerMutationVariables>(
    LogoutReferrerDocument,
    options,
  );
}
export type LogoutReferrerMutationHookResult = ReturnType<typeof useLogoutReferrerMutation>;
export type LogoutReferrerMutationResult = Apollo.MutationResult<LogoutReferrerMutation>;
export type LogoutReferrerMutationOptions = Apollo.BaseMutationOptions<
  LogoutReferrerMutation,
  LogoutReferrerMutationVariables
>;
export const RegisterReferrerDocument = gql`
  mutation registerReferrer(
    $flowId: String!
    $id: ID!
    $locale: String
    $fullName: String
    $firstName: String
    $lastName: String
    $email: String!
    $marketingOptIn: Boolean
    $termsOptIn: Boolean
    $customFields: [CustomFieldInput!]
    $utmParams: UtmParamsInput
  ) {
    registerReferrer(
      input: {
        flowId: $flowId
        id: $id
        locale: $locale
        fullName: $fullName
        firstName: $firstName
        lastName: $lastName
        email: $email
        termsOptIn: $termsOptIn
        marketingOptIn: $marketingOptIn
        customFields: $customFields
        utmParams: $utmParams
      }
    ) {
      ok
      errors
      session {
        locale
        contentReplacements
        activeStep {
          ...ReferrerJourneySessionFragment
        }
      }
    }
  }
  ${ReferrerJourneySessionFragmentFragmentDoc}
`;
export type RegisterReferrerMutationFn = Apollo.MutationFunction<
  RegisterReferrerMutation,
  RegisterReferrerMutationVariables
>;

/**
 * __useRegisterReferrerMutation__
 *
 * To run a mutation, you first call `useRegisterReferrerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterReferrerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerReferrerMutation, { data, loading, error }] = useRegisterReferrerMutation({
 *   variables: {
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *      fullName: // value for 'fullName'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      marketingOptIn: // value for 'marketingOptIn'
 *      termsOptIn: // value for 'termsOptIn'
 *      customFields: // value for 'customFields'
 *      utmParams: // value for 'utmParams'
 *   },
 * });
 */
export function useRegisterReferrerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterReferrerMutation,
    RegisterReferrerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterReferrerMutation, RegisterReferrerMutationVariables>(
    RegisterReferrerDocument,
    options,
  );
}
export type RegisterReferrerMutationHookResult = ReturnType<typeof useRegisterReferrerMutation>;
export type RegisterReferrerMutationResult = Apollo.MutationResult<RegisterReferrerMutation>;
export type RegisterReferrerMutationOptions = Apollo.BaseMutationOptions<
  RegisterReferrerMutation,
  RegisterReferrerMutationVariables
>;
export const ReferrerJourneyGetSessionDocument = gql`
  query referrerJourneyGetSession(
    $flow: String!
    $flowId: ID!
    $id: ID!
    $requestedLocale: String
  ) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        ...ReferrerJourneySessionFragment
      }
      contentReplacements
      locale
    }
  }
  ${ReferrerJourneySessionFragmentFragmentDoc}
`;

/**
 * __useReferrerJourneyGetSessionQuery__
 *
 * To run a query within a React component, call `useReferrerJourneyGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferrerJourneyGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferrerJourneyGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function useReferrerJourneyGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReferrerJourneyGetSessionQuery,
    ReferrerJourneyGetSessionQueryVariables
  > &
    ({ variables: ReferrerJourneyGetSessionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferrerJourneyGetSessionQuery, ReferrerJourneyGetSessionQueryVariables>(
    ReferrerJourneyGetSessionDocument,
    options,
  );
}
export function useReferrerJourneyGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferrerJourneyGetSessionQuery,
    ReferrerJourneyGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ReferrerJourneyGetSessionQuery,
    ReferrerJourneyGetSessionQueryVariables
  >(ReferrerJourneyGetSessionDocument, options);
}
export function useReferrerJourneyGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        ReferrerJourneyGetSessionQuery,
        ReferrerJourneyGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ReferrerJourneyGetSessionQuery,
    ReferrerJourneyGetSessionQueryVariables
  >(ReferrerJourneyGetSessionDocument, options);
}
export type ReferrerJourneyGetSessionQueryHookResult = ReturnType<
  typeof useReferrerJourneyGetSessionQuery
>;
export type ReferrerJourneyGetSessionLazyQueryHookResult = ReturnType<
  typeof useReferrerJourneyGetSessionLazyQuery
>;
export type ReferrerJourneyGetSessionSuspenseQueryHookResult = ReturnType<
  typeof useReferrerJourneyGetSessionSuspenseQuery
>;
export type ReferrerJourneyGetSessionQueryResult = Apollo.QueryResult<
  ReferrerJourneyGetSessionQuery,
  ReferrerJourneyGetSessionQueryVariables
>;
export const RewardRedemptionJourneyGetConfigDocument = gql`
  query rewardRedemptionJourneyGetConfig(
    $flow: String!
    $campaignSlug: String
    $locale: String
    $flowId: ID
  ) {
    config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
      ...RewardRedemptionJourneyConfigFragment
    }
  }
  ${RewardRedemptionJourneyConfigFragmentFragmentDoc}
`;

/**
 * __useRewardRedemptionJourneyGetConfigQuery__
 *
 * To run a query within a React component, call `useRewardRedemptionJourneyGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardRedemptionJourneyGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardRedemptionJourneyGetConfigQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      campaignSlug: // value for 'campaignSlug'
 *      locale: // value for 'locale'
 *      flowId: // value for 'flowId'
 *   },
 * });
 */
export function useRewardRedemptionJourneyGetConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    RewardRedemptionJourneyGetConfigQuery,
    RewardRedemptionJourneyGetConfigQueryVariables
  > &
    (
      | { variables: RewardRedemptionJourneyGetConfigQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RewardRedemptionJourneyGetConfigQuery,
    RewardRedemptionJourneyGetConfigQueryVariables
  >(RewardRedemptionJourneyGetConfigDocument, options);
}
export function useRewardRedemptionJourneyGetConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RewardRedemptionJourneyGetConfigQuery,
    RewardRedemptionJourneyGetConfigQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RewardRedemptionJourneyGetConfigQuery,
    RewardRedemptionJourneyGetConfigQueryVariables
  >(RewardRedemptionJourneyGetConfigDocument, options);
}
export function useRewardRedemptionJourneyGetConfigSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RewardRedemptionJourneyGetConfigQuery,
        RewardRedemptionJourneyGetConfigQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RewardRedemptionJourneyGetConfigQuery,
    RewardRedemptionJourneyGetConfigQueryVariables
  >(RewardRedemptionJourneyGetConfigDocument, options);
}
export type RewardRedemptionJourneyGetConfigQueryHookResult = ReturnType<
  typeof useRewardRedemptionJourneyGetConfigQuery
>;
export type RewardRedemptionJourneyGetConfigLazyQueryHookResult = ReturnType<
  typeof useRewardRedemptionJourneyGetConfigLazyQuery
>;
export type RewardRedemptionJourneyGetConfigSuspenseQueryHookResult = ReturnType<
  typeof useRewardRedemptionJourneyGetConfigSuspenseQuery
>;
export type RewardRedemptionJourneyGetConfigQueryResult = Apollo.QueryResult<
  RewardRedemptionJourneyGetConfigQuery,
  RewardRedemptionJourneyGetConfigQueryVariables
>;
export const RewardRedemptionJourneyGetSessionDocument = gql`
  query rewardRedemptionJourneyGetSession(
    $flow: String!
    $flowId: ID!
    $id: ID!
    $requestedLocale: String
  ) {
    session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
      activeStep {
        ...RewardRedemptionJourneySessionFragment
      }
      contentReplacements
      locale
    }
  }
  ${RewardRedemptionJourneySessionFragmentFragmentDoc}
`;

/**
 * __useRewardRedemptionJourneyGetSessionQuery__
 *
 * To run a query within a React component, call `useRewardRedemptionJourneyGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRewardRedemptionJourneyGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRewardRedemptionJourneyGetSessionQuery({
 *   variables: {
 *      flow: // value for 'flow'
 *      flowId: // value for 'flowId'
 *      id: // value for 'id'
 *      requestedLocale: // value for 'requestedLocale'
 *   },
 * });
 */
export function useRewardRedemptionJourneyGetSessionQuery(
  baseOptions: Apollo.QueryHookOptions<
    RewardRedemptionJourneyGetSessionQuery,
    RewardRedemptionJourneyGetSessionQueryVariables
  > &
    (
      | { variables: RewardRedemptionJourneyGetSessionQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RewardRedemptionJourneyGetSessionQuery,
    RewardRedemptionJourneyGetSessionQueryVariables
  >(RewardRedemptionJourneyGetSessionDocument, options);
}
export function useRewardRedemptionJourneyGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RewardRedemptionJourneyGetSessionQuery,
    RewardRedemptionJourneyGetSessionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RewardRedemptionJourneyGetSessionQuery,
    RewardRedemptionJourneyGetSessionQueryVariables
  >(RewardRedemptionJourneyGetSessionDocument, options);
}
export function useRewardRedemptionJourneyGetSessionSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RewardRedemptionJourneyGetSessionQuery,
        RewardRedemptionJourneyGetSessionQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RewardRedemptionJourneyGetSessionQuery,
    RewardRedemptionJourneyGetSessionQueryVariables
  >(RewardRedemptionJourneyGetSessionDocument, options);
}
export type RewardRedemptionJourneyGetSessionQueryHookResult = ReturnType<
  typeof useRewardRedemptionJourneyGetSessionQuery
>;
export type RewardRedemptionJourneyGetSessionLazyQueryHookResult = ReturnType<
  typeof useRewardRedemptionJourneyGetSessionLazyQuery
>;
export type RewardRedemptionJourneyGetSessionSuspenseQueryHookResult = ReturnType<
  typeof useRewardRedemptionJourneyGetSessionSuspenseQuery
>;
export type RewardRedemptionJourneyGetSessionQueryResult = Apollo.QueryResult<
  RewardRedemptionJourneyGetSessionQuery,
  RewardRedemptionJourneyGetSessionQueryVariables
>;
export const TrackEventDocument = gql`
  mutation trackEvent(
    $id: ID!
    $campaignSlug: String!
    $event: EventEnum!
    $params: JSON!
    $flowId: ID
    $utmParams: UtmParamsInput
  ) {
    trackEvent(
      input: {
        id: $id
        campaignSlug: $campaignSlug
        event: $event
        params: $params
        flowId: $flowId
        utmParams: $utmParams
      }
    ) {
      ok
      errors
    }
  }
`;
export type TrackEventMutationFn = Apollo.MutationFunction<
  TrackEventMutation,
  TrackEventMutationVariables
>;

/**
 * __useTrackEventMutation__
 *
 * To run a mutation, you first call `useTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventMutation, { data, loading, error }] = useTrackEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      campaignSlug: // value for 'campaignSlug'
 *      event: // value for 'event'
 *      params: // value for 'params'
 *      flowId: // value for 'flowId'
 *      utmParams: // value for 'utmParams'
 *   },
 * });
 */
export function useTrackEventMutation(
  baseOptions?: Apollo.MutationHookOptions<TrackEventMutation, TrackEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TrackEventMutation, TrackEventMutationVariables>(
    TrackEventDocument,
    options,
  );
}
export type TrackEventMutationHookResult = ReturnType<typeof useTrackEventMutation>;
export type TrackEventMutationResult = Apollo.MutationResult<TrackEventMutation>;
export type TrackEventMutationOptions = Apollo.BaseMutationOptions<
  TrackEventMutation,
  TrackEventMutationVariables
>;
