#import "../../shared-style.graphql"
#import "../../custom-fonts.graphql"
#import "../../urls.graphql"

fragment ClickToClaimFriendConfigFragment on Config {
  customFonts {
    ...CustomFontsFragment
  }
  styles {
    ...SharedStyleFragment
  }
  globalStyles
  localisation {
    date {
      dayNames
      abbreviatedDayNames
      monthNames
      abbreviatedMonthNames
      shortFormat
      longFormat
      monthOnlyFormat
    }
  }
  steps {
    type

    ... on SelectFriendRewardOptionStep {
      config {
        introLink {
          show
        }
        rewardTypes {
          id
          rewardOptions {
            key
            position
            title
            body
            containerBackgroundColor
            image {
              url
              altText
              mobile {
                url
                altText
              }
            }
            selectButtonText
          }
        }
      }
      content {
        title
        body
        introLink {
          text
          url
        }
        claimRewardError
        expiryInfo
      }
    }

    ... on ConfirmFriendRewardOptionStep {
      config {
        footerLink {
          show
        }
      }
      content {
        title
        confirmButton
        cancelButton
        claimRewardError
        footerLink {
          text
          url
        }
      }
    }

    ... on FriendRewardClaimedStep {
      config {
        footerLink {
          show
        }
      }
      content {
        title
        body
        footerLink {
          text
          url
        }
      }
    }

    ... on FriendRewardAlreadyClaimedStep {
      content {
        title
        body
      }
    }

    ... on FulfilmentExpiredStep {
      config {
        footerLink {
          show
        }
      }
      content {
        title
        body
        footerLink {
          text
          url
        }
      }
    }
  }
  urls {
    ...UrlsFragment
  }
}
