import React, { FC } from "react";
import render from "lib/Template";
import { ContainerSize, useDevice } from "hooks/Device";

import {
  TitleStyled,
  ParagraphStyled,
  OfferStyled,
  OfferDescriptionStyled,
  OfferImageStyled,
} from "./CaptureFriendDetails.styled";

import { CaptureFriendDetailsStep } from "types/graphql";

const ReferrerOffer: FC<{
  step: CaptureFriendDetailsStep;
  sessionContentReplacements: any;
}> = ({ step, sessionContentReplacements }) => {
  const device = useDevice();
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;

  if (!step.content) return null;

  const image = step.content.image;
  let imgUrl = image.url;
  let altText = image.altText;
  if (smallContainer && image?.mobile) {
    imgUrl = image.mobile.url;
    altText = image.mobile.altText;
  }

  return (
    <OfferStyled>
      <OfferImageStyled>
        <img src={imgUrl} alt={altText} />
      </OfferImageStyled>
      <OfferDescriptionStyled>
        {step.content.title && (
          <TitleStyled>{render(step.content.title, sessionContentReplacements)}</TitleStyled>
        )}
        <ParagraphStyled>{render(step.content.body, sessionContentReplacements)}</ParagraphStyled>
      </OfferDescriptionStyled>
    </OfferStyled>
  );
};

export default ReferrerOffer;
