fragment SuccessfulFriendRegistrationActiveStepFragment on SuccessfulFriendRegistrationActiveStep {
  config {
    showSecondaryButton
    buttons {
      buttonClass
      text
      url

      ... on TileButtonContent {
        image {
          altText
          url
        }
        heading
        description
        actionId
        buttonClass
      }
    }
  }
}
