import React, { FC, useState } from "react";
import Header from "../Shared/Header";
import {
  MobileWrapper,
  HeaderWrapper,
  PillBackGroundStyled,
  PillWrapperStyled,
  ChildrenBody,
} from "./Mobile.styled";
import { Context } from "../..";
import StatusList from "./StatusList";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Pill from "components/pill";

const Mobile: FC<{ context: Context }> = ({ context }) => {
  const [isOpen, setOpen] = useState(false);

  const handleToggle = (): void => setOpen(!isOpen);

  const content = context.step.content.referralStatus;
  const config = context.session.config?.referralStatus;
  const stepConfig = context.step.config;

  if (!config || !content || !stepConfig) return null;

  const anyReferrals = config.completeReferrals.length + config.pendingReferrals.length > 0;

  // if we've got nothing to display just exit
  if (!anyReferrals) return null;

  const showStats = context.step.config.showReferralStats;
  const showStatuses = context.step.config.showReferralStatuses;
  const showPending =
    config.pendingReferrals.length > 0 && context.step.config.showPendingReferrals;

  return (
    <MobileWrapper>
      <HeaderWrapper $anyReferrals={anyReferrals}>
        {showStats && <Header stats={content.stats} config={config} stepConfig={stepConfig} />}
        {showStatuses && (
          <>
            <ChildrenBody isOpen={isOpen}>
              {config.completeReferrals.length > 0 && (
                <StatusList
                  type="complete"
                  purchaseTitle={content.table.headers.date}
                  title={content.table.complete.title}
                  body={config.completeReferrals}
                  statuses={content.table.statuses}
                />
              )}
              {showPending && (
                <StatusList
                  type="pending"
                  purchaseTitle={content.table.headers.date}
                  title={content.table.pending.title}
                  body={config.pendingReferrals}
                  statuses={content.table.statuses}
                />
              )}
            </ChildrenBody>
            <PillBackGroundStyled isOpen={isOpen}>
              <PillWrapperStyled>
                {isOpen ? (
                  <Pill
                    handleToggle={handleToggle}
                    text={content.pill.expanded}
                    icon={<MdKeyboardArrowUp />}
                  />
                ) : (
                  <Pill
                    handleToggle={handleToggle}
                    text={content.pill.collapsed}
                    icon={<MdKeyboardArrowDown />}
                  />
                )}
              </PillWrapperStyled>
            </PillBackGroundStyled>
          </>
        )}
      </HeaderWrapper>
    </MobileWrapper>
  );
};

export default Mobile;
