import React, { FC, useContext, useState } from "react";
import parse from "html-react-parser";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import {
  ClaimRewardInput,
  ClaimRewardPayload,
  ClickToClaimOptionsSelectorStep,
  RewardEmailedActiveStep,
  SelectFriendRewardOptionStep,
  SessionInterface,
} from "types/graphql";

import Button from "components/button";
import VisuallyHidden from "components/visually-hidden";

import { ParagraphStyled } from "styles/global.styled";
import {
  WrapperStyled,
  CardStyled,
  CardTitleStyled,
  CardDescriptionStyled,
  CopyStyled,
  ImageStyled,
  HeaderStyled,
  TitleStyled,
  ContainerStyled,
  ImageContainerStyled,
  SubmissionErrorStyled,
  ExpiryInfoStyled,
} from "./SelectRewardOption.styled";

import useMutation from "hooks/UseMutation";
import { ContainerSize, useDevice } from "hooks/Device";
import { clickToClaimSharedQueries } from "queries";
import Svg from "components/svg/Svg";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { renderString } from "lib/Template";
import { DateTime } from "luxon";

export type SelectRewardOptionProps = {
  stepData: ClickToClaimOptionsSelectorStep | SelectFriendRewardOptionStep;
  handleRewardOptionSelection: (key: string, title: string) => void;
  handleIntroLinkClick: () => void;
  userType: string;
};

const SelectRewardOption: FC<SelectRewardOptionProps> = ({
  stepData,
  handleIntroLinkClick,
  handleRewardOptionSelection,
  userType,
}) => {
  const {
    sessionConfig,
    goToStep,
    appConfig,
    sessionContentReplacements: sourceContentReplacements,
    callbacks,
  } = useContext<DefaultContext>(StepContext);

  const device = useDevice();
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;

  const optionsForRewardType =
    stepData.config.rewardTypes
      .find((rewardType) => rewardType.id === sessionConfig.config?.rewardTypeId)
      ?.rewardOptions?.slice() || [];

  const allowedRewardTypes = sessionConfig.config?.allowedRewardOptions || [];

  const availableRewardOptions =
    allowedRewardTypes.length === 0
      ? optionsForRewardType
      : optionsForRewardType.filter((reward) => allowedRewardTypes.includes(reward.key));

  availableRewardOptions.sort(function (a, b) {
    return a.position - b.position;
  });

  const { expiryInfo } = stepData.content;

  const multipleOptions = availableRewardOptions.length > 1;

  const displayExpiry =
    sourceContentReplacements["fulfilment.expiry_date"] &&
    sourceContentReplacements["fulfilment.expiry_days"];

  const expiryDate = DateTime.fromISO(sourceContentReplacements["fulfilment.expiry_date"]);
  const sessionContentReplacements = {
    ...sourceContentReplacements,
    "fulfilment.expiry_date": expiryDate.isValid ? expiryDate.toLocaleString() : null,
  };

  const [error, setError] = useState<boolean>(false);
  const [submitSelectedReward, { loading }] = useMutation<
    { claimReward: ClaimRewardPayload },
    ClaimRewardInput
  >(clickToClaimSharedQueries.mutations.claimReward, {
    onCompleted: (data: { claimReward: ClaimRewardPayload }): void => {
      if (data.claimReward.ok) {
        const nextSession = data.claimReward.session as SessionInterface;
        const nextActiveStep = nextSession.activeStep as RewardEmailedActiveStep;

        goToStep({
          type: nextActiveStep.type,
          config: null,
          contentReplacements: {
            ...nextSession.contentReplacements,
            ...{
              "selected_reward.title": availableRewardOptions[0].title,
            },
          },
        });
      } else {
        setError(true);
      }
    },
    onError: () => setError(true),
  });

  const flowId = appConfig.flow.id;
  const id = appConfig.id;

  const handleRewardClaim = (optionKey: string): void => {
    if (flowId && optionKey) {
      submitSelectedReward({
        variables: {
          flowId,
          optionKey,
          id,
          userType,
        },
      });
    } else {
      setError(true);
    }
  };

  const showIntroLink = stepData.config.introLink.show;

  if (callbacks && callbacks.onLayoutLoaded) callbacks.onLayoutLoaded();

  const optionImageUrl = (option: any) => {
    let url = option.image.url;
    if (smallContainer && option.image?.mobile) {
      url = option.image.mobile.url;
    }

    return url;
  };

  const optionImageAltText = (option: any) => {
    let altText = option.image.altText;
    if (smallContainer && option.image?.mobile) {
      altText = option.image.mobile.altText;
    }

    return altText;
  };

  return (
    <WrapperStyled>
      <HeaderStyled>
        <TitleStyled>{parse(stepData.content.title)}</TitleStyled>
        <ParagraphStyled>{parse(stepData.content.body)}</ParagraphStyled>
        {showIntroLink && (
          <Button handleOnClick={(): void => handleIntroLinkClick()} appearance="link">
            {stepData.content.introLink.text}
            <VisuallyHidden>Opens in a new tab</VisuallyHidden>
          </Button>
        )}
      </HeaderStyled>

      <ContainerStyled $optionsCount={availableRewardOptions.length}>
        {availableRewardOptions.map((option) => (
          <CardStyled key={option.key}>
            {option.image && (
              <ImageContainerStyled style={{ backgroundColor: option.containerBackgroundColor }}>
                <ImageStyled src={optionImageUrl(option)} alt={optionImageAltText(option)} />
              </ImageContainerStyled>
            )}
            <CopyStyled>
              <CardTitleStyled>{parse(option.title)}</CardTitleStyled>
              <CardDescriptionStyled>{parse(option.body)}</CardDescriptionStyled>
              {multipleOptions ? (
                <Button
                  handleOnClick={(): void => handleRewardOptionSelection(option.key, option.title)}
                >
                  {option.selectButtonText}
                </Button>
              ) : (
                <Button
                  handleOnClick={(): void => handleRewardClaim(option.key)}
                  isLoading={loading}
                >
                  {option.selectButtonText}
                </Button>
              )}
            </CopyStyled>
          </CardStyled>
        ))}
      </ContainerStyled>
      {error && <SubmissionErrorStyled>{stepData.content.claimRewardError}</SubmissionErrorStyled>}
      {displayExpiry && expiryInfo && (
        <ExpiryInfoStyled>
          <Svg svgImage={AiOutlineInfoCircle} />{" "}
          {renderString(expiryInfo, sessionContentReplacements)}
        </ExpiryInfoStyled>
      )}
    </WrapperStyled>
  );
};

export default SelectRewardOption;
