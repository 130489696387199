import React, { FC, PropsWithChildren } from "react";
import CollapsibleCard from "components/collapsible-card/CollapsibleCard";
import {
  PartnerDashboardOfferContent,
  PartnerDashboardActiveStepConfigRewardDetail,
} from "types/graphql";
import render from "lib/Template";
import { ContainerSize, useDevice } from "hooks/Device";

const OfferDetails: FC<
  PropsWithChildren<{
    data: PartnerDashboardOfferContent;
    showRewards?: boolean;
    rewards: Array<PartnerDashboardActiveStepConfigRewardDetail>;
    icon: any;
    sessionContentReplacements?: any;
  }>
> = ({ data, showRewards, rewards, icon, sessionContentReplacements = {} }) => {
  const device = useDevice();
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;
  const { title, heading, body, image } = data;

  if (!title || !body) return null;

  let imgUrl = image.url;
  let altText = image.altText;
  if (smallContainer && image?.mobile) {
    imgUrl = image.mobile.url;
    altText = image.mobile.altText;
  }

  return (
    <CollapsibleCard icon={icon} title={title} collapsible={false} expandVertically={true}>
      {image && <img style={{ width: "100%" }} src={imgUrl} alt={altText} />}
      <h1>{heading}</h1>
      <p>{render(body, sessionContentReplacements)}</p>
      {showRewards && (
        <ul>
          {rewards.map((reward) => (
            <li key={reward.title}>
              <strong>{reward.title}</strong>
            </li>
          ))}
        </ul>
      )}
    </CollapsibleCard>
  );
};

export default OfferDetails;
