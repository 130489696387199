#import "../../../fragments/flows/lookup-user-journey/config.graphql"

query lookupUserJourneyGetConfig(
  $flow: String!
  $campaignSlug: String
  $locale: String
  $flowId: ID
) {
  config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
    ...LookupUserJourneyConfigFragment
  }
}
