import Pill from "components/pill";
import React, { FC, ReactNode, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { ChildrenStyled, ChildrenWrapperStyled, CollapseStyled } from "./Collapse.styled";

type Props = {
  children: ReactNode;
  collapsedTitle: string;
  expandedTitle: string;
  isOpen?: boolean;
};

const Collapse: FC<Props> = ({ children, collapsedTitle, expandedTitle, isOpen = false }) => {
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const handleToggle = (): void => setIsExpanded(!isExpanded);

  return (
    <CollapseStyled>
      <ChildrenStyled $isOpen={isExpanded}>
        <ChildrenWrapperStyled>{children}</ChildrenWrapperStyled>
      </ChildrenStyled>
      {isExpanded ? (
        <Pill handleToggle={handleToggle} text={expandedTitle} icon={<MdKeyboardArrowUp />} />
      ) : (
        <Pill handleToggle={handleToggle} text={collapsedTitle} icon={<MdKeyboardArrowDown />} />
      )}
    </CollapseStyled>
  );
};

export default Collapse;
