import React, { FC, PropsWithChildren } from "react";
import BaseForm from "components/form";
import Input from "components/input";
import { LookupUserStep } from "types/graphql";
import { Context } from "lib/Lego/UserLookup/LookupUser";
import {
  ContainerStyled,
  OfferContainer,
  OfferTextAndFormContainer,
  OfferTextBlock,
  OfferTitle,
  OfferText,
  FormStyled,
  ButtonStyled,
  OfferBannerImageStyled,
} from "./Form.styled";
import { ContainerSize, useDevice } from "hooks/Device";

export type LookupUserAccessCodeFormProps = {
  stepData: LookupUserStep;
};

const Form: FC<PropsWithChildren<{ context: Context }>> = ({ context, children }) => {
  const device = useDevice();
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;

  const image = context.step.content.image;
  let imgUrl = image.url;
  let altText = image.altText;
  if (smallContainer && image?.mobile) {
    imgUrl = image.mobile.url;
    altText = image.mobile.altText;
  }

  const inputContent = context.step.content.form.inputs;

  return (
    <ContainerStyled>
      <OfferContainer>
        <OfferTextAndFormContainer>
          {context.step.content.image?.url && <OfferBannerImageStyled src={imgUrl} alt={altText} />}
          <OfferTextBlock>
            <OfferTitle>{context.step.content.title}</OfferTitle>
            <OfferText>{context.step.content.body}</OfferText>
          </OfferTextBlock>
          <BaseForm
            handleOnSubmit={context.methods.handleLookupUser}
            overrideFormStyled={FormStyled}
          >
            <Input
              placeholder={inputContent.accessCode.placeholder}
              label={inputContent.accessCode.label}
              required
              showLabel
              name="code"
              errorMessage={
                (inputContent.accessCode.validationErrors || []).find((f) => f.key === "default")
                  ?.value || ""
              }
              showError={context.state.showInvalidCodeMessage}
              tooltip={inputContent.accessCode.tooltip || undefined}
            />
            <ButtonStyled appearance="primary" buttonType="submit" isLoading={false}>
              {context.step.content.form.button.text}
            </ButtonStyled>
          </BaseForm>
          {children}
        </OfferTextAndFormContainer>
      </OfferContainer>
    </ContainerStyled>
  );
};

export default Form;
