#import "../../../../fragments/flows/referrer-journey/session.graphql"

mutation loginViaSeamless(
  $flowId: String!
  $id: ID!
  $signedKeys: [String!]!
  $signature: String!
  $locale: String
  $customFieldValue: String
  $customFields: [CustomFieldInput!]
  $fullName: String!
  $email: String!
  $marketingOptIn: Boolean
  $termsOptIn: Boolean
  $clientData: JSON
  $splashPageSubmit: Boolean
) {
  loginViaSeamless(
    input: {
      flowId: $flowId
      id: $id
      signedKeys: $signedKeys
      signature: $signature
      locale: $locale
      fullName: $fullName
      email: $email
      marketingOptIn: $marketingOptIn
      termsOptIn: $termsOptIn
      customFieldValue: $customFieldValue
      customFields: $customFields
      clientData: $clientData
      splashPageSubmit: $splashPageSubmit
    }
  ) {
    ok
    errors
    session {
      activeStep {
        ...ReferrerJourneySessionFragment
      }
      contentReplacements
      locale
    }
  }
}
