#import "../../../fragments/flows/lookup-user-journey/lookup-user-active-step.graphql"

query lookupUserJourneyGetSession(
  $flow: String!
  $flowId: ID!
  $id: ID!
  $requestedLocale: String
) {
  session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
    activeStep {
      type

      ... on LookupUserActiveStep {
        ...LookupUserActiveStepFragment
      }
    }
    contentReplacements
    locale
  }
}
