import React, { FC, useEffect, useState } from "react";
import { useContainerQueries } from "use-container-queries";

import { ApolloClient, useApolloClient } from "@apollo/client";
import { ThemeProvider } from "styled-components";

import { GlobalStyled, GlobalWrapperStyled } from "styles/global.styled";
import { StepContextProvider } from "./context";

import { EmbedParameters } from "./types/embed-parameters.types";
import { getSessionId } from "lib/SessionId";

import FontLoader from "components/font-loader";
import TemplateLoader from "components/template-loader";

import { Config, SessionInterface } from "types/graphql";

import { DataMessagesStyled, AppLoaderStyled } from "./App.styled.ts";
import { BreakpointContext } from "context/BreakpointContext";

type Props = {
  params: EmbedParameters;
  boot: (
    apolloClient: ApolloClient<object>,
    params: EmbedParameters,
    sessionId: string,
  ) => Promise<[Config, SessionInterface]>;
};

export type ContainerSize = "large" | "small";
export const breakpoints: { [key in ContainerSize]: [number, number?] } = {
  small: [0, 449],
  large: [450],
};

const App: FC<Props> = ({ params, boot }) => {
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<Config | null>(null);
  const [session, setSession] = useState<SessionInterface | null>(null);
  const apolloClient = useApolloClient();
  const { ref, active: activeBreakpoint } = useContainerQueries({ breakpoints });

  const [sessionId] = useState(getSessionId(params.flow.name, params.market, params.campaign));

  useEffect(() => {
    (async (): Promise<void> => {
      const [config, session] = await boot(apolloClient, params, sessionId);
      setConfig(config);
      setSession(session);
      setLoading(false);
    })();
  }, [params, boot, apolloClient]);

  if (loading) {
    return (
      <DataMessagesStyled>
        <AppLoaderStyled />
      </DataMessagesStyled>
    );
  }

  if (!config || !session) {
    throw new Error("Error loading");
  }

  const globalStyles = config.styles;

  return (
    <div className="bp--e7414a9f-42dd-41ee-8d8e-57fb492d24cb" data-scope="bp-platform" ref={ref}>
      <BreakpointContext.Provider value={activeBreakpoint}>
        <ThemeProvider theme={globalStyles}>
          <GlobalStyled />
          <FontLoader fonts={config.customFonts} />
          <GlobalWrapperStyled>
            <StepContextProvider
              globalStyles={globalStyles}
              steps={config.steps}
              localisation={config.localisation}
              urls={config.urls}
              session={session}
              appConfig={{
                flow: params.flow,
                locale: params.locale,
                id: sessionId,
                campaignSlug: params.campaign,
                isPreview: !!params.preview,
              }}
              programType={config.programType}
              utmParams={params.utm}
              callbacks={params.callbacks}
            >
              <TemplateLoader />
            </StepContextProvider>
          </GlobalWrapperStyled>
        </ThemeProvider>
      </BreakpointContext.Provider>
    </div>
  );
};

export default App;
