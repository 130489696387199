import React, { FC } from "react";
import { TileButtonContent } from "types/graphql";
import parse from "html-react-parser";

import {
  ImageTileTitleStyled,
  ImageTileContainerStyled,
  ImageTileContentStyled,
  ImageTileImageStyled,
} from "../ImageTiles.styled";
import Button from "components/button";
import VisuallyHidden from "components/visually-hidden";

type ImageTileProps = {
  button: TileButtonContent;
  index: number;
  appearance: "primary" | "secondary";
  smallContainer: boolean;
  handleCallToActionClick: () => void;
};

export const ImageTile: FC<ImageTileProps> = ({
  button,
  index,
  appearance,
  smallContainer,
  handleCallToActionClick,
}) => {
  let imgUrl, altText;
  const image = button.image;
  if (image) {
    imgUrl = image.url;
    altText = image.altText;
    if (smallContainer && image?.mobile) {
      imgUrl = image.mobile.url;
      altText = image.mobile.altText;
    }
  }

  return (
    <ImageTileContainerStyled>
      {button.image && <ImageTileImageStyled src={imgUrl} alt={altText} />}
      <ImageTileContentStyled>
        {button.heading && <ImageTileTitleStyled>{parse(button.heading)}</ImageTileTitleStyled>}
        {button.description && <p>{parse(button.description)}</p>}
        <Button
          key={`cta_${index}`}
          handleOnClick={handleCallToActionClick}
          appearance={appearance}
        >
          {button.text}
          <VisuallyHidden>Opens in a new tab</VisuallyHidden>
        </Button>
      </ImageTileContentStyled>
    </ImageTileContainerStyled>
  );
};
