#import "../../../fragments/flows/reward-redemption-journey/config.graphql"

query rewardRedemptionJourneyGetConfig(
  $flow: String!
  $campaignSlug: String
  $locale: String
  $flowId: ID
) {
  config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
    ...RewardRedemptionJourneyConfigFragment
  }
}
