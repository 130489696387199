import React, { FC, ReactNode } from "react";
import { ButtonStyled, ButtonLoaderStyled } from "./Button.styled";

export type ButtonAppearance = "primary" | "secondary" | "link" | "active-tab" | "inactive-tab";

type Props = {
  children: ReactNode;
  handleOnClick?: () => void;
  disabled?: boolean;
  appearance?: ButtonAppearance;
  buttonType?: "button" | "submit" | "reset";
  isLoading?: boolean;
  icon?: ReactNode;
};

export const Button: FC<Props> = ({
  children,
  handleOnClick,
  disabled = false,
  appearance = "primary",
  buttonType = "button",
  isLoading = false,
  icon,
}) => (
  <ButtonStyled
    type={buttonType}
    onClick={handleOnClick}
    disabled={disabled || isLoading || false}
    role="button"
    $appearance={appearance}
  >
    {isLoading ? (
      <ButtonLoaderStyled />
    ) : (
      <>
        {icon}
        {children}
      </>
    )}
  </ButtonStyled>
);

export default Button;
