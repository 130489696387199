import React, { FC, PropsWithChildren, useContext, CSSProperties } from "react";
import { PlacesType, Tooltip as ReactTooltip } from "react-tooltip";
import { DefaultTheme, ThemeContext } from "styled-components";
import { resolveColor } from "styles/helpers";
import styled from "styled-components";

const ReactTooltipStyled = styled(ReactTooltip)`
  max-width: 300px;
  text-align: left;
  z-index: 9999;
`;

const Tooltip: FC<PropsWithChildren<{ message: string; type: string; direction?: PlacesType }>> = ({
  message,
  type,
  direction = "bottom",
  children,
}) => {
  const theme = useContext(ThemeContext) || ({} as DefaultTheme);
  const tooltipStyles: CSSProperties = {
    color: resolveColor(theme, theme.layout.backgroundColor),
    backgroundColor: resolveColor(theme, theme.layout.color),
    whiteSpace: "normal",
  };
  const reactTooltipProps = {
    id: `bp_${type}`,
    place: direction,
    style: tooltipStyles,
  };

  return (
    <>
      <div
        data-testid={`bp_test_${type}`}
        data-tooltip-id={`bp_${type}`}
        data-tooltip-content={message}
      >
        {children}
      </div>
      <ReactTooltipStyled {...reactTooltipProps} />
    </>
  );
};

export default Tooltip;
