#import "../../../../fragments/flows/referrer-journey/session.graphql"

mutation loginViaAuthToken($flowId: String!, $id: ID!, $authToken: String!) {
  loginViaAuthToken(input: { flowId: $flowId, id: $id, authToken: $authToken }) {
    ok
    errors
    session {
      activeStep {
        ...ReferrerJourneySessionFragment
      }
      contentReplacements
      locale
    }
  }
}
