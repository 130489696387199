import React, { FC } from "react";
import { FaRedditAlien } from "react-icons/fa";

import usePopupWindow from "hooks/PopupWindow";
import useRecordEvent from "hooks/RecordEvent";
import {
  ShareButtonLabelStyled,
  ShareButtonOuterStyled,
  ShareButtonStyled,
} from "../ShareButton.styled";

import { EventEnum } from "types/graphql";

export type Props = {
  shareUrl: string;
  buttonText?: string;
};

const RedditShare: FC<Props> = ({ shareUrl, buttonText = "Reddit" }) => {
  const popupWindow = usePopupWindow();
  const recordEvent = useRecordEvent();

  const handleRedditShareClick = (): void => {
    popupWindow(`https://www.reddit.com/submit?url=${encodeURIComponent(shareUrl)}`);
    recordEvent(EventEnum.Share, { share_network: "Reddit" });
  };

  return (
    <ShareButtonOuterStyled>
      <ShareButtonStyled onClick={handleRedditShareClick}>
        <FaRedditAlien />
      </ShareButtonStyled>
      <ShareButtonLabelStyled>{buttonText}</ShareButtonLabelStyled>
    </ShareButtonOuterStyled>
  );
};

export default RedditShare;
