import React, { FC, PropsWithChildren, useState, useLayoutEffect } from "react";
import { useCallback } from "react";
import { TargetTooltipWrapperStyled, TargetTooltipStyled } from "./TargetTooltip.styled";

const TargetTooltip: FC<
  PropsWithChildren<{ content: string; targetRef: HTMLElement | undefined; isInline?: boolean }>
> = ({ content, targetRef, children, isInline = true }) => {
  const tipMargin = 10;
  const [tipStyle, setTipStyle] = useState<any>();
  const [showTip, setShowTip] = useState(false);

  const calculateTipStyle = useCallback((): void => {
    const targetPos = targetRef && targetRef.getBoundingClientRect();

    targetPos &&
      setTipStyle({
        left: isInline ? tipMargin : 0,
        bottom: isInline ? targetPos.height - 3 : targetPos.height + 5,
        width: targetPos.width - (isInline ? tipMargin * 2 : 0),
        display: "inline-block",
      });
  }, [targetRef]);

  useLayoutEffect(() => {
    calculateTipStyle();
  }, [targetRef, calculateTipStyle]);

  return (
    <>
      <TargetTooltipWrapperStyled
        onMouseEnter={(): void => {
          calculateTipStyle();
          setShowTip(true);
        }}
        onMouseLeave={(): void => setShowTip(false)}
        onTouchEnd={(): void => {
          calculateTipStyle();
          setShowTip(!showTip);
        }}
      >
        {children}
      </TargetTooltipWrapperStyled>
      <TargetTooltipStyled $isInline={isInline} style={showTip ? tipStyle : {}}>
        {content}
      </TargetTooltipStyled>
    </>
  );
};

export default TargetTooltip;
