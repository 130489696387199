import { resolveColor } from "styles/helpers";
import styled from "styled-components";

type TargetTooltipProps = {
  $isInline: boolean;
};

export const TargetTooltipWrapperStyled = styled.div``;

export const TargetTooltipStyled = styled.span<TargetTooltipProps>`
  position: absolute;
  display: none;
  border-radius: 3px;

  color: ${({ theme }) => resolveColor(theme, theme.layout.backgroundColor)};
  background-color: ${({ theme }) => resolveColor(theme, theme.layout.color)};

  padding: 10px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.font.family};

  z-index: 9999;

  animation: fadeIn 200ms;

  ${({ $isInline, theme }) =>
    $isInline
      ? `
    &:after {
      position: absolute;
      left: 90%;
      top: 100%;

      width: 0;
      height: 0;
      border-left: 8.5px solid transparent;
      border-right: 8.5px solid transparent;
      border-top: 8px solid ${resolveColor(theme, theme.layout.color)};

      content: "";

      z-index: 9999;
    }
   `
      : ""}

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
