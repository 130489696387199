#import "../../../../fragments/flows/partner-journey/partner-dashboard-active-step.graphql"

mutation partnerLoginViaAuthToken(
  $flowId: String!
  $id: ID!
  $authToken: String!
  $locale: String
) {
  partnerLoginViaAuthToken(
    input: { flowId: $flowId, id: $id, authToken: $authToken, locale: $locale }
  ) {
    ok
    errors
    session {
      activeStep {
        type

        ... on PartnerDashboardActiveStep {
          ...PartnerDashboardActiveStepFragment
        }
      }
      contentReplacements
      locale
    }
  }
}
