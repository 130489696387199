import React, { FC, useContext } from "react";
import render from "lib/Template";

import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import { ContainerSize, useDevice } from "hooks/Device";

import {
  TitleStyled,
  ParagraphStyled,
  OfferStyled,
  OfferDescriptionStyled,
  OfferImageStyled,
} from "./RedirectFriendToClient.styled";

import { ClickToRedirectFriendStep } from "types/graphql";

const Offer: FC<{}> = () => {
  const { currentStep, sessionContentReplacements } = useContext<DefaultContext>(StepContext);
  const device = useDevice();
  const smallContainer = device.containerSize === ContainerSize.SmallContainer;
  const stepData = currentStep as ClickToRedirectFriendStep;

  if (!stepData.optionalContent) return null;

  const image = stepData.optionalContent.image;
  let imgUrl = image.url;
  let altText = image.altText;
  if (smallContainer && image?.mobile) {
    imgUrl = image.mobile.url;
    altText = image.mobile.altText;
  }

  return (
    <OfferStyled>
      <OfferImageStyled>
        <img src={imgUrl} alt={altText} />
      </OfferImageStyled>
      <OfferDescriptionStyled>
        {stepData.optionalContent.title && (
          <TitleStyled>
            {render(stepData.optionalContent.title, sessionContentReplacements)}
          </TitleStyled>
        )}
        <ParagraphStyled>
          {render(stepData.optionalContent.body, sessionContentReplacements)}
        </ParagraphStyled>
      </OfferDescriptionStyled>
    </OfferStyled>
  );
};

export default Offer;
