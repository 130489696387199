import React, { FC, PropsWithChildren } from "react";
import { Context } from "./index";
import { ShareCode } from "components/share-area";
import { renderString } from "lib/Template";
import NativeSharingTools from "components/native-sharing-tools";

const shareCode: FC<PropsWithChildren<{ context: Context }>> = ({ context }) => {
  const device = context.state.device;

  const { shareCode } = context.step.content;
  const { sessionContentReplacements } = context.state;

  if (!context.step.config.showShareCode) return null;

  return (
    <>
      <ShareCode
        title={renderString(shareCode.label, context.state.sessionContentReplacements)}
        copySuccessNotification={renderString(
          shareCode.copySuccessNotification,
          context.state.sessionContentReplacements,
        )}
        copyText={renderString(shareCode.shareMessage, context.state.sessionContentReplacements)}
        showCopySuccessNotification={context.state.showShareCodeCopySuccessNotification}
        shareMessage={
          renderString(shareCode.shareMessage, context.state.sessionContentReplacements) || ""
        }
        onCopy={context.methods.handleShareCodeCopy}
      />

      {device.nativeSharingSupported && (
        <NativeSharingTools
          nativeSharingButtonText={shareCode.nativeSharing.buttonText}
          nativeSharingMessage={renderString(shareCode.shareMessage, sessionContentReplacements)}
          nativeSharingTitle={shareCode.nativeSharing.title}
          manualSharingCopyText={renderString(shareCode.shareMessage, sessionContentReplacements)}
          manualSharingCopyButtonText={shareCode.copyButtonText}
          onCopy={context.methods.handleShareCodeCopy}
        />
      )}
    </>
  );
};

export default shareCode;
