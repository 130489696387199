#import "../../shared-style.graphql"
#import "../../custom-fonts.graphql"
#import "../../urls.graphql"

fragment ReferrerJourneyConfigFragment on Config {
  customFonts {
    ...CustomFontsFragment
  }
  styles {
    ...SharedStyleFragment
  }
  globalStyles
  localisation {
    date {
      dayNames
      abbreviatedDayNames
      monthNames
      abbreviatedMonthNames
      shortFormat
      longFormat
    }
    sharing {
      tabs {
        socialButtonText
        emailButtonText
      }
      email {
        messageLabelText
        emailClientLabelText
      }
    }
  }
  steps {
    type
    ... on PageDisabledStep {
      content {
        title
        body
      }
    }

    ... on SeamlessLoginFailureStep {
      content {
        title
        body
      }
    }

    ... on SplashPageStep {
      optionalConfig {
        inputs {
          marketingOptIn {
            show
            required
          }
          termsOptIn {
            show
          }
        }
        offerDetails {
          showStepIcons
          revealOnLoad
        }
        layout
      }
      optionalContent {
        title
        image {
          url
          altText
        }
        offerDetails {
          collapsedTitle
          expandedTitle
          steps
          footer
        }
        form {
          caption
          submitError
          button {
            text
          }
          inputs {
            marketingOptIn {
              label
              validationErrors {
                key
                value
              }
            }
            termsOptIn {
              label
              validationErrors {
                key
                value
              }
            }
          }
          finePrint
        }
        eligibilityCheckRejectionMessage
        additionalFinePrint
      }
    }

    ... on CaptureReferrerDetailsStep {
      optionalConfig {
        inputs {
          fullName {
            show
            pattern
          }
          firstName {
            show
            pattern
          }
          lastName {
            show
            pattern
          }
          email {
            show
            pattern
          }
          marketingOptIn {
            show
            required
          }
          termsOptIn {
            show
          }
          customFields {
            key
            position
            inputFieldType
            ... on CustomFieldInputConfig {
              pattern
            }
            ... on CustomFieldListInputConfig {
              items
            }
          }
        }
        button {
          show
        }
        offerDetails {
          showStepIcons
          revealOnLoad
        }
        layout
      }
      optionalContent {
        title
        image {
          url
          altText
          mobile {
            url
            altText
          }
        }
        offerDetails {
          collapsedTitle
          expandedTitle
          steps
          footer
        }
        form {
          caption
          submitError
          button {
            text
          }
          inputs {
            fullName {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            firstName {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            lastName {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            email {
              label
              placeholder
              validationErrors {
                key
                value
              }
              tooltip
            }
            marketingOptIn {
              label
              validationErrors {
                key
                value
              }
            }
            termsOptIn {
              label
              validationErrors {
                key
                value
              }
            }
            customFields {
              key
              content {
                label
                placeholder
                validationErrors {
                  key
                  value
                }
                items
              }
            }
          }
          finePrint
        }
        signInLinkEmailedMessage
        eligibilityCheckRejectionMessage
        additionalFinePrint
      }
    }

    ... on ReferrerDashboardStep {
      content {
        title
        logout {
          message
          linkText
        }
        image {
          url
          altText
          mobile {
            url
            altText
          }
        }
        offerDetails {
          collapsedTitle
          expandedTitle
          steps
          footer
        }
        referralStatus {
          stats {
            friendVisitCount
            completeReferralCount
            friendRegistrationCount
            cancelledReferralCount
            pendingReferralCount
          }
          pill {
            expanded
            collapsed
          }
          table {
            pending {
              title
              tooltip
            }
            complete {
              title
              tooltip
            }
            headers {
              name
              date
              status
            }
            statuses {
              pending
              complete
            }
          }
        }
        rewardCapping {
          warningTitle
          monetaryWarningMessage
          volumeWarningMessage
        }
        finePrint
        referralStatus {
          stats {
            friendVisitCount
            completeReferralCount
            friendRegistrationCount
            cancelledReferralCount
            pendingReferralCount
          }
          pill {
            expanded
            collapsed
          }
          table {
            pending {
              title
              tooltip
            }
            complete {
              title
              tooltip
            }
            headers {
              name
              date
              status
            }
            statuses {
              pending
              complete
            }
          }
        }
        shareMessage
        manualSharing {
          title
          copyButtonText
          copySuccessNotification
        }
        nativeSharing {
          title
          buttonText
        }
        shareCode {
          copyButtonText
          copySuccessNotification
          label
          nativeSharing {
            title
            buttonText
          }
          shareMessage
        }
      }

      config {
        showReferralStatuses
        showPendingReferrals
        showReferralStats
        referralStats
        shareNetworks
        layout
        offerDetails {
          showStepIcons
        }
        showDirectLink
        showShareCode
      }

      style {
        shareButtons {
          css
          _hover {
            css
          }
          icon {
            css
            _hover {
              css
            }
          }
        }
      }
    }
  }
  urls {
    ...UrlsFragment
  }
}
