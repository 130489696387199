import React, { FC } from "react";
import { FaXTwitter } from "react-icons/fa6";

import usePopupWindow from "hooks/PopupWindow";
import useRecordEvent from "hooks/RecordEvent";
import {
  ShareButtonLabelStyled,
  ShareButtonOuterStyled,
  ShareButtonStyled,
} from "../ShareButton.styled";

import { EventEnum } from "types/graphql";

export type Props = {
  shareText: string;
  shareUrl: string;
  buttonText?: string;
};

const TwitterShare: FC<Props> = ({ shareText, shareUrl, buttonText = "X" }) => {
  const popupWindow = usePopupWindow();
  const recordEvent = useRecordEvent();

  const handleTwitterShareClick = (): void => {
    popupWindow(
      `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText + " " + shareUrl)}`,
    );

    recordEvent(EventEnum.Share, { share_network: "Twitter" });
  };

  return (
    <ShareButtonOuterStyled>
      <ShareButtonStyled onClick={handleTwitterShareClick}>
        <FaXTwitter />
      </ShareButtonStyled>
      <ShareButtonLabelStyled>{buttonText}</ShareButtonLabelStyled>
    </ShareButtonOuterStyled>
  );
};

export default TwitterShare;
