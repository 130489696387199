#import "../../../../fragments/flows/lookup-user-journey/lookup-user-active-step.graphql"

mutation lookupUserViaCode($flowId: String!, $id: ID!, $code: String!) {
  lookupUserViaCode(input: { flowId: $flowId, id: $id, code: $code }) {
    ok
    errors
    session {
      locale
      contentReplacements
      activeStep {
        type

        ... on LookupUserActiveStep {
          ...LookupUserActiveStepFragment
        }
      }
    }
  }
}
