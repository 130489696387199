#import "../../../fragments/flows/referrer-journey/config.graphql"

query referrerJourneyGetConfig(
  $flow: String!
  $campaignSlug: String
  $locale: String
  $flowId: ID
) {
  config(flow: $flow, campaignSlug: $campaignSlug, locale: $locale, flowId: $flowId) {
    ...ReferrerJourneyConfigFragment
  }
}
