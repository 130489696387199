fragment CaptureReferrerDetailsActiveStepFragment on CaptureReferrerDetailsActiveStep {
  config {
    showSignInLinkEmailedMessage
    showEligibilityCheckRejectionMessage
  }
  validationErrors {
    key
    field
  }
}
