import React, { FC, useContext, useEffect, useRef, useState } from "react";
import {
  AddFriendReferralInput,
  AddFriendReferralPayload,
  EventEnum,
  RewardEmailedActiveStep,
  SessionInterface,
  SuccessfulFriendRegistrationStep,
} from "types/graphql";
import { StepContext } from "context/index";
import { DefaultContext } from "types/StepContext.types";
import useRecordEvent from "hooks/RecordEvent";

import useMutation from "../../../hooks/UseMutation";
import { friendJourneyQueries } from "../../../queries";
import { BrickTypes, Context } from "lib/Lego/FriendJourney/SuccessfulFriendRegistration";
import { Lego } from "lib/Lego";
import getLayout from "./layouts";
import { useDevice } from "hooks/Device";

export type SuccessfulFriendRegistrationProps = {
  stepData: SuccessfulFriendRegistrationStep;
};

const SuccessfulFriendRegistration: FC<SuccessfulFriendRegistrationProps> = ({ stepData }) => {
  const { appConfig, sessionContentReplacements, sessionConfig, goToStep } =
    useContext<DefaultContext>(StepContext);

  const recordEvent = useRecordEvent();
  const [error, setError] = useState<boolean>(false);

  const flowId = appConfig.flow.id;
  const id = appConfig.id;
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView();
    }
  }, []);

  const [addFriendReferral, { loading }] = useMutation<
    { addFriendReferral: AddFriendReferralPayload },
    AddFriendReferralInput
  >(friendJourneyQueries.mutations.addFriendReferral, {
    onCompleted: (data: { addFriendReferral: AddFriendReferralPayload }): void => {
      if (data.addFriendReferral.ok) {
        const nextSession = data.addFriendReferral.session as SessionInterface;
        const nextActiveStep = nextSession.activeStep as RewardEmailedActiveStep;

        goToStep({
          type: nextActiveStep.type,
          config: null,
          contentReplacements: nextSession.contentReplacements,
        });
      } else {
        setError(true);
      }
    },
    onError: () => setError(true),
  });

  const handleAddFriendReferral = (): void => {
    const variables = {
      flowId,
      id,
    } as AddFriendReferralInput;

    addFriendReferral({ variables });
  };

  const handleContinueToClientSite = (url: string): void => {
    recordEvent(EventEnum.ContinueToClientSite);
    window.open(url);
  };

  const context: Context = {
    step: stepData,
    session: sessionConfig,
    methods: {
      handleContinueToClientSite,
      handleAddFriendReferral,
    },
    state: {
      device: useDevice(),
      sessionContentReplacements,
      loading,
      error,
    },
    optionalStep: true,
  };

  return (
    <div ref={scrollRef}>
      <Lego<Context>
        brickTypes={BrickTypes}
        layout={getLayout("default")}
        context={context}
        isPreview={appConfig.isPreview}
      />
    </div>
  );
};

export default SuccessfulFriendRegistration;
