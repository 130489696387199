#import "../../../../fragments/flows/friend-journey/session.graphql"

mutation registerFriend(
  $flowId: String!
  $id: ID!
  $locale: String
  $fullName: String
  $firstName: String
  $lastName: String
  $email: String!
  $marketingOptIn: Boolean
  $termsOptIn: Boolean
  $customFields: [CustomFieldInput!]
) {
  registerFriend(
    input: {
      flowId: $flowId
      id: $id
      locale: $locale
      fullName: $fullName
      firstName: $firstName
      lastName: $lastName
      email: $email
      termsOptIn: $termsOptIn
      marketingOptIn: $marketingOptIn
      customFields: $customFields
    }
  ) {
    ok
    session {
      locale
      contentReplacements
      activeStep {
        ...FriendJourneySessionFragment
      }
    }
    errors
  }
}
