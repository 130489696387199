#import "../../../fragments/flows/redirect-friend-to-client-journey/session.graphql"

query redirectFriendToClientJourneyGetSessionPreview(
  $flow: String!
  $campaignSlug: String!
  $step: String!
  $configurationId: ID!
  $state: PreviewStateInput
  $locale: String!
) {
  sessionPreview(
    flow: $flow
    campaignSlug: $campaignSlug
    step: $step
    state: $state
    configurationId: $configurationId
    locale: $locale
  ) {
    activeStep {
      ...RedirectFriendToClientJourneySessionFragment
    }
    contentReplacements
  }
}
