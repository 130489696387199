#import "../../../fragments/flows/referrer-journey/splash-page-active-step.graphql"
#import "../../../fragments/flows/referrer-journey/capture-referrer-details-active-step.graphql"
#import "../../../fragments/flows/referrer-journey/referrer-dashboard-active-step.graphql"

query referrerJourneyGetSessionPreview(
  $flow: String!
  $campaignSlug: String!
  $step: String!
  $configurationId: ID!
  $state: PreviewStateInput
  $locale: String!
) {
  sessionPreview(
    flow: $flow
    campaignSlug: $campaignSlug
    step: $step
    state: $state
    configurationId: $configurationId
    locale: $locale
  ) {
    activeStep {
      type

      ... on SplashPageActiveStep {
        ...SplashPageActiveStepFragment
      }

      ... on CaptureReferrerDetailsActiveStep {
        ...CaptureReferrerDetailsActiveStepFragment
      }

      ... on ReferrerDashboardActiveStep {
        ...ReferrerDashboardActiveStepFragment
      }
    }
    contentReplacements
  }
}
