import React, { FC } from "react";
import { FaViber } from "react-icons/fa";

import usePopupWindow from "hooks/PopupWindow";
import useRecordEvent from "hooks/RecordEvent";
import {
  ShareButtonLabelStyled,
  ShareButtonOuterStyled,
  ShareButtonStyled,
} from "../ShareButton.styled";

import { EventEnum } from "types/graphql";

export type Props = {
  shareUrl: string;
  shareText: string;
  buttonText?: string;
};

const ViberShare: FC<Props> = ({ shareUrl, shareText, buttonText = "Viber" }) => {
  const popupWindow = usePopupWindow();
  const recordEvent = useRecordEvent();

  const handleViberShareClick = (): void => {
    popupWindow(`viber://pa?text=${encodeURIComponent(shareText + " " + shareUrl)}`);
    recordEvent(EventEnum.Share, { share_network: "Viber" });
  };

  return (
    <ShareButtonOuterStyled>
      <ShareButtonStyled onClick={handleViberShareClick}>
        <FaViber />
      </ShareButtonStyled>
      <ShareButtonLabelStyled>{buttonText}</ShareButtonLabelStyled>
    </ShareButtonOuterStyled>
  );
};

export default ViberShare;
