#import "../../../fragments/flows/referrer-journey/session.graphql"

query referrerJourneyGetSession($flow: String!, $flowId: ID!, $id: ID!, $requestedLocale: String) {
  session(flow: $flow, flowId: $flowId, id: $id, requestedLocale: $requestedLocale) {
    activeStep {
      ...ReferrerJourneySessionFragment
    }
    contentReplacements
    locale
  }
}
