mutation claimReward($flowId: String!, $optionKey: ID!, $id: ID!, $userType: String!) {
  claimReward(input: { flowId: $flowId, optionKey: $optionKey, id: $id, userType: $userType }) {
    ok
    session {
      activeStep {
        type
      }
      contentReplacements
    }
    errors
  }
}
